import React, { ReactNode, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import VideoCard_ from '../../components/VideoCard';
import { SearchVideo, useSearchVideos } from '../../api/search';
import { useSearchStore } from '../../stores';

import { useStyles } from './SearchPage.styles';

const VideoCard = VideoCard_ as any;

type SearchWrapperProps = {
  children: ReactNode;
};

const SearchWrapper = ({ children }: SearchWrapperProps) => {
  const styles = useStyles();

  return (
    <div className={styles.searchPage}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

const SearchPage = () => {
  const styles = useStyles();

  const { activeSearch, searchQueryStr, setChatOpen } = useSearchStore(
    useShallow((state) => ({
      searchQueryStr: state.searchQueryStr,
      activeSearch: state.activeSearch,
      setChatOpen: state.setChatOpen
    }))
  );

  const {
    data: searchData = [] as SearchVideo[],
    isLoading,
    isError,
    error
  } = useSearchVideos(searchQueryStr ?? '');

  useEffect(() => {
    if (activeSearch === 'ai') {
      setChatOpen(true);
    }
    return () => {
      setChatOpen(false);
    };
  }, [activeSearch, setChatOpen]);

  if (isError)
    return (
      <SearchWrapper>
        <ErrorMessage error={JSON.stringify(error)} fullHeightCentralized />
      </SearchWrapper>
    );

  if (isLoading)
    return (
      <SearchWrapper>
        <Loader loaderProps={{ size: 80 }} fullHeightCentralized />
      </SearchWrapper>
    );

  if (!searchData.length)
    return (
      <SearchWrapper>
        <ErrorMessage
          error={`Sorry, we couldn't find any results matching "${searchQueryStr}"`}
          fullHeightCentralized
        />
      </SearchWrapper>
    );

  return (
    <SearchWrapper>
      {activeSearch && (
        <h2 className={styles.title}>
          {activeSearch === 'ai' && 'Ai search results'}
          {activeSearch === 'regular' && 'Search results'}
        </h2>
      )}
      <div className={styles.grid}>
        {searchData.map((video, i: number) => (
          <div key={video.id + i}>
            <VideoCard
              key={video.id + i}
              video={video}
              isFromSearch
              // commented by request of Alex Pogrebnoy
              // searchWords={searchStr
              //   .split(/\s|\|/g)
              //   .map((str) =>
              //     str[str.length - 1] === '*'
              //       ? `\\b\\w*${str.slice(0, -1)}\\w*\\b`
              //       : `\\b${str}\\b`
              //   )}
            />
          </div>
        ))}
      </div>
    </SearchWrapper>
  );
};

export default SearchPage;
