import React from 'react';
import { func, object, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { DismissIcon } from '../../assets/icons';
import TimeCard from '../TimeCard';
import { useResizeObserver } from '../../hooks';
import { styles } from './Recording.styles';
import { capitalizeFirstChar } from '../../shared';

const propTypes = {
  recording: shape({
    id: string,
    start: string,
    end: string,
    details: object,
    programId: string,
    position: string,
    state: string
  }).isRequired,
  deleteRecording: func,
  classes: object.isRequired
};

const defaultProps = {
  deleteRecording: () => {}
};

const SCHEDULED = 'scheduled';

const Recording = ({
  recording: { id, start, end, details, programId, position, state },
  deleteRecording,
  classes
}) => {
  const isScheduled = state.toLowerCase() === SCHEDULED;
  const [bindResizeObserver, { width }] = useResizeObserver();

  const isSmall = width < 580;
  const isSmallest = width < 385;

  return (
    <div
      className={
        isSmall
          ? isSmallest
            ? classes.rootSmallest
            : classes.rootSmall
          : classes.root
      }
      ref={bindResizeObserver}
    >
      <div
        className={
          isSmall ? classes.thumbnailContainerSmall : classes.thumbnailContainer
        }
      >
        <Link
          className={classes.link}
          to={{ pathname: `/recordings/${programId}`, state: { position } }}
        >
          <div
            className={classes.thumbnail}
            style={{
              backgroundImage: details ? `url('${details.thumbnail}')` : 'none'
            }}
          />
        </Link>
      </div>
      <div>
        <div className={classes.dateTileContainer}>
          <TimeCard
            startTime={start}
            endTime={end}
            theme={isScheduled ? 'gray' : 'turquoise'}
            showDay
          />
        </div>
      </div>
      <div
        className={
          isSmall ? classes.recordingContainerSmall : classes.recordingContainer
        }
      >
        <div className={classes.recordingWrapper}>
          <button
            className={classes.remove}
            onClick={() => deleteRecording(id)}
          >
            <DismissIcon className={classes.dismissIcon} />
            <span className={classes.dismissText}>Remove</span>
          </button>
          {!isScheduled && <div className={classes.recordingIcon} />}
          {state}
        </div>
      </div>
      <div className={isSmall ? classes.descriptionSmall : classes.description}>
        {details && capitalizeFirstChar(details.title)}
      </div>
    </div>
  );
};

Recording.propTypes = propTypes;
Recording.defaultProps = defaultProps;

export default withStyles(styles)(Recording);
