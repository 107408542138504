import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import ErrorMessage from '../../ErrorMessage';

import { useColumnsHeight } from './hooks';

import { useStyles } from './VideoLayout.styles';

const VideoLayout = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const wrapperRef = useColumnsHeight(children);

  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)} {...props}>
      <div className={styles.layout} ref={wrapperRef}>
        {children}
      </div>
      <div className={styles.error}>
        <div className={styles.qtyError}>
          <ErrorMessage error="Unexpected quantity of children in Layout component. Expected from 0 to 3 elements." />
        </div>
        <div className={styles.noVideoError}>
          <ErrorMessage error="Missing video element in Layout component." />
        </div>
      </div>
    </div>
  );
};

export default VideoLayout;
