import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import CustomTooltip from '../../CustomTooltip';

import { useStyles } from './SlidesTitle.styles';

type Props = HTMLAttributes<HTMLHeadingElement> & {
  tooltipHtml?: string;
};

const VideoSlide = ({ className, tooltipHtml, children, ...props }: Props) => {
  const styles = useStyles();

  return (
    <h3 className={clsx(styles.header, className)} {...props}>
      {children}
      {tooltipHtml && <CustomTooltip html={tooltipHtml} />}
    </h3>
  );
};

export default VideoSlide;
