export const NAV_LINKS = [
  {
    value: 'DASHBOARD',
    label: 'Home',
    path: '/home'
  },
  {
    value: 'CHANNELS',
    label: 'Channels',
    path: '/channels'
  },
  {
    value: 'VIDEOS',
    label: 'On demand',
    path: '/videos'
  },
  {
    value: 'RECORDINGS',
    label: 'My recordings',
    path: '/recordings'
  }
];
