import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '10px 50px 20px 50px',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    color: '#ffffff'
  }
}));
