import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getAdList } from './adTechApi';
import { AdItem } from './adTechTypes';

export const useAdList = (options: UseQueryOptions<AdItem[]> = {}) =>
  useQuery<AdItem[], unknown>({
    queryKey: ['adList'],
    queryFn: getAdList,
    ...options
  });
