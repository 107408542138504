import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  selectRoot: {
    color: '#fff',
    fontFamily: 'Open Sans',
    fontSize: 12,
    textAlign: 'center',
    position: 'relative'
  },
  title: {
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '&:hover, &:focus': {
      textShadow: '0 0 1em #fff',
      outline: 0
    },

    '&:has(svg):hover, &:has(svg):focus': {
      filter: 'drop-shadow(0px 0px 6px rgb(255 255 255))'
    }
  },
  titleInner: {
    width: 35,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  list: {
    position: 'absolute',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    backgroundColor: '#414042',
    top: 0,
    left: 0,
    translate: 'calc(-50% + 35px / 2) -100%'
  },
  listItem: {
    width: 100,
    cursor: 'pointer',
    lineHeight: '16.8px',
    padding: '2.4px',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  highlightedItem: {
    backgroundColor: '#575558',
    textShadow: '0 0 1em #fff'
  },
  selectedItem: {
    backgroundColor: '#fff',
    color: '#2b333f'
  }
}));
