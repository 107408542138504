/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { styles } from './PlatformMonitoringPage.styles';

class PlatformMonitoringPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>Platform Monitoring</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(PlatformMonitoringPage);
