import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tagCloudWrapper: {
    paddingTop: '10px',
    width: '100%',
    '& iframe': {
      width: '100%',
      border: 0
    },
    '& iframe .application': {
      backgroundColor: 'black'
    }
  },
  playerWrp: {
    backgroundColor: 'black'
  },
  controls: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginTop: 28,
      minHeight: '400px'
    }
  },
  videoWrapResponse: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '75%'
    }
  },
  expansionPanel: {
    backgroundColor: '#000',
    padding: 0,
    '& div': {
      color: 'rgba(255,255,255,0.54)'
    }
  },
  listHeader: {
    opacity: '0.6',
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '28px',
    textAlign: 'left',
    margin: '0 10px'
  }
}));
