import React, { useState } from 'react';
import { Modal, Tooltip } from '@material-ui/core';

import { tooltipTagCloud } from '../../constants/tooltipsHTML';

import { useStyles } from './VideoPageBannerSection.styles';

type Props = {
  poster: string;
  title: string;
  description: string;
  tagCloud?: string;
};

const VideoPageBannerSection = ({
  poster,
  title,
  description,
  tagCloud
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const styles = useStyles();

  const banner = { backgroundImage: 'url(' + poster + ')' };

  return (
    <div className={styles.root}>
      <div className={styles.banner} style={banner}>
        <div className={styles.bannerOverlay} />
      </div>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      {tagCloud && (
        <Tooltip
          interactive
          title={<div dangerouslySetInnerHTML={{ __html: tooltipTagCloud }} />}
          classes={{ popper: styles.popper, tooltip: styles.tooltip }}
        >
          <div className={styles.showTagButton} onClick={() => setIsOpen(true)}>
            <span className={styles.showTagButtonAfter}></span>
            SHOW TAGS
          </div>
        </Tooltip>
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className={styles.modalContent}>
          <div dangerouslySetInnerHTML={{ __html: tagCloud ?? '' }} />
        </div>
      </Modal>
    </div>
  );
};

export default VideoPageBannerSection;
