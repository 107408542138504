import { makeStyles, createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#414042',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#f37037',
      contrastText: '#ffffff'
    },
    action: {
      disabled: '#414042'
    }
  }
});

export const drawerWidth = 445;
export const headerHeight = 90;

export const useStyles = makeStyles({
  app: {
    height: '100%',
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    height: '100%',
    minHeight: `calc(100% - ${headerHeight}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: 0,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
      backgroundColor: 'rgba(201, 201, 201, 0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#A1A1A1',
      borderRadius: 10
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  header: {
    height: headerHeight
  }
});
