import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonProps, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useShallow } from 'zustand/react/shallow';
import { Link } from 'react-router-dom';

import { useSearchStore } from '../../../stores';
import { useClickOutside, usePrevious } from '../../../hooks/utilHooks';
import { useGetUser } from '../../../api/user';

import SearchRegular from './SearchRegular';
import SearchAI from './SearchAI';
import VoiceSearch from '../../VoiceSearch';

import { CloseIcon, SearchIcon, AiIcon, MicIcon } from '../../../assets/icons';
import { useStyles } from './Search.styles';

type SearchType = 'search' | 'ai';

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const Search = () => {
  const [activeSearch, setActiveSearch] = useState<SearchType>('ai');
  const styles = useStyles();

  const getToggleButtonSharedProps = (
    ...args: string[]
  ): Partial<ButtonProps> => ({
    className: clsx(styles.toggleButton, ...args),
    type: 'button'
  });

  const iconBtnSharedProps = {
    disableRipple: true,
    className: styles.control
  };

  const {
    isSearchOpen,
    setIsSearchOpen,
    searchStr,
    setSearchStr,
    searchAiStr,
    setSearchAiStr,
    chatMessagesQty,
    setChatOpen
  } = useSearchStore(
    useShallow((state) => ({
      isSearchOpen: state.isSearchOpen,
      setIsSearchOpen: state.setIsSearchOpen,
      searchStr: state.searchStr,
      setSearchStr: state.setSearchStr,
      searchAiStr: state.searchAiStr,
      setSearchAiStr: state.setSearchAiStr,
      chatMessagesQty: state.chatMessages.length,
      setChatOpen: state.setChatOpen
    }))
  );
  const isActiveInputEmpty =
    activeSearch === 'search' ? !searchStr : !searchAiStr;

  // auto focus on input logic
  const inputsContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isSearchOpen) return;
    const input = inputsContainerRef.current?.querySelector('input');
    if (input) input.focus();
  }, [isSearchOpen, activeSearch]);

  const searchContainerRef = useRef<HTMLDivElement>(null);
  useClickOutside(searchContainerRef, () => {
    if (isSearchOpen) setIsSearchOpen(false);
  });

  const searchAiRef = useRef<HTMLInputElement>(null);
  const searchRegularRef = useRef<HTMLInputElement>(null);
  const prevIsSearchOpen = usePrevious(isSearchOpen);
  useEffect(() => {
    if (isSearchOpen && !prevIsSearchOpen) {
      if (activeSearch === 'search') {
        searchRegularRef.current?.focus();
      } else {
        searchAiRef.current?.focus();
      }
    }
  }, [activeSearch, isSearchOpen, prevIsSearchOpen]);

  const [isVoiceSearchOpen, setIsVoiceSearchOpen] = useState(false);
  /** Must be memoized as it used in useEffect */
  const onClose = useCallback(() => {
    setIsVoiceSearchOpen(false);

    if (activeSearch === 'search') {
      searchRegularRef.current?.focus();
    } else {
      searchAiRef.current?.focus();
    }
  }, [activeSearch]);
  /** Must be memoized as it used in useEffect */
  const onVoiceSearch = useCallback(
    (text: string) => {
      if (activeSearch === 'search') {
        setSearchStr((prevText) => (prevText + ' ' + text).trim());
      } else {
        setSearchAiStr((prevText) => (prevText + ' ' + text).trim());
      }
    },
    [activeSearch, setSearchAiStr, setSearchStr]
  );

  const { isSuccess: isLoggedIn } = useGetUser();

  return (
    <div
      className={styles.container}
      ref={searchContainerRef}
      onClick={(e) => e.stopPropagation()}
    >
      {isVoiceSearchOpen && (
        <VoiceSearch
          onClose={onClose}
          onVoiceSearch={onVoiceSearch}
          className={styles.voice}
        />
      )}
      <div className={styles.inputs}>
        <div className={styles.buttonsContainer}>
          <button
            {...getToggleButtonSharedProps(
              activeSearch === 'search' ? styles.activeToggleButton : ''
            )}
            title="Search"
            onClick={() => {
              setActiveSearch('search');
              searchRegularRef.current?.focus();
            }}
          >
            <SearchIcon style={{ marginRight: 3 }} />
            <div>Search</div>
          </button>
          <button
            {...getToggleButtonSharedProps(
              activeSearch === 'ai' ? styles.activeToggleButton : ''
            )}
            title="AI Search"
            onClick={() => {
              if (chatMessagesQty > 0) {
                setChatOpen(true);
              }
              setActiveSearch('ai');
              setTimeout(() => searchAiRef.current?.focus());
            }}
          >
            <AiIcon style={{ marginRight: 2 }} />
            <div>AI Search</div>
          </button>
        </div>
        <div className={styles.inputsContainer} ref={inputsContainerRef}>
          <SearchRegular
            ref={searchRegularRef}
            style={{ ...(activeSearch !== 'search' && { display: 'none' }) }}
          />

          {!isLoggedIn ? (
            <div
              className={styles.loginMsg}
              style={{ ...(activeSearch !== 'ai' && { display: 'none' }) }}
            >
              Please,{' '}
              <Link
                to="/login"
                onClick={() => {
                  setIsSearchOpen(false);
                }}
              >
                login
              </Link>{' '}
              to use AI Assistant.
            </div>
          ) : (
            <SearchAI
              ref={searchAiRef}
              style={{ ...(activeSearch !== 'ai' && { display: 'none' }) }}
            />
          )}
        </div>
      </div>
      <div className={styles.controls}>
        {SpeechRecognition && (
          <IconButton
            {...iconBtnSharedProps}
            style={{ marginRight: 14 }}
            title="Search using voice"
            onClick={() => {
              setIsVoiceSearchOpen(true);
            }}
          >
            <MicIcon />
          </IconButton>
        )}

        <IconButton
          {...iconBtnSharedProps}
          title={isActiveInputEmpty ? 'Close' : 'Clear'}
          onClick={() => {
            if (isVoiceSearchOpen) return;

            if (isActiveInputEmpty) {
              setIsSearchOpen(false);
              return;
            }
            if (activeSearch === 'search') {
              setSearchStr('');
            } else {
              setSearchAiStr('');
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Search;
