import React, { forwardRef, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

import { useVideoStoreValue } from '../../store/videoStore';

import { CaptionsOnIcon, CaptionsOffIcon } from './assets/icons';
import { useStyles } from './CaptionsControl.styles';

type Props = HTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
};

const CaptionsControl = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, disabled, ...props }, ref) => {
    const isStarted = useVideoStoreValue((store) => store.isStarted);
    const isCCshown = useVideoStoreValue((store) => store.isCCshown);

    const styles = useStyles();

    const content = (
      <div ref={ref} title={disabled ? 'Captions not available' : undefined}>
        <button
          {...(!isStarted && { tabIndex: -1 })}
          className={clsx(
            className,
            styles.button,
            disabled && styles.disabled
          )}
          type="button"
          onClick={onClick}
          {...props}
        >
          {isCCshown ? <CaptionsOnIcon /> : <CaptionsOffIcon />}
        </button>
      </div>
    );

    return disabled ? (
      content
    ) : (
      <Tooltip
        interactive
        placement="top"
        title={
          <>
            <p className={styles.title}>Closed Captioning</p>
            <p className={styles.text}>
              This video was processed with Speech-to-Text Intelligent Service
              for words detection and recognition. Also standard VTT format
              supported for Captioning and Subtitles.
            </p>
          </>
        }
      >
        {content}
      </Tooltip>
    );
  }
);
CaptionsControl.displayName = 'CaptionsControl';

export default CaptionsControl;
