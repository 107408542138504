import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions
} from '@tanstack/react-query';

import { initAiSearch, searchAi } from './searchAiApi';
import { AiSearch, AiSearchInit, AiSearchData } from './searchAiTypes';
import { useGetUser } from '../user';

export const useInitAiSearch = (
  options: UseQueryOptions<AiSearchInit, unknown> = {}
) => {
  const { isSuccess } = useGetUser();

  return useQuery<AiSearchInit>({
    queryKey: ['initAiSearch'],
    queryFn: initAiSearch,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isSuccess,
    ...options
  });
};

export const useSearchAi = (
  options: UseMutationOptions<AiSearch, unknown, AiSearchData, unknown> = {}
) =>
  useMutation({
    mutationFn: (data: AiSearchData) =>
      searchAi({ threadId: data.threadId, query: data.query }),
    ...options
  });
