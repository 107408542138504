import {
  UserLoginResponse,
  UserLoginPayload,
  UserResponse,
  UserLogoutResponse
} from './userTypes';
import { API_GET_USER, API_LOGIN_USER, API_LOGOUT_USER } from './userUrls';
import { request } from '../request';

export const loginUser = ({ uid, passwd }: UserLoginPayload) =>
  request<UserLoginResponse>(API_LOGIN_USER(uid, passwd));

export const logoutUser = () => request<UserLogoutResponse>(API_LOGOUT_USER);

export const getUser = () => request<UserResponse>(API_GET_USER);
