/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tabs } from '@material-ui/core';

import { groupContentFetchData } from '../../../actions/groups.action';

import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/ErrorMessage';
import VideoCard from '../../../components/VideoCard';

import { styles } from './VideoGroup.styles';

class VideoGroup extends Component {
  componentDidMount() {
    this.props.fetchData(this.props.group.id);
  }

  render() {
    const classes = this.props.classes;
    const { name, items, isLoading, hasError } = { ...this.props.group };

    let content = null;

    if (items) {
      content = (
        <Tabs
          value={false}
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            scrollButtons: classes.scrollButton,
            flexContainer: classes.flexContainer
          }}
        >
          {items.map((video, index) => (
            <VideoCard
              video={video}
              key={`${index}-${video.id}`}
              className={classes.cardContainer}
            />
          ))}
        </Tabs>
      );
    }

    if (isLoading) {
      content = <Loader loaderProps={{ size: 40 }} fullHeightCentralized />;
    }

    if (hasError) {
      content = <ErrorMessage fullHeightCentralized />;
    }

    return (
      <div className={classes.videoGroup}>
        <Typography className={classes.divider} color="secondary">
          {name}
        </Typography>
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(groupContentFetchData(id))
});

export default withRouter(
  withStyles(styles)(connect(null, mapDispatchToProps)(VideoGroup))
);
