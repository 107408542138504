/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import {
  recommendationsFetchData,
  favoriteChannelsFetchData,
  favoriteVideosFetchData,
  hotFetchData,
  topChannelsFetchData
} from '../../actions/dashboard.action';

import DashboardTile from '../../components/dashboardPage/DashboardTile';
import VideoCardDashboard from '../../components/VideoCardDashboard';
import FavoriteChannel from '../../components/dashboardPage/FavoriteChannel';
import Recording from '../../components/Recording';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { useStyles } from './DashboardPage.styles';
import { recordingsFetchDataDB } from '../../actions/recordings.action';

// TODO: use hooks for state connection
// TODO: destructure props and add prop-types
const DashboardPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTwoColumnsMode = useMediaQuery(theme.breakpoints.only('md'));

  useEffect(() => {
    props.recommendationsFetchData();
    props.favoriteChannelsFetchData();
    props.favoriteVideosFetchData();
    props.hotFetchData();
    props.topChannelsFetchData();
    props.recordingsFetchDataDB();
    // just disabling because it worked for years
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderVideoTiles = (arr, qty) => (
    <div className={classes.tilesGrid}>
      {arr.slice(0, qty).map((video, idx) => (
        <Link
          to={`/videos/${video.id}`}
          className={classes.thumbnailLink}
          key={video.id + idx}
        >
          <VideoCardDashboard title={video.title} thumbnail={video.thumbnail} />
        </Link>
      ))}
    </div>
  );

  const { isLoading, isFailed } = props;

  if (isLoading)
    return <Loader loaderProps={{ size: 40 }} fullHeightCentralized />;
  if (isFailed) return <ErrorMessage fullHeightCentralized />;

  const thirdColumn = (
    <>
      <DashboardTile title="Favorite Videos">
        {renderVideoTiles(props.favoriteVideos.slice(0, 6))}
      </DashboardTile>
      <DashboardTile
        title="Last Recordings"
        link={
          <Link to="/recordings" className={classes.viewAllLink}>
            view all
          </Link>
        }
        disablePadding
      >
        <div className={classes.lastRecordings}>
          {props.recordings.map((recording) => (
            <Recording key={recording.id} recording={recording} />
          ))}
        </div>
      </DashboardTile>
    </>
  );

  return (
    <div className={classes.root}>
      <div>
        <DashboardTile title="Recommendation">
          {renderVideoTiles(props.recommendations)}
        </DashboardTile>
        {isTwoColumnsMode && <div>{thirdColumn}</div>}
      </div>
      <div>
        <DashboardTile title="What's hot">
          {renderVideoTiles(props.recommendations, 5)}
          <h3 className={classes.topChannelsTitle}>Top Channels</h3>
          <div className={classes.topChannels}>
            {props.topChannels.slice(0, 4).map((channel) => (
              <Link key={channel.id} to="#" className={classes.thumbnailLink}>
                <img
                  src={channel.thumbnail}
                  title={channel.title}
                  className={classes.topChannelsImg}
                  alt=""
                />
              </Link>
            ))}
          </div>
        </DashboardTile>
        <DashboardTile title="Favorites Channels">
          <div className={classes.favoriteChannels}>
            <div className={classes.favoriteChannelsTitleWrp}>
              <div className={classes.favoriteChannelsTitle}>Channel</div>
              <div className={classes.favoriteChannelsTitle}>Playing now</div>
            </div>
            {props.favoriteChannels.slice(0, 3).map((channel) => (
              <FavoriteChannel key={channel.id} channel={channel} />
            ))}
          </div>
        </DashboardTile>
      </div>
      {!isTwoColumnsMode && <div>{thirdColumn}</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading:
    state.dashboard.recommendations.isLoading ||
    state.dashboard.hot.isLoading ||
    state.dashboard.topChannels.isLoading ||
    state.dashboard.favoriteChannels.isLoading ||
    state.dashboard.favoriteVideos.isLoading,

  isFailed:
    state.dashboard.recommendations.isFailed ||
    state.dashboard.hot.isFailed ||
    state.dashboard.topChannels.isFailed ||
    state.dashboard.favoriteChannels.isFailed ||
    state.dashboard.favoriteVideos.isFailed,

  recommendations: state.dashboard.recommendations.data,
  hot: state.dashboard.hot.data,
  topChannels: state.dashboard.topChannels.data,
  favoriteChannels: state.dashboard.favoriteChannels.data,
  favoriteVideos: state.dashboard.favoriteVideos.data,
  recordings: state.recordings.data
});

const mapDispatchToProps = (dispatch) => ({
  recommendationsFetchData: () => dispatch(recommendationsFetchData()),
  favoriteChannelsFetchData: () => dispatch(favoriteChannelsFetchData()),
  favoriteVideosFetchData: () => dispatch(favoriteVideosFetchData()),
  hotFetchData: () => dispatch(hotFetchData()),
  topChannelsFetchData: () => dispatch(topChannelsFetchData()),
  recordingsFetchDataDB: () => dispatch(recordingsFetchDataDB())
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
