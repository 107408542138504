import React from 'react';
import clsx from 'clsx';

import { AiIcon, AccountIcon } from '../../../assets/icons';
import { ERROR_TXT, LOADING_TXT, useSearchStore } from '../../../stores';

import { useStyles } from './ChatAI.styles';

const ChatAI = () => {
  const styles = useStyles();

  const chatMessages = useSearchStore((state) => state.chatMessages);

  return (
    <div className={styles.searchChatContainer}>
      {chatMessages.map(({ isAi, message }, idx) => {
        const splitMessage = message?.split('\n');
        const messageWithNewLines = splitMessage.map((msg, idx) => (
          <div key={idx}>{msg}</div>
        ));

        return (
          <div
            key={idx}
            className={clsx(styles.chatMessage, {
              [styles.chatMessageReverse]: !isAi
            })}
          >
            <div className={styles.author}>
              <div
                className={clsx(
                  styles.icon,
                  isAi ? styles.aiIcon : styles.userIcon
                )}
              >
                {isAi ? <AiIcon /> : <AccountIcon />}
              </div>
            </div>
            <div className={isAi ? '' : styles.userMsg}>
              <div>
                {isAi ? (
                  message === LOADING_TXT ? (
                    <div className={styles.dots} />
                  ) : message === ERROR_TXT ? (
                    <div className={styles.msgError}>{message}</div>
                  ) : (
                    messageWithNewLines
                  )
                ) : (
                  messageWithNewLines
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatAI;
