import {
  FETCH_VIDEO_DETAILS_ERROR,
  FETCH_VIDEO_RECORDINGS_SUCCESS,
  FETCH_VIDEO_RECORDINGS_POST_SUCCESS,
  FETCH_VIDEO_RECORDING_SUCCESS,
  FETCH_VIDEO_RECORDING_REQUEST
} from '../constants/actionTypes';

import {
  API_GET_RECORDING,
  API_POST_RECORDING,
  API_DELETE_RECORDING
} from '../api/apiEndpoints';

export function videoDetailsIsLoading() {
  return {
    type: FETCH_VIDEO_RECORDING_REQUEST
  };
}

export function videoDetailsFetchError(error) {
  return {
    type: FETCH_VIDEO_DETAILS_ERROR,
    payload: error
  };
}

export function videoRecordingFetchSuccess(recording) {
  return {
    type: FETCH_VIDEO_RECORDING_SUCCESS,
    payload: recording
  };
}

export function videoRecordingsFetchSuccess(recordings) {
  return {
    type: FETCH_VIDEO_RECORDINGS_SUCCESS,
    payload: recordings
  };
}

export function videoRecordingsPostSuccess(details) {
  return {
    type: FETCH_VIDEO_RECORDINGS_POST_SUCCESS,
    payload: details
  };
}

export function programFetchRecording(id) {
  return (dispatch) => {
    dispatch(videoDetailsIsLoading());

    fetch(API_GET_RECORDING(id))
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response;
      })
      .then((response) => response.json())
      .then((response) => dispatch(videoRecordingFetchSuccess(response)))
      .catch((error) => {
        const message = error.message || error;
        console.error(`ERROR in recordFetchRecording (${id}): ${message}`);

        return dispatch(videoDetailsFetchError(message));
      });
  };
}

export function programPostRecording(body) {
  return (dispatch) => {
    dispatch(videoDetailsIsLoading());

    fetch(API_POST_RECORDING(body), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json())
      .then((response) => dispatch(videoRecordingsPostSuccess(response)))
      .catch((error) => {
        const message = error.message || error;
        console.error(
          `ERROR in programPostRecordings (${body.id}): ${message}`
        );

        return dispatch(videoDetailsFetchError(message));
      });
  };
}

export function programDeleteRecording(body) {
  return (dispatch) => {
    dispatch(videoDetailsIsLoading());

    fetch(API_DELETE_RECORDING(body), { method: 'DELETE' })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json())
      .then((response) => dispatch(videoRecordingsPostSuccess(response)))
      .catch((error) => {
        const message = error.message || error;
        console.error(
          `ERROR in programFetchRecordings (${body.id}): ${message}`
        );

        return dispatch(videoDetailsFetchError(message));
      });
  };
}
