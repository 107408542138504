/* eslint-disable react/prop-types */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Typography,
  IconButton,
  DialogContentText
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../components/ProgramDetailsDialog/ProgramDetailsDialog.styles';
import { Close as CloseIcon } from '@material-ui/icons';

const Modal = ({
  title,
  text,
  handleConfirm,
  handleCancel,
  classes: {
    modalLight,
    modalTitle,
    modalTitleText,
    modalCloseButton,
    modalCloseIcon,
    modalContent
  }
}) => {
  return (
    <Dialog open="true" onClose={handleCancel} classes={{ paper: modalLight }}>
      <DialogTitle classes={{ root: modalTitle }}>
        <Typography className={modalTitleText}>{title}</Typography>
        <IconButton
          disableRipple
          onClick={handleCancel}
          className={modalCloseButton}
        >
          <CloseIcon className={modalCloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: modalContent }}>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} variant="contained" color="secondary">
          OK
        </Button>
        <Button onClick={handleCancel} variant="contained" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Modal);
