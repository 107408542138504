import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

import { PrevStateFn, SetType } from '../storeTypeUtils';
import { useSearchStore } from './searchStore';

export type RegularSearchSlice = {
  /** `searchQueryStr` is a value of search query (triggers search api) */
  searchQueryStr: string;
  setSearchQueryStr: (input: string) => void;
  /** `searchStr` is a value of input (not triggers search api) */
  searchStr: string;
  setSearchStr: (input: string | PrevStateFn<string>) => void;
};

export function regularSearchSlice(set: SetType<RegularSearchSlice>) {
  return {
    searchQueryStr: '',
    setSearchQueryStr: (input: string) => {
      set({ searchQueryStr: input }, false, 'setSearchQueryStr');
    },
    searchStr: '',
    setSearchStr: (input: string | PrevStateFn<string>) => {
      const setRest = [false, 'setSearchStr'] as const;
      if (typeof input === 'string') {
        set({ searchStr: input }, ...setRest);
      } else {
        set((state) => ({ searchStr: input(state.searchStr) }), ...setRest);
      }
    }
  } satisfies RegularSearchSlice;
}

export const useSetSearchQueryStr = () => {
  const setSearchQueryStr_ = useSearchStore((state) => state.setSearchQueryStr);

  const history = useHistory();
  const { pathname } = useLocation();
  const goToSearchPage = useCallback(() => history.push('/search'), [history]);
  const setSearchQueryStr = useCallback(
    (query: string) => {
      setSearchQueryStr_(query);
      if (pathname !== '/search') goToSearchPage();
    },
    [goToSearchPage, pathname, setSearchQueryStr_]
  );

  return setSearchQueryStr;
};
