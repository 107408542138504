import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type LoopStore = {
  isLooping: boolean;
  setIsLooping: (input: boolean) => void;
};

export const useLoopStore = create<LoopStore>()(
  devtools(
    persist(
      (set) => ({
        isLooping: false,
        setIsLooping: (input: boolean) => {
          set({ isLooping: input }, false, 'setIsLooping');
        }
      }),
      {
        name: 'loop-store'
      }
    )
  )
);
