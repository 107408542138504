import { MutableRefObject, useEffect } from 'react';

type AnyFunctionType = (...args: any[]) => any;

export const useClickOutside = (
  ref: MutableRefObject<Element | null>,
  cb: AnyFunctionType,
  disabled = false,
  ignoreElements: Element[] = []
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Element) &&
        !ignoreElements.some((element) =>
          element.contains(event.target as Element)
        )
      ) {
        !disabled && cb();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [ref, cb, disabled, ignoreElements]);
};
