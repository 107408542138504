import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  bar: {
    backgroundColor: '#414042b3',
    borderRadius: '30px',
    display: 'flex',
    height: '50px',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: ' 0 10px',
    width: '96%',
    gap: 10,
    transition: 'opacity 0.3s'
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none'
  }
}));
