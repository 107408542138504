import React from 'react';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';

import { useStyles } from './DashboardTile.styles';

const propTypes = {
  title: oneOfType([string, node]),
  link: node,
  children: oneOfType([node, arrayOf(node)]),
  disablePadding: bool
};

const defaultProps = {
  title: '',
  children: null,
  disablePadding: false
};

const DashboardTile = ({ title, link, children, disablePadding }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={disablePadding ? { padding: 0 } : {}}>
      {title && (
        <p className={classes.title}>
          <span>{title}</span>
          {link}
        </p>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
};

DashboardTile.propTypes = propTypes;
DashboardTile.defaultProps = defaultProps;

export default DashboardTile;
