import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  banner: {
    height: 200,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200%'
  },
  bannerOverlay: {
    height: '100%',
    width: '100%',
    backgroundImage: 'linear-gradient(to bottom, transparent, #000)'
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '32px',
    textShadow: '2px 2px 0px #000',
    top: '50%',
    left: 60,
    marginTop: -50,
    marginBottom: 0,
    position: 'absolute',
    zIndex: 1,
    borderBottom: `2px solid ${theme.palette.secondary.main}`
  },
  description: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    textShadow: '2px 2px 0px #000',
    top: '50%',
    left: 60,
    marginBottom: 0,
    position: 'absolute',
    zIndex: 1
  },
  showTagButton: {
    backgroundColor: '#f37037',
    boxShadow: '3px 2px 0 2px #000',
    fontWeight: 'bold',
    color: '#fff',
    fontSize: '16px',
    textAlign: 'center',
    padding: '10px 60px 10px 15px',
    display: 'block',
    position: 'absolute',
    top: '20%',
    right: 0,
    transition: 'all .4s ease',
    cursor: 'pointer',
    zIndex: 1,
    borderRadius: '0 0 0 3px'
  },
  showTagButtonAfter: {
    display: 'block',
    height: '100%',
    width: 0,
    position: 'absolute',
    top: 0,
    left: '-14px',
    borderTop: '35px solid #f37037',
    borderLeft: '14px solid transparent',
    borderBottom: '0px solid transparent',
    borderRight: '0px solid transparent'
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    width: '100%',
    maxWidth: 804,
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: 'none'
  },
  popper: {
    opacity: 1
  },
  tooltip: {
    borderRadius: 0,
    transform: 'none !important'
  }
}));
