import { combineReducers } from 'redux';
import { groups } from './groups.reducer';
import { tutorial } from './tutorial.reducer';
import { video } from './video.reducer';
import { videoNav } from './videoNav.reducer';
import { videoPromo } from './videoPromo.reducer';
import { programGuide } from './programGuide.reducer';
import { dashboard } from './dashboard.reducer';
import { recordings } from './recordings.reducer';
import { videoContentManagement } from './videoContentManagement.reducer';

const rootReducer = combineReducers({
  groups,
  programGuide,
  video,
  videoNav,
  videoPromo,
  tutorial,
  dashboard,
  recordings,
  videoContentManagement
});

export default rootReducer;
