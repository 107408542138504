import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';

import Routes from '../../routes';
import Header from '../Header';
import Search from '../Header/Search';
import ChatAI from '../Header/ChatAI';
import { useSearchStore } from '../../stores';
import { useInitAiSearch } from '../../api/searchAi';

import Drawer from '../Drawer';
import { useUserPolling } from './hooks';

import { theme, useStyles } from './App.styles';

const App = () => {
  const styles = useStyles();
  const { isChatOpen } = useSearchStore((state) => ({
    isChatOpen: state.isChatOpen
  }));

  useUserPolling();

  // init ai search globally
  useInitAiSearch();

  return (
    <MuiThemeProvider theme={theme}>
      <div className={styles.app}>
        <Header searchElements={<Search />} className={styles.header} />
        <main
          className={clsx(styles.content, {
            [styles.contentShift]: isChatOpen
          })}
        >
          <Routes />
        </main>
        <Drawer>
          <div>
            <ChatAI />
          </div>
        </Drawer>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
