import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  timeRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    paddingLeft: 10,
    transition: 'all .2s'
  },
  text: {
    color: '#e6e7e8',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 300,
    userSelect: 'none',
    lineHeight: '19px',
    width: 100,
    whiteSpace: 'nowrap'
  },
  sliderRoot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    width: '100%',
    height: '20px',

    '&:hover $sliderTrack, &:hover $sliderBuffer': {
      height: 5
    },
    '&:hover $sliderThumb': {
      width: 15,
      height: 15,
      '&::after': {
        opacity: 1,
        transform: 'translate(-50%, -125%)'
      }
    }
  },
  sliderTrack: {
    backgroundColor: '#73859f80',
    position: 'relative',
    borderRadius: '9999px',
    height: '3px',
    cursor: 'pointer',
    transition: 'all .2s',
    margin: '0 auto',
    width: '100%'
  },
  sliderBuffer: {
    position: 'absolute',
    backgroundColor: '#73859fbf',
    borderRadius: '9999px',
    height: 3,
    width: 0
  },
  sliderRange: {
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: '9999px',
    height: '100%'
  },
  sliderThumb: {
    position: 'relative',
    display: 'block',
    width: '9px',
    height: '9px',
    backgroundColor: '#f37037',
    boxShadow: '0 0 1px gray',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all .2s',

    '&:focus-visible': {
      boxShadow: '0 0 1em #fff',
      outline: 'none'
    },

    '&::after': {
      opacity: 0,
      content: 'attr(data-current-time)',
      position: 'absolute',
      borderRadius: 3,
      pointerEvents: 'none',
      padding: '6px 8px 8px',
      color: '#000',
      backgroundColor: '#fffc',
      fontSize: 10,
      fontFamily: 'serif,Times,Times New Roman',
      left: '50%',
      transform: 'translate(-50%, -105%)',
      top: 0,
      transition: 'all .2s'
    }
  }
}));
