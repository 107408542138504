import React, { useEffect, useState } from 'react';
import { object, shape } from 'prop-types';
import { Drawer, useMediaQuery } from '@material-ui/core';
import {
  Theaters as TheatersIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import MatchedVideos from '../../components/matchingsPage/MatchedVideos';
import VideoContainer from '../../components/video/VideoContainer';
import { useResizeObserver } from '../../hooks';
import { shuffleArray } from '../../shared';

import { useConnectStateMatchings, useVideoWidth } from './hooks';

import { styles } from './MatchingsPage.styles';
import { useVideo } from '../../api/video';

const propTypes = {
  classes: object,
  match: shape({
    params: object
  }).isRequired
};

const defaultProps = { classes: {} };

const PlatformIntelligencePage = ({ classes }) => {
  const [
    bindResizeObserver,
    { width: videoContainerWidth, height: videoContainerHeight }
  ] = useResizeObserver(50);
  const videoWidth = useVideoWidth(videoContainerWidth, videoContainerHeight);

  const smallerScreen = useMediaQuery('(max-width: 1366px)');

  const { id = '' } = useParams();
  const { data: mainVideo } = useVideo(id);

  const { areLoading, haveError, error, sampleMatchings } =
    useConnectStateMatchings(id);

  const [matchedVideo, setMatchedVideo] = useState({});
  const [selectedThumbIdx] = useState(0);
  const [bottomNavbarOpen, setBottomNavbarOpen] = useState(true);

  const [shuffledSampleMatchings, setShuffledSampleMatchings] = useState([]);
  useEffect(
    () => void setShuffledSampleMatchings(shuffleArray(sampleMatchings)),
    [selectedThumbIdx, sampleMatchings]
  );

  return (
    <div
      className={!bottomNavbarOpen ? classes.root : classes.rootReverse}
      style={{
        height: bottomNavbarOpen
          ? smallerScreen
            ? 'calc(100% - 142px)'
            : 'calc(100% - 187px)'
          : smallerScreen
            ? 'calc(100% - 32px)'
            : 'calc(100% - 42px)'
      }}
    >
      {areLoading && (
        <Loader loaderProps={{ size: 80 }} fullHeightCentralized />
      )}
      {haveError && <ErrorMessage error={error} fullHeightCentralized />}

      <div className={classes.videosLayout} ref={bindResizeObserver}>
        <div className={classes.videoWrapper} style={{ width: videoWidth }}>
          {mainVideo?.src_list && mainVideo?.src_list.length > 0 && (
            <VideoContainer videoObject={mainVideo} />
          )}
        </div>
        <div className={classes.videoWrapper} style={{ width: videoWidth }}>
          {matchedVideo.src_list && matchedVideo.src_list.length > 0 ? (
            <VideoContainer videoObject={matchedVideo} />
          ) : (
            <div className={classes.selectMsg} style={{ width: videoWidth }}>
              Please select matched video{' '}
              <TheatersIcon style={{ fontSize: 34, marginLeft: 5 }} />
            </div>
          )}
        </div>
      </div>

      <button
        onClick={() => setBottomNavbarOpen((i) => !i)}
        className={classes.matchesTitle}
      >
        <div>Matched Videos</div>
        <div
          className={classes.arrowIcon}
          style={{ transform: `rotate(${bottomNavbarOpen ? 0 : 180}deg)` }}
        >
          <KeyboardArrowDownIcon />
        </div>
      </button>

      <Drawer
        variant="persistent"
        anchor="bottom"
        open={bottomNavbarOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <MatchedVideos
          videos={shuffledSampleMatchings}
          onMatchSelect={setMatchedVideo}
        />
      </Drawer>
    </div>
  );
};

PlatformIntelligencePage.propTypes = propTypes;
PlatformIntelligencePage.defaultProps = defaultProps;

export default withStyles(styles)(PlatformIntelligencePage);
