const tooltip = {
  borderRadius: 0,
  transform: 'none !important',
  fontSize: 15,
  marginTop: 5,
  marginLeft: 10
};

export const styles = () => ({
  popper: {
    opacity: 1
  },
  tooltip,
  tooltipWithShadow: {
    ...tooltip,
    boxShadow: '3px 2px 17px 1px rgba(0, 0, 0, 0.64)'
  }
});
