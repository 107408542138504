import React, { HTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import { useStyles } from './ControlsBlock.styles';

const ControlsBlock = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const styles = useStyles();
  return <div ref={ref} className={clsx(className, styles.block)} {...props} />;
});
ControlsBlock.displayName = 'ControlsBlock';

export default ControlsBlock;
