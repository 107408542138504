import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  errorMessage: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '32px',
    lineHeight: '43px',
    fontStyle: 'italic',
    opacity: 0.5,
    textAlign: 'center'
  }
}));
