import React, { forwardRef, HTMLAttributes } from 'react';
import { Input, InputProps, InputAdornment } from '@material-ui/core';
import clsx from 'clsx';

import Loader from '../../Loader';

import { useStyles } from './HeadInput.styles';

type Props = InputProps & {
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
  isLoading?: boolean;
};

const HeadInput = forwardRef<HTMLInputElement, Props>(
  ({ value = '', wrapperProps, isLoading = false, ...props }, ref) => {
    const styles = useStyles();

    return (
      <div
        {...wrapperProps}
        className={clsx(styles.root, wrapperProps?.className)}
      >
        <Input
          value={value}
          inputRef={ref}
          type="text"
          endAdornment={
            <InputAdornment position="end">
              {isLoading && (
                <Loader
                  loaderProps={{
                    size: 16,
                    color: 'secondary',
                    className: styles.inputLoader
                  }}
                />
              )}
            </InputAdornment>
          }
          {...props}
          classes={{
            input: styles.input,
            underline: styles.cssUnderline,
            ...props.classes
          }}
        />
      </div>
    );
  }
);
HeadInput.displayName = 'HeadInput';

export default HeadInput;
