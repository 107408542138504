import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  userPage: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: '10px 50px 20px 50px',
    flex: 1,
    color: 'white'
  }
}));
