export const styles = () => ({
  videoListPage: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: '10px 50px 20px 50px',
    flex: 1
  },
  contentWithPlayer: {
    padding: '10px 50px 20px 50px',
    flex: 1,
    marginTop: '-220px',
    zIndex: 3
  }
});
