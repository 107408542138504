/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Fab } from '@material-ui/core';
import { PlayArrow, VolumeOff, VolumeUp } from '@material-ui/icons';

import VideoContainer from '../video/VideoContainer';
import { useVideo } from '../../api/video';
import { useDetectIdle } from '../../hooks';

import { useStyles } from './Billboard.styles';

const Billboard = () => {
  // TODO: replace hardcoded 'ukraine-vertex' with item from groups when groups implemented using react-query
  // const item = groups[0]?.items?.[0];
  const { data: video } = useVideo('ukraine-vertex');

  const {
    detectIdleRef,
    isMouseMoving,
    onMouseMove,
    setMouseMoving,
    isFocusWithin
  } = useDetectIdle();
  const [isMouseOverControls, setIsMouseOverControls] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const [muted, setMuted] = useState(true);
  useEffect(() => {
    const videoElem = videoRef.current;
    if (!videoElem) return;

    const mutedChangeHandler = () => {
      setMuted(Boolean(videoElem.volume === 0 || videoElem.muted));
    };

    videoElem.addEventListener('volumechange', mutedChangeHandler);

    return () => {
      videoElem.removeEventListener('volumechange', mutedChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef.current]);

  const styles = useStyles();

  return (
    <div
      className={styles.root}
      ref={detectIdleRef}
      onMouseMove={onMouseMove}
      onMouseLeave={() => setMouseMoving(false)}
    >
      {video && (
        <VideoContainer
          videoObject={video}
          ref={videoRef}
          hideControls
          autoplay
          isLooping
          className={styles.player}
          videoTabIndex={-1}
        />
      )}
      <div className={styles.videoOverlay}>
        <div
          className={styles.videoOverlayContent}
          style={{
            opacity:
              isMouseMoving || isMouseOverControls || isFocusWithin ? 1 : 0
          }}
          onMouseEnter={() => setIsMouseOverControls(true)}
          onMouseLeave={() => setIsMouseOverControls(false)}
        >
          <div className={styles.videoOverlayText}>
            <p className={styles.videoOverlayTitle}>{video?.title}</p>
            <p className={styles.videoOverlayDescription}>
              {video?.description}
            </p>
          </div>
          <div className={styles.controlsContainer}>
            <Link
              className={styles.link}
              // TODO: replace with item.id when groups implemented using react-query
              // to={{ pathname: `/videos/${id}` }}>
              to={{ pathname: '/videos/ukraine-vertex' }}
            >
              <Button
                disableRipple
                tabIndex={-1}
                variant="contained"
                color="secondary"
                className={styles.button}
                classes={{ label: styles.buttonLabel }}
              >
                Play
                <PlayArrow />
              </Button>
            </Link>
            <Fab
              disableRipple
              size="medium"
              color="secondary"
              className={styles.button}
              onClick={() => {
                const videoElem = videoRef.current;
                if (!videoElem) return;
                const currentVolume = videoElem.volume;
                videoElem.volume = currentVolume === 0 ? 1 : 0;
                videoElem.muted = currentVolume === 0 ? false : true;
              }}
            >
              {muted ? <VolumeOff /> : <VolumeUp />}
            </Fab>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billboard;
