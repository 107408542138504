import { request } from '../request';

import {
  epgUrl,
  epgChannelUrl,
  getProgramUrl,
  getChannelUrl
} from './channelsUrls';
import { Channel, EpgChannel, Epg, Program } from './channelsTypes';

export const getEpg = () => request<Epg[]>(epgUrl);

export const getEpgChannel = (channelId: string) =>
  request<EpgChannel>(epgChannelUrl(channelId));

export const getProgramDetails = (programId: string) =>
  request<Program>(getProgramUrl(programId));

export const getChannel = (id: string) => request<Channel>(getChannelUrl(id));
