import { createTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  channelColumn: {
    backgroundColor: '#1D1E21',
    borderBottom: '2px solid black',
    borderRight: '1px solid black',
    zIndex: '100'
  },
  channel: {
    height: 60,
    borderBottom: '2px solid black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'content-box'
  },
  channelLinkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  channelLink: {
    display: 'block',
    height: 'calc(100% - 20px)'
  },
  channelImage: {
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  },
  channelProgram: {
    whiteSpace: 'nowrap',
    position: 'relative',
    height: 62,
    userSelect: 'none'
  },
  currentTimeVerticalLine: {
    borderRight: '2px solid #F37037',
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: '1'
  },
  dateTitle: {
    position: 'relative',
    order: 2,
    minHeight: '46px',
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  programColumn: {
    position: 'relative'
  },
  thumbHorizontal: {
    backgroundColor: `rgb(243, 112, 55, .8)`,
    borderRadius: 3
  },
  timeline: {
    backgroundColor: '#1D1E21',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    display: 'flex',
    userSelect: 'none'
  },
  timelineBlock: {
    display: 'inline-block',
    borderRight: '2px solid #5a5c60',
    backgroundColor: '#1D1E21',
    flexShrink: '0',
    position: 'relative',
    height: '20px',
    boxSizing: 'border-box',
    '&::after': {
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: '50%',
      top: '0',
      bottom: 'calc(100% - 4px)',
      display: 'block',
      content: '""',
      border: '1px solid #868789'
    },
    '&::before': {
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: '50%',
      top: 'calc(100% - 3px)',
      bottom: '0',
      display: 'block',
      content: '""',
      border: '1px solid #868789'
    }
  },
  timelineItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    color: '#868789',
    textAlign: 'center',
    fontSize: '11px',
    fontFamily: 'Open Sans, sans-serif'
  },
  iconSwitch: {
    margin: '5px',
    marginTop: '10px'
  }
}));

export const calendarTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F37037'
      }
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: '#F37037',
        '&:hover': {
          backgroundColor: '#F37037',
          fontWeight: 'bold'
        }
      },
      current: {
        color: '#F37037'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#F37037'
      }
    },
    MuiFormControl: {
      root: {
        color: '#FFF',
        padding: '14px 20px',
        paddingLeft: '20px',
        paddingTop: '12px',
        paddingRight: '0'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: '#FFF !important'
        },
        '&:after': {
          borderBottomColor: '#F37037'
        }
      }
    },
    MuiInputBase: {
      input: {
        color: '#D8D8D8',
        width: '120px'
      }
    },
    MuiIconButton: {
      root: {
        color: '#FFF',
        padding: 0,
        margin: '5px'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#D8D8D8'
      },
      '&$checked': {
        color: '#F37037'
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});
