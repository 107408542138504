/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
// commented by request of Alex Pogrebnoy
// import Highlighter from 'react-highlight-words';

import { tooltipTags } from '../../constants/tooltipsHTML';
import { getImageUrl } from '../../shared';

import { styles } from './VideoCard.styles';

const VideoCard = ({
  video,
  classes,
  className,
  isFromSearch = false,
  // commented by request of Alex Pogrebnoy
  // searchWords,
  actions
}) => (
  <div
    className={
      (video.speech ? classes.cardWithSpeechWrapper : classes.cardWrapper) +
      ' ' +
      className
    }
  >
    <Card className={classes.card}>
      <Link
        className={classes.link}
        to={{
          pathname: `/videos/${video.id}`,
          state: { position: video.position, isFromSearch }
        }}
      >
        <CardActionArea
          classes={{
            root: classes.cardRoot,
            focusHighlight: classes.cardFocusHighlight
          }}
        >
          <CardMedia
            className={classes.image}
            image={getImageUrl(video.thumbnail)}
            title={video.title}
          >
            {video.thumbnailOnHover && (
              <CardMedia
                className={classes.imageOnHover}
                image={video.thumbnailOnHover}
              />
            )}
            {Array.isArray(video.flags) && (
              <div className={classes.flagsWrapper}>
                {video.flags.map((flag) =>
                  tooltipTags[flag.toLowerCase()] ? (
                    <Tooltip
                      interactive
                      key={flag}
                      title={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: tooltipTags[flag.toLowerCase()]
                          }}
                        />
                      }
                      classes={{
                        popper: classes.popper,
                        tooltip: classes.tooltip
                      }}
                    >
                      <Chip label={flag} className={classes.flag} key={flag} />
                    </Tooltip>
                  ) : (
                    <Chip label={flag} className={classes.flag} key={flag} />
                  )
                )}
              </div>
            )}
            {!isNaN(video.position) && (
              <Chip
                label={new Date(video.position * 1000)
                  .toISOString()
                  .substr(11, 8)}
                className={classes.time}
              />
            )}
          </CardMedia>

          <CardContent className={classes.content}>
            <Typography className={classes.title}>
              {/* commented by request of Alex Pogrebnoy */}
              {/* <Highlighter
                highlightClassName={classes.highlightText}
                searchWords={searchWords || []}
                textToHighlight={video.title}
              /> */}
              {video.title}
            </Typography>
            {video.speech && (
              <Typography className={classes.speech}>
                {/* commented by request of Alex Pogrebnoy */}
                {/* <Highlighter
                  highlightClassName={classes.highlightText}
                  searchWords={searchWords || []}
                  textToHighlight={video.speech}
                /> */}
                {video.speech}
              </Typography>
            )}
          </CardContent>

          {actions && (
            <CardActions className={classes.actions} disableActionSpacing>
              {actions}
            </CardActions>
          )}
        </CardActionArea>
      </Link>
    </Card>
  </div>
);

export default withStyles(styles)(VideoCard);
