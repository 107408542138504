import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SoundHigh = (props: SvgIconProps) => (
  <SvgIcon
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="200px"
    width="200px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="square"
      strokeMiterlimit="10"
      strokeWidth="32"
      d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"
    />
    <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z" />
  </SvgIcon>
);

export const SoundMedium = (props: SvgIconProps) => (
  <SvgIcon
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="200px"
    width="200px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M157.65 176.1H64v159.8h93.65L288 440V72L157.65 176.1z" />
    <path
      fill="none"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M352 320c9.74-19.41 16-40.81 16-64 0-23.51-6-44.4-16-64m48 176c19.48-34 32-64 32-112s-12-77.7-32-112"
    />
  </SvgIcon>
);

export const SoundLow = (props: SvgIconProps) => (
  <SvgIcon
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="200px"
    width="200px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m391.12 341.48-28.6-14.36 7.18-14.3c9.49-18.9 14.3-38 14.3-56.82 0-19.36-4.66-37.92-14.25-56.73L362.48 185 391 170.48l7.26 14.25C410.2 208.16 416 231.47 416 256c0 23.83-6 47.78-17.7 71.18zM189.65 176.1H96v159.8h93.65L320 440V72L189.65 176.1z" />
  </SvgIcon>
);

export const SoundMute = (props: SvgIconProps) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="200px"
    width="200px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="square"
      strokeMiterlimit="10"
      strokeWidth="32"
      d="M416 432 64 80"
    />
    <path d="M352 256c0-24.56-5.81-47.88-17.75-71.27L327 170.47 298.48 185l7.27 14.25C315.34 218.06 320 236.62 320 256a112.91 112.91 0 0 1-.63 11.74l27.32 27.32A148.8 148.8 0 0 0 352 256zm64 0c0-51.19-13.08-83.89-34.18-120.06l-8.06-13.82-27.64 16.12 8.06 13.82C373.07 184.44 384 211.83 384 256c0 25.93-3.89 46.21-11 65.33l24.5 24.51C409.19 319.68 416 292.42 416 256z" />
    <path d="M480 256c0-74.26-20.19-121.11-50.51-168.61l-8.61-13.49-27 17.22 8.61 13.49C429.82 147.38 448 189.5 448 256c0 48.76-9.4 84-24.82 115.55l23.7 23.7C470.16 351.39 480 309 480 256zM256 72l-73.6 58.78 73.6 73.59V72zM32 176.1v159.8h93.65L256 440V339.63L92.47 176.1H32z" />
  </svg>
);
