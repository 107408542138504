/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { Link as ExternalLink, IconButton, SvgIcon } from '@material-ui/core';

import { styles } from './WelcomeTour.styles';

class WelcomeTour extends Component {
  state = {
    open: false,
    activeStep: 0,
    tutorialShow: false
  };

  componentDidMount() {
    const showTutorial = localStorage.getItem('tutorialShow');
    if (showTutorial === 'hideTutorial') {
      this.setState({ tutorialShow: false, open: false });
    } else if (showTutorial === 'showTutorial') {
      this.setState({ tutorialShow: true, open: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showTutorial !== this.props.showTutorial) {
      this.setState({ open: true });
    }
  }

  handleCheckboxChange = () => () => {
    this.setState({ tutorialShow: !this.state.tutorialShow });
    if (this.state.tutorialShow) {
      localStorage.setItem('tutorialShow', 'hideTutorial');
    } else {
      localStorage.setItem('tutorialShow', 'showTutorial');
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    sessionStorage.setItem('sessionTutorialShow', 'hideTutorial');
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  render() {
    const classes = this.props.classes;
    const { theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = this.props.tutorialSteps.length;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <div className={classes.modalBlock}>
          <Typography variant="h5">
            Welcome!
            <IconButton
              disableRipple={false}
              className={classes.closeButton}
              onClick={this.handleClose}
            >
              <SvgIcon>
                <path d="M11.6786672,10 L19.6523366,17.9736694 C20.1158878,18.4372205 20.1158878,19.1887855 19.6523366,19.6523366 C19.1887855,20.1158878 18.4372205,20.1158878 17.9736694,19.6523366 L10,11.6786672 L2.02633061,19.6523366 C1.56277945,20.1158878 0.811214527,20.1158878 0.347663369,19.6523366 C-0.11588779,19.1887855 -0.11588779,18.4372205 0.347663369,17.9736694 L8.32133276,10 L0.347663369,2.02633061 C-0.11588779,1.56277945 -0.11588779,0.811214527 0.347663369,0.347663369 C0.811214527,-0.11588779 1.56277945,-0.11588779 2.02633061,0.347663369 L10,8.32133276 L17.9736694,0.347663369 C18.4372205,-0.11588779 19.1887855,-0.11588779 19.6523366,0.347663369 C20.1158878,0.811214527 20.1158878,1.56277945 19.6523366,2.02633061 L11.6786672,10 Z" />
              </SvgIcon>
            </IconButton>
          </Typography>
          <div className={classes.root}>
            <Paper square elevation={0} className={classes.header}>
              {this.props.tutorialSteps[activeStep] && (
                <Typography>
                  {this.props.tutorialSteps[activeStep].alt}
                </Typography>
              )}
            </Paper>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {this.props.tutorialSteps.map((step, index) => (
                <div key={step.id}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <ExternalLink href={step.url} target="_blank">
                      <img
                        className={classes.img}
                        src={step.url}
                        alt={step.alt}
                      />
                    </ExternalLink>
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            <MobileStepper
              variant="progress"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
              classes={{
                progress: classes.mobileStepperProgres
              }}
              nextButton={
                <Button
                  size="small"
                  onClick={this.handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={this.handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </div>

          <FormGroup row>
            <FormControlLabel
              classes={{
                label: classes.checkboxWrp
              }}
              control={
                <Checkbox
                  checked={!this.state.tutorialShow}
                  onChange={this.handleCheckboxChange('tutorialShow')}
                  value="tutorialShow"
                  classes={{
                    root: classes.checkboxBase,
                    checked: classes.checkboxChecked
                  }}
                />
              }
              label="don`t show this tutorial"
            />
          </FormGroup>
        </div>
      </Modal>
    );
  }
}

WelcomeTour.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(WelcomeTour);
