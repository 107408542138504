import React from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import VideoPageBannerSection from '../../components/VideoPageBannerSection';
import VideoDetails from '../../components/VideoDetails';
import { useVideo } from '../../api/video';

import { useStyles } from './RecordingVideoPage.styles';
import VideoContainer from '../../components/video/VideoContainer';

const RecordingVideoPage = () => {
  const { id = '' } = useParams();

  const { data: video, isLoading, isError, error } = useVideo(id);

  const styles = useStyles();

  let content = null;

  if (video) {
    content = (
      <div>
        <VideoPageBannerSection
          poster={video.poster}
          title={video.title}
          description={video.description}
          tagCloud={video.tagCloud}
        />
        <div style={{ marginTop: '-30px', position: 'relative' }}>
          <Grid
            container
            wrap-xs-nowrap="true"
            alignItems="stretch"
            justify="center"
            direction="row"
            className={styles.playerWrp}
          >
            {/* TODO: use new promo section */}
            {/* {videoPromo.length && videoPromoTime.length && (
                <Grid
                  item
                  className={styles.controls}
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                >
                  <VideoPagePromoSection
                    showTime={Math.round(currentTime)}
                    data={videoPromo}
                    timeLine={videoPromoTime}
                  />
                </Grid>
              )} */}

            <Grid
              item
              id="videoBlock"
              className={styles.videoWrapResponse}
              xs={12}
              sm={12}
              md
              lg
            >
              {video.src_list?.length > 0 && (
                <VideoContainer videoObject={video} autoplay />
              )}
              <VideoDetails programId={this.props.id} />
            </Grid>

            {/* TODO: use new carousel */}
            {/* {videoNav.length && (
                <Grid
                  item
                  className={styles.controls}
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                >
                  <VideoPageNavSection data={videoNav} onClick={setStartTime} />
                </Grid>
              )} */}
          </Grid>
        </div>
      </div>
    );
  }

  if (isLoading) {
    content = <Loader loaderProps={{ size: 80 }} fullHeightCentralized />;
  }

  if (isError) {
    content = <ErrorMessage error={error} fullHeightCentralized />;
  }

  return <div>{content}</div>;
};

export default RecordingVideoPage;
