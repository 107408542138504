import get from 'lodash/get';
import { useCallback, useRef, useState } from 'react';

import { baseUrl } from '../../../../api/apiEndpoints';
import { useGetUser } from '../../../../api/user';
import { ChannelMulti } from '../../../../api/channels';

import { VideoObject } from '../VideoContainer';

const regex = /\[[^[\]]*\]/g;

const getFullUrl = (src: string) => `${baseUrl}${src}`;

export const useReplaceParams = (
  src: string,
  videoObject: VideoObject,
  parentObject?: ChannelMulti
) => {
  const { data } = useGetUser();
  const user = data?.user;
  const srcWithParamsRef = useRef<string | null>(null);

  const [, forceUpdate] = useState(0);
  const resetSrcWithParams = useCallback(() => {
    srcWithParamsRef.current = null;
    forceUpdate((prev) => prev + 1);
  }, []);

  // if no [path.to.value] in src, return src as is
  if (!regex.test(src) && !srcWithParamsRef.current) {
    srcWithParamsRef.current = src.startsWith('http') ? src : getFullUrl(src);
    return [srcWithParamsRef.current, resetSrcWithParams] as const;
  }

  const absoluteSrc = src.startsWith('http') ? src : getFullUrl(src);
  const url = new URL(absoluteSrc);

  // just in case, making sure that value to replace has square brackets
  const allKeysToReplace = Array.from(url.searchParams.keys()).filter((key) => {
    const value = url.searchParams.get(key) ?? '';
    return value.startsWith('[') && value.endsWith(']');
  });

  allKeysToReplace.forEach((key) => {
    const valuePattern = url.searchParams.get(key) ?? '';
    const isUser = valuePattern.startsWith('[user.');
    const isVideo = valuePattern.startsWith('[video.');
    if (!isUser && !isVideo) {
      return console.warn(`Unexpected url param pattern: ${valuePattern}`);
    }
    const newValue = isUser
      ? get(user ?? {}, valuePattern.replace('[user.', '').replace(']', ''))
      : get(
          // parent object is a parent of multiscreen channel object if no data found in main object - it will look up in parent object
          { ...(parentObject ?? {}), ...videoObject },
          valuePattern.replace('[video.', '').replace(']', '')
        );

    if (!newValue) {
      // if value is not found, remove the key (e.g user not logged in and so, no user object available)
      url.searchParams.delete(key);
    } else if (
      typeof newValue === 'string' ||
      typeof newValue === 'number' ||
      typeof newValue === 'boolean'
    ) {
      // if value is primitive, set it as is
      url.searchParams.set(key, newValue.toString());
    } else if (Array.isArray(newValue)) {
      // if value is array, join it with comma
      url.searchParams.set(key, newValue.join(','));
    } else {
      console.warn(`Unexpected value type: ${typeof newValue}`);
    }
  });

  if (!srcWithParamsRef.current) {
    srcWithParamsRef.current = url.toString();
  }

  return [String(srcWithParamsRef.current), resetSrcWithParams] as const;
};
