import React, { memo, useEffect, useState } from 'react';
import { bool, object, string } from 'prop-types';
import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '../../Tooltip';
import { useResizeObserver } from '../../../hooks';
import { styles } from './CastCard.styles';

const propTypes = {
  classes: object.isRequired,
  isDirector: bool,
  subtitle: string,
  title: string,
  thumbnail: string,
  url: string
};

const defaultProps = {
  isDirector: false,
  subtitle: '',
  title: '',
  thumbnail: '',
  url: ''
};

const CastCard = ({ title, subtitle, url, thumbnail, isDirector, classes }) => {
  const [bindResizeObserver, { width }] = useResizeObserver();
  const [isEllipsisActive, setEllipsisActive] = useState(false);

  useEffect(() => {
    const elem = bindResizeObserver.current;
    elem && setEllipsisActive(elem.offsetWidth < elem.scrollWidth);
  }, [width, bindResizeObserver]);

  return (
    <Link href={url} className={classes.link} target="_blank">
      <div className={classes.avatarCard}>
        <img
          src={thumbnail}
          className={`${classes.avatar} ${
            isDirector ? classes.avatarWithBorder : ''
          }`}
          alt=""
        />
        <div className={classes.rightColumn}>
          <div className={classes.elipsisParent}>
            <Tooltip title={isEllipsisActive ? title : ''}>
              <div ref={bindResizeObserver} className={classes.title}>
                {title}
              </div>
            </Tooltip>
            <img src="/images/IMDB.png" className={classes.imdbIcon} alt="" />
          </div>
          <span className={classes.subTitle}>{subtitle}</span>
        </div>
      </div>
    </Link>
  );
};
CastCard.propTypes = propTypes;
CastCard.defaultProps = defaultProps;

export default memo(withStyles(styles)(CastCard));
