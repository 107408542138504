import { useState, useEffect } from 'react';

export function useVideoWidth(videoContainerWidth, videoContainerHeight) {
  const [videoWidth, setWideoWidth] = useState(0);

  useEffect(() => {
    const width = videoContainerHeight * 1.78 - 100;
    const height = (videoContainerWidth / 2 - 25) * 0.5625;
    const widthBasedOnHeight = height * 1.78 - 100;
    setWideoWidth(width < widthBasedOnHeight ? width : widthBasedOnHeight);
  }, [videoContainerWidth, videoContainerHeight]);

  return videoWidth;
}
