import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, IconButton } from '@material-ui/core';
import { Delete, Edit, PlayArrow } from '@material-ui/icons';
import { useMutation } from '@tanstack/react-query';

import { styles } from '../TranscodingPage.styles';
import WorkflowModal from './WorkflowModal';
import Modal from '../Modal';
import { TranscodingAPI } from '../../../api/transcoding';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const WorkflowsTab = () => {
  const classes = useStyles();
  const workflowsFromMem = JSON.parse(
    localStorage.getItem('workflows') || '[]'
  );
  const [workflows, setWorkflows] = useState(workflowsFromMem);
  const [action, setAction] = useState({});

  // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
  const { mutateAsync: runJob } = useMutation((workflow) =>
    TranscodingAPI.createJobs(workflow)
  );

  useEffect(() => {
    if (workflows) {
      localStorage.setItem('workflows', JSON.stringify(workflows));
    }
  }, [workflows]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 235px)' }}
      >
        <Table className={classes.table} stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Input</TableCell>
              <TableCell>Output</TableCell>
              <TableCell>Profile(s)</TableCell>
              <TableCell>partitionByTime</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workflows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.flow}</TableCell>
                <TableCell>{row.input}</TableCell>
                <TableCell>{row.output}</TableCell>
                <TableCell>
                  {row.profile} {(row.additionalProfiles || []).join(',')}
                </TableCell>
                <TableCell>{row.partitionByTime}</TableCell>
                <TableCell>
                  <IconButton
                    className={classes.notifyButton}
                    classes={{ root: classes.iconButton }}
                    style={{ padding: 0 }}
                    onClick={() => setAction({ type: 'edit', data: row })}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    className={classes.notifyButton}
                    classes={{ root: classes.iconButton }}
                    style={{ padding: 0 }}
                    onClick={() => setAction({ type: 'run', data: row })}
                  >
                    <PlayArrow />
                  </IconButton>
                  <IconButton
                    className={classes.notifyButton}
                    classes={{ root: classes.iconButton }}
                    style={{ padding: 0 }}
                    onClick={() => setAction({ type: 'delete', data: row })}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAction({ type: 'add', data: {} })}
        >
          Add workflow
        </Button>
      </div>
      {action.type === 'add' && (
        <WorkflowModal
          selectedItem={action.data}
          onSave={(newWorkflow) => {
            setWorkflows((prevState) => [...prevState, newWorkflow]);
            setAction({});
          }}
          handleClose={() => setAction({})}
        />
      )}
      {action.type === 'edit' && (
        <WorkflowModal
          selectedItem={action.data}
          onSave={(workflow) => {
            setWorkflows((prevState) => {
              const newState = prevState.slice();
              const i = prevState.findIndex((_) => _.name === workflow.name);
              newState[i] = workflow;
              return newState;
            });
            setAction({});
          }}
          handleClose={() => setAction({})}
        />
      )}
      {action.type === 'delete' && (
        <Modal
          title="Delete confirmation"
          text={`You're about to delete ${action.data.name} workflow. Do you want to proceed?`}
          handleConfirm={() => {
            setWorkflows((prevState) =>
              prevState.filter((_) => _.name !== action.data.name)
            );
            setAction({});
          }}
          handleCancel={() => setAction({})}
        />
      )}
      {action.type === 'run' && (
        <Modal
          title="Run confirmation"
          text={`You're about to run ${action.data.name} workflow. Do you want to proceed?`}
          handleConfirm={() => {
            const job = { ...action.data };
            delete job.name;
            delete job._id;
            runJob(job)
              .then(() => {})
              .finally(() => setAction({}));
          }}
          handleCancel={() => setAction({})}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(WorkflowsTab);
