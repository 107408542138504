export const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '60px',
    marginBottom: '10px'
  },
  columnLeft: {
    width: '164px',
    marginRight: '24px',
    display: 'flex',
    flexShrink: 0
  },
  columnRight: {
    display: 'flex',
    width: '100%',
    textDecoration: 'none'
  },
  channelThumbnail: {
    maxWidth: '120px',
    margin: 'auto'
  },
  playingNowThumbnail: {
    width: '106px',
    height: '60px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    flexShrink: 0
  },
  playingNowTitleWrp: {
    backgroundColor: '#1F1F1F',
    display: 'flex',
    width: '100%'
  },
  playingNowTitle: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '19px',
    overflow: 'hidden',
    height: '40px',
    margin: 'auto 0',
    padding: '0 12px'
  }
});
