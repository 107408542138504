import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions
} from '@tanstack/react-query';

import {
  UserLoginPayload,
  UserLoginResponse,
  UserResponse,
  UserLogoutResponse
} from './userTypes';
import { loginUser, logoutUser, getUser } from './userApi';

export const useLoginUser = (
  options: UseMutationOptions<
    UserLoginResponse,
    unknown,
    UserLoginPayload,
    unknown
  > = {}
) =>
  useMutation<UserLoginResponse, unknown, UserLoginPayload, unknown>({
    mutationFn: (data: UserLoginPayload) =>
      loginUser({ uid: data.uid, passwd: data.passwd }),
    ...options
  });

export const useLogoutUser = (
  options: UseMutationOptions<UserLogoutResponse> = {}
) =>
  useMutation<UserLogoutResponse, unknown, void, unknown>({
    mutationFn: logoutUser,
    ...options
  });

export const getUserQueryKey = ['user'];
export const useGetUser = (options: UseQueryOptions<UserResponse> = {}) =>
  useQuery<UserResponse>({
    queryKey: getUserQueryKey,
    queryFn: getUser,
    ...options
  });
