import { makeStyles } from '@material-ui/core/styles';

const gapPersonColumn = 40;

export const useStyles = makeStyles((theme) => ({
  hgroup: {
    display: 'grid',
    gridTemplate: 'auto 1fr / min-content auto auto',
    marginBottom: 19
  },
  imageContainer: {
    gridRowEnd: 'span 2'
  },
  dateContainer: {
    margin: '11px 0 0 20px'
  },
  genresContainer: {
    justifySelf: 'end',
    margin: '10px 0 0 10px'
  },
  titleContainer: {
    margin: '23px 0 0 20px',
    gridColumnEnd: 'span 2'
  },
  image: {
    width: 214,
    height: 120
  },
  tag: {
    color: '#F37037',
    fontFamily: 'Open Sans',
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingRight: 6,
    letterSpacing: 1,
    '&:last-child': {
      paddingRight: 0
    }
  },
  title: {
    color: '#E6E7E8',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 300,
    marginRight: 10
  },
  titleText: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: 17,
    fontWeight: 'bold',
    letterSpacing: 1.1
  },
  personsGrid: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, calc(33.33% - ${gapPersonColumn - gapPersonColumn * 0.33}px))`,
    gap: `15px ${gapPersonColumn}px`
  },
  storyline: {
    color: '#fff',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'justify',
    margin: '30px 4px 60px 4px'
  },
  programActions: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
