import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  sliderRoot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    width: '100%',
    height: 12
  },
  sliderTrack: {
    backgroundColor: 'rgba(243, 112, 55, 0.38)',
    position: 'relative',
    height: '2px',
    cursor: 'pointer',
    transition: 'all .2s',
    margin: '0 auto',
    width: '100%'
  },
  sliderRange: {
    position: 'absolute',
    backgroundColor: '#f3703780',
    height: '100%'
  },
  sliderThumb: {
    position: 'relative',
    display: 'block',
    width: '12px',
    height: '12px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 0px 0px 0px rgba(243, 112, 55, 0.16)',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all .2s',

    '&:focus-visible, &:hover': {
      boxShadow: '0px 0px 0px 8px rgba(243, 112, 55, 0.16)',
      outline: 'none'
    }
  },
  markers: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  marker: {
    backgroundColor: '#f3703780',
    width: '2px',
    height: '2px',
    borderRadius: '1px'
  },
  markerBehind: {
    opacity: 0.4,
    backgroundColor: '#fff'
  }
}));
