import React, { Component } from 'react';

export default class ChannelsContentManagementPage extends Component {
  render() {
    return (
      <div style={{ height: '100%', textAlign: 'center', paddingTop: '10%' }}>
        ChannelsContentManagementPage
      </div>
    );
  }
}
