import { makeStyles } from '@material-ui/core/styles';

import { drawerWidth, headerHeight } from '../App/App.styles';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: headerHeight,
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#202020',
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: '"Open Sans", sans-serif'
  },
  drawerContainer: {
    marginTop: headerHeight,
    overflow: 'hidden',
    padding: '0 18px 36px 30px',
    height: '100%',
    maxHeight: `calc(100% - ${headerHeight}px)`
  },
  chatWrapper: {
    overflow: 'hidden',
    height: '100%'
  },
  messagesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',

    marginBottom: 27
  },
  scrollable: {
    overflow: 'auto',
    paddingTop: 27,
    paddingRight: 12,

    '&::-webkit-scrollbar': {
      width: 4,
      backgroundColor: 'rgba(201, 201, 201, 0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#A1A1A1',
      borderRadius: 10
    },
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.secondary.main}`,
      outlineOffset: -2
    }
  },
  controls: {
    paddingRight: 12
  }
}));
