import { createTheme } from '@material-ui/core';

export const styles = () => ({
  headerWrapper: {},
  headerContainer: {
    backgroundColor: '#181818',
    display: 'flex',
    padding: '14px 20px 17px',
    justifyContent: 'space-between'
  },
  headerTitle: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#F37037',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

export const customTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F37037'
      }
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: '#F37037',
        '&:hover': {
          backgroundColor: '#F37037',
          fontWeight: 'bold'
        }
      },
      current: {
        color: '#F37037'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#F37037'
      }
    },
    MuiFormControl: {
      root: {
        color: '#FFF'
      }
    },
    MuiInput: {
      input: {
        padding: '0px 5px 3px'
      },
      underline: {
        '&:before': {
          borderBottomColor: '#FFF !important'
        },
        '&:after': {
          borderBottomColor: '#F37037'
        }
      }
    },
    MuiInputBase: {
      input: {
        color: '#D8D8D8'
      }
    },
    MuiIconButton: {
      root: {
        color: '#FFF',
        padding: '0 0 3px'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#D8D8D8'
      },
      '&$checked': {
        color: '#F37037'
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});
