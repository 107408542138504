export const styles = () => ({
  modal: {
    backgroundColor: '#1D1E21',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    width: 960,
    maxWidth: 'initial',
    borderRadius: 0
  },
  modalLight: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    width: 960,
    maxWidth: 'initial',
    borderRadius: 0
  },
  modalTitle: {
    height: 50,
    padding: 0,
    backgroundColor: '#141518'
  },
  modalTitleText: {
    color: '#F37037',
    fontFamily: 'Open Sans',
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '14px 20px',
    letterSpacing: 1
  },
  modalCloseButton: {
    position: 'absolute',
    right: 13,
    top: 10,
    color: '#D8D8D8',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'white'
    }
  },
  modalCloseIcon: { fontSize: 30 },
  modalContent: {
    padding: '10px 30px 60px 30px'
  }
});
