import {
  FETCH_DASHBOARD_RECOMMENDATIONS_REQUEST,
  FETCH_DASHBOARD_RECOMMENDATIONS_ERROR,
  FETCH_DASHBOARD_RECOMMENDATIONS_SUCCESS,
  FETCH_DASHBOARD_HOT_REQUEST,
  FETCH_DASHBOARD_HOT_ERROR,
  FETCH_DASHBOARD_HOT_SUCCESS,
  FETCH_DASHBOARD_TOP_CHANNELS_REQUEST,
  FETCH_DASHBOARD_TOP_CHANNELS_ERROR,
  FETCH_DASHBOARD_TOP_CHANNELS_SUCCESS,
  FETCH_DASHBOARD_FAVORITE_CHANNELS_REQUEST,
  FETCH_DASHBOARD_FAVORITE_CHANNELS_ERROR,
  FETCH_DASHBOARD_FAVORITE_CHANNELS_SUCCESS,
  FETCH_DASHBOARD_FAVORITE_VIDEOS_REQUEST,
  FETCH_DASHBOARD_FAVORITE_VIDEOS_ERROR,
  FETCH_DASHBOARD_FAVORITE_VIDEOS_SUCCESS
} from '../constants/actionTypes';

export const initialState = {
  recommendations: {
    isLoading: false,
    isFailed: false,
    data: []
  },
  hot: {
    isLoading: false,
    isFailed: false,
    data: []
  },
  topChannels: {
    isLoading: false,
    isFailed: false,
    data: []
  },
  favoriteChannels: {
    isLoading: false,
    isFailed: false,
    data: []
  },
  favoriteVideos: {
    isLoading: false,
    isFailed: false,
    data: []
  },
  lastRecordings: {
    isLoading: false,
    isFailed: false,
    data: []
  }
};

const handleRequest = (state, section) => ({
  ...state,
  [section]: { ...state[section], isLoading: true, data: [] }
});
const handleRequestError = (state, section) => ({
  ...state,
  [section]: { ...state[section], isLoading: false, isFailed: true }
});
const handleRequestSuccess = (state, section, data) => ({
  ...state,
  [section]: { ...state[section], isLoading: false, data }
});

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_RECOMMENDATIONS_REQUEST:
      return handleRequest(state, 'recommendations');
    case FETCH_DASHBOARD_RECOMMENDATIONS_ERROR:
      return handleRequestError(state, 'recommendations');
    case FETCH_DASHBOARD_RECOMMENDATIONS_SUCCESS:
      return handleRequestSuccess(state, 'recommendations', action.payload);

    case FETCH_DASHBOARD_HOT_REQUEST:
      return handleRequest(state, 'hot');
    case FETCH_DASHBOARD_HOT_ERROR:
      return handleRequestError(state, 'hot');
    case FETCH_DASHBOARD_HOT_SUCCESS:
      return handleRequestSuccess(state, 'hot', action.payload);

    case FETCH_DASHBOARD_TOP_CHANNELS_REQUEST:
      return handleRequest(state, 'topChannels');
    case FETCH_DASHBOARD_TOP_CHANNELS_ERROR:
      return handleRequestError(state, 'topChannels');
    case FETCH_DASHBOARD_TOP_CHANNELS_SUCCESS:
      return handleRequestSuccess(state, 'topChannels', action.payload);

    case FETCH_DASHBOARD_FAVORITE_CHANNELS_REQUEST:
      return handleRequest(state, 'favoriteChannels');
    case FETCH_DASHBOARD_FAVORITE_CHANNELS_ERROR:
      return handleRequestError(state, 'favoriteChannels');
    case FETCH_DASHBOARD_FAVORITE_CHANNELS_SUCCESS:
      return handleRequestSuccess(state, 'favoriteChannels', action.payload);

    case FETCH_DASHBOARD_FAVORITE_VIDEOS_REQUEST:
      return handleRequest(state, 'favoriteVideos');
    case FETCH_DASHBOARD_FAVORITE_VIDEOS_ERROR:
      return handleRequestError(state, 'favoriteVideos');
    case FETCH_DASHBOARD_FAVORITE_VIDEOS_SUCCESS:
      return handleRequestSuccess(state, 'favoriteVideos', action.payload);

    default:
      return state;
  }
}
