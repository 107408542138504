export const styles = (theme) => ({
  modalBlock: {
    boxSizing: 'border-box',
    position: 'absolute',
    maxWidth: theme.spacing(110),
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#ffffff',
    backgroundColor: '#272628',
    '& h5': {
      color: '#ffffff',
      margin: '10px 0'
    }
  },
  closeButton: {
    display: 'inline-block',
    float: 'right',
    width: 28,
    height: 28,
    padding: 0,
    textAlign: 'center',
    borderRadius: '50%',
    lineHeight: '28px',
    color: '#cecece',
    cursor: 'pointer'
  },
  root: {
    maxWidth: '100%',
    flexGrow: 1
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: '#000',
    '& p': {
      color: '#fff'
    }
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  mobileStepper: {
    backgroundColor: '#000',
    '& button span': {
      color: '#f37037'
    }
  },
  mobileStepperProgres: {
    '& div': {
      backgroundColor: '#f37037'
    }
  },
  checkboxWrp: {
    color: '#ffffff'
  },
  checkboxBase: {
    color: '#f37037',
    '&$checkboxChecked': {
      color: '#f37037'
    }
  },
  checkboxChecked: {}
});
