import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import { useVideoStoreValue } from '../store/videoStore';

import { PlayIcon } from '../../../assets/icons';
import { useStyles } from './VideoStartButton.styles';

const VideoStartButton = ({
  className,
  ...props
}: HTMLAttributes<HTMLButtonElement>) => {
  const isHovered = useVideoStoreValue((store) => store.isHovered);
  const styles = useStyles();
  return (
    <button
      className={clsx(className, styles.button, isHovered && styles.hovered)}
      type="button"
      {...props}
    >
      <PlayIcon />
    </button>
  );
};

export default VideoStartButton;
