/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  ListItem,
  withStyles,
  IconButton,
  Grow,
  List
} from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import moment from 'moment';
import clsx from 'clsx';

import ProgramActions from '../../../../components/ProgramActions';
import TimeCard from '../../../../components/TimeCard';

import { styles } from './ProgramGuideList.styles';

function ProgramGuideList(props) {
  const { data, classes, itemHeight } = props;

  const listItems = data.map((item, i) => (
    <Grow in timeout={i * 300} key={i}>
      <ListItem
        className={clsx(
          classes.listItem,
          isCurrentlyPlaying(item.startTime, item.endTime)
            ? classes.listItemActive
            : null
        )}
      >
        <Card className={classes.card} style={{ height: itemHeight }}>
          <CardMedia
            className={classes.cover}
            image={item.thumbnail}
            title={item.title}
          />
          {item.recorded === 'true' && (
            <IconButton className={classes.playButton}>
              <PlayArrow fontSize="inherit" />
            </IconButton>
          )}
          <CardContent className={classes.cardContent}>
            <div className={classes.cardRow}>
              <div
                className={classes.timeCardContainer}
                onClick={() =>
                  futureShow(item.startTime) ? props.changeTime(item) : null
                }
              >
                <TimeCard startTime={item.startTime} endTime={item.endTime} />
              </div>
              <Typography className={classes.nowPlaying}>
                NOW PLAYING
              </Typography>
            </div>

            <Typography
              style={{
                paddingTop: 5,
                color: '#E6E7E8',
                fontFamily: 'Open Sans'
              }}
            >
              {item.title}
            </Typography>
          </CardContent>
          <div className={classes.programActions}>
            <ProgramActions
              schedule={props.schedule}
              dismiss={props.dismiss}
              handleHover={props.handleMouseOver}
              recordText={props.recordText}
              showRecordButton={props.showRecordButton}
              item={item}
              id={item.id}
            />
          </div>
        </Card>
      </ListItem>
    </Grow>
  ));

  return <List className={classes.listContainer}>{listItems}</List>;
}

function isCurrentlyPlaying(from, to) {
  const currentDate = moment().year(2019).month(8).date(1);
  return currentDate.isBetween(moment(from), moment(to));
}

function futureShow(from) {
  const currentDate = moment().year(2019).month(8).date(1);
  return currentDate.isAfter(moment(from));
}

export default withStyles(styles)(ProgramGuideList);
