import React, { HTMLAttributes, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import clsx from 'clsx';

import { getImageUrl } from '../../shared';
import { useVideoSlides } from '../../hooks';
import mergeRefs from '../../shared/mergeRefs';

import CarouselItem from './CarouselItem';
import CarouselArrow from './CarouselArrow';
import CarouselSlider from './CarouselSlider';
import { useMouseWheel } from './hooks';

import { useStyles } from './TimelineCarousel.styles';

type Props = HTMLAttributes<HTMLDivElement> & {
  videoElem: HTMLVideoElement | null;
};

const TimelineCarousel = ({ className, videoElem, ...props }: Props) => {
  const { data, activeIndex, getSlideSharedProps } = useVideoSlides(videoElem);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'center'
  });

  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);

  const wrapperRef = useMouseWheel(emblaApi, setActiveCarouselIndex);

  useEffect(() => {
    // minimum delay is needed to make scroll work on page load
    const timer = setTimeout(() => {
      if (!emblaApi || activeIndex === null) return;
      const slidesInView = emblaApi?.slidesInView() ?? [];
      const slidesLength = slidesInView.length;
      const newIndex = activeIndex - Math.floor(slidesLength / 2) + 1;
      emblaApi.scrollTo(newIndex);
      setActiveCarouselIndex(newIndex);
    });
    return () => clearTimeout(timer);
  }, [activeIndex, emblaApi]);

  const relativeScrollTo = (indexModifier: number) => {
    if (!emblaApi) return;
    const currentSlide = emblaApi.selectedScrollSnap();
    const withModifier = currentSlide + indexModifier;
    const slidesLength = data?.length ?? 0;
    const newIndex =
      withModifier < 0
        ? 0
        : withModifier > slidesLength - 1
          ? slidesLength - 1
          : withModifier;
    emblaApi?.scrollTo(newIndex);
    setActiveCarouselIndex(newIndex);
  };

  const styles = useStyles();

  // without this block slider will never reach its end (unless user drags manually)
  const slidesInView = emblaApi?.slidesInView() ?? [];
  const slidesLength = slidesInView.length;
  const carouselThreshold = Math.floor(slidesLength / 2);
  const max = data?.length ?? 0;
  const _activeCarouselIndex =
    max - carouselThreshold <= activeCarouselIndex ? max : activeCarouselIndex;

  return (
    <div className={clsx(className, styles.componentRoot)} {...props}>
      <CarouselSlider
        max={max}
        value={[_activeCarouselIndex ?? 0]}
        onValueChange={(value) => {
          emblaApi?.scrollTo(value[0]);
          setActiveCarouselIndex(value[0]);
        }}
        className={styles.slider}
      />
      <div className={styles.carouselWrapper}>
        <CarouselArrow
          className={styles.arrow}
          onClick={() => relativeScrollTo(-3)}
        />
        <div
          className={styles.carouselRoot}
          ref={mergeRefs(emblaRef, wrapperRef)}
        >
          <div className={styles.carouselContainer}>
            {data?.map((item, idx) => {
              const { isCurrent, onClick } = getSlideSharedProps(
                item,
                data[idx + 1],
                idx
              );

              return (
                <CarouselItem
                  key={idx}
                  className={styles.slide}
                  title={item.description}
                  isActive={isCurrent}
                  onClick={onClick}
                  style={{
                    backgroundImage: `url('${getImageUrl(item.thumbnail)}')`
                  }}
                />
              );
            })}
          </div>
        </div>
        <CarouselArrow
          className={styles.arrow}
          isNext
          onClick={() => relativeScrollTo(3)}
        />
      </div>
    </div>
  );
};

export default TimelineCarousel;
