import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// @ts-ignore
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './services/resizeObserverPolyfill';
import App from './components/App';
import reducer from './reducers';
import './index.css';

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

const launchOffline = async () => {
  if (process.env.REACT_APP_OFFLINE === 'TRUE') {
    const importResult = await import('./api/offline');
    importResult.worker.start({
      onUnhandledRequest: 'bypass'
    });
  } else {
    return Promise.resolve();
  }
};

launchOffline().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <HashRouter>
          <App />
        </HashRouter>
      </QueryClientProvider>
    </Provider>
  );
});
