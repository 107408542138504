import { baseUrl } from '../apiEndpoints';
const transcodingBaseUrl = `${baseUrl}:5002`;

// Transcoding
export const API_GET_ALL_PROFILES = () =>
  `${transcodingBaseUrl}/api/v1/profiles`;
export const API_CREATE_PROFILE = () => `${transcodingBaseUrl}/api/v1/profiles`;
export const API_UPDATE_PROFILE_BY_ID = (id: string) =>
  `${transcodingBaseUrl}/api/v1/profiles/${id}`;
export const API_DELETE_PROFILE_BY_ID = (id: string) =>
  `${transcodingBaseUrl}/api/v1/profiles/${id}`;

export const API_CREATE_JOB = () => `${transcodingBaseUrl}/api/v1/jobs`;
export const API_GET_JOBS = () => `${transcodingBaseUrl}/api/v1/jobs`;

export const API_GET_JOB_BY_ID = (id: string) =>
  `${transcodingBaseUrl}/api/v1/jobs/${id}`;
