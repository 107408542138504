export const tooltipADS = `
    <p style="
        color: #f37037;
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        margin: 5px;"
    >
        Advertisement
    </p>
    <p style="
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 15px;
        font-weight: 500;
        margin: 5px;"
    >
        This video was processed with an Intelligent Service for Brands logos detection.
        OTT client displays and highlights Brands logos in sync with the video they are appeared.
        Brand Logo is clickable during time it is present in video.
    </p>
`;

export const tooltipStoryBoard = `
    <p style="
        color: #f37037;
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        margin: 5px;"
    >
        StoryBoard
    </p>
    <p style="
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 15px;
        font-weight: 500;
        margin: 5px;"
    >
        This video was processed with an Intelligent Service for Face detection and recognition; and Scenes changes detection.
        OTT client provides additional component for navigation based on scenes detected.
    </h2>
`;

export const tooltipSettings = `
    <p style="
        color: #f37037;
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        margin: 5px;"
    >
        Settings
    </p>
    <p style="
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 15px;
        font-weight: 500;
        margin: 5px;"
    >
        Here you can change different video sources or formats available for this video.
        In case of adaptive HLS format will be chosen as an source - additional menu with video resolutions will be available.
    </p>
`;

export const tooltipTagCloud = `
    <p style="
        color: #f37037;
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        margin: 5px;"
    >
        TagCloud
    </p>
    <p style="
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 15px;
        font-weight: 500;
        margin: 5px;"
    >
        This video was processed with Speech-to-Text Intelligent Service for words detection and recognition.
        OTT client provides additional component to present calculated Words or Tags Cloud for this video.
    </p>
`;

export const tooltipQuality = `
    <p style="
        color: #f37037;
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        margin: 5px;"
    >
        Quality
    </p>
    <p style="
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 15px;
        font-weight: 500;
        margin: 5px;"
    >
        This component is available in case of Adaptive HLS source is chosen for video.
    </p>
`;

export const tooltipVideoLooping = `
    <p style="
        color: #f37037;
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        margin: 5px;"
    >
        Video Looping
    </p>
    <p style="
        font-family: Open Sans; 
        text-align: justify; 
        line-height: 20px;
        font-size: 15px;
        font-weight: 500;
        margin: 5px;"
    >
        This component manages video looping - useful for big screen demo scenarios.
    </p>
`;

export const tooltipTags = {
  live: `
            <p style="
                font-family: Open Sans; 
                text-align: justify; 
                line-height: 20px;
                font-size: 15px;
                font-weight: 600;
                margin: 5px;"
            >
                This video broadcasts <span style="color: #f37037;">Live Stream</span>
            </p>
        `,
  sb: `
            <p style="
                font-family: Open Sans; 
                text-align: justify; 
                line-height: 20px;
                font-size: 15px;
                font-weight: 600;
                margin: 5px;"
            >
                This video provides extended navigation with <span style="color: #f37037;">StoryBoard</span> component. See right of the player
            </p>
        `,
  cc: `
            <p style="
                font-family: Open Sans; 
                text-align: justify; 
                line-height: 20px;
                font-size: 15px;
                font-weight: 600;
                margin: 5px;"
            >
                This video has <span style="color: #f37037;">Closed Captioning</span> autogenerated based on audio dialogs
            </p>
        `,
  search: `
            <p style="
                font-family: Open Sans; 
                text-align: justify; 
                line-height: 20px;
                font-size: 15px;
                font-weight: 600;
                margin: 5px;"
            >
                <span style="color: #f37037;">Dialogs</span> from this video available for the <span style="color: #f37037;">Search</span>
            </p>
        `,
  tags: `
            <p style="
                font-family: Open Sans; 
                text-align: justify; 
                line-height: 20px;
                font-size: 15px;
                font-weight: 600;
                margin: 5px;"
            >
                This video provides additional <span style="color: #f37037;">Tag Cloud</span> component. See up-right <span style="color: #f37037;">&quot;SHOW TAGS&quot;</span> button
            </p>
        `,
  ads: `
            <p style="
                font-family: Open Sans; 
                text-align: justify; 
                line-height: 20px;
                font-size: 15px;
                font-weight: 600;
                margin: 5px;"
            >
                This video provides <span style="color: #f37037;">Online Advertisement</span> component. See left of the player
            </p>
        `
};
