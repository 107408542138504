const avatarSize = 60;
const avatarIndent = 8;

export const styles = () => ({
  link: {
    '&:hover': { textDecoration: 'none' }
  },
  avatarCard: {
    display: 'flex',
    fontFamily: 'Open Sans',
    '&:hover $subTitle:before': {
      content: '""',
      display: 'block',
      height: 1,
      width: '100%',
      backgroundColor: '#fff',
      position: 'absolute',
      top: 2.5
    }
  },
  avatar: {
    width: avatarSize,
    height: avatarSize,
    borderRadius: avatarSize / 2,
    gridRowEnd: 'span 2',
    boxSizing: 'border-box',
    marginRight: avatarIndent
  },
  avatarWithBorder: {
    border: '2px solid #F37037'
  },
  rightColumn: {
    width: `calc(100% - ${avatarSize + avatarIndent}px)`
  },
  elipsisParent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    color: '#fff',
    fontSize: 18,
    padding: '6px 5px 0 0',
    boxSizing: 'border-box',
    display: 'block'
  },
  subTitle: {
    position: 'relative',
    color: '#E6E7E8',
    fontSize: 13,
    fontWeight: 300,
    paddingTop: 5,
    display: 'block',
    gridColumnEnd: 'span 2'
  },
  imdbIcon: {
    alignSelf: 'center',
    paddingTop: 9
  }
});
