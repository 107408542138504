import {
  FETCH_TUTORIAL_REQUEST,
  FETCH_TUTORIAL_ERROR,
  FETCH_TUTORIAL_SUCCESS
} from '../constants/actionTypes';

export const initialState = {
  isLoading: false,
  hasError: false,
  error: '',
  data: []
};

export function tutorial(state = initialState, action) {
  switch (action.type) {
    case FETCH_TUTORIAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        data: [],
        error: ''
      };

    case FETCH_TUTORIAL_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload
      };

    case FETCH_TUTORIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };

    default:
      return state;
  }
}
