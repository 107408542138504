const root = {
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: 1,
  position: 'relative'
};

export const styles = (theme) => ({
  root: {
    ...root,
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  rootReverse: {
    ...root,
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeOut,
      duration: '210ms'
    })
  },
  videosLayout: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '25px',
    paddingTop: 5,
    boxSizing: 'border-box',
    minHeight: 200,
    flexGrow: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  videoWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '&:first-child': {
      justifySelf: 'end'
    }
  },
  selectMsg: {
    color: 'gray',
    fontSize: 23,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    border: '1px dashed gray',
    margin: 20,
    padding: '25% 0',

    '@media (max-width: 1366px)': {
      fontSize: 16
    }
  },
  thumbsContainer: {
    width: 'calc(100% - 120px)',
    marginBottom: -5,
    position: 'relative'
  },
  matchesTitle: {
    color: '#F37037',
    height: 42,
    padding: '0 35px',
    margin: 0,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    backgroundColor: '#1F1F1F',
    width: '100%',

    border: 0,
    outline: 'none',
    textAlign: 'left',
    cursor: 'pointer',

    position: 'absolute',
    bottom: 0,
    transform: 'translateY(100%)',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '@media (max-width: 1366px)': {
      fontSize: 12,
      height: 32
    }
  },
  drawerPaper: {
    width: '100%',
    height: 145,
    backgroundColor: '#181818',
    padding: 20,

    '@media (max-width: 1366px)': {
      height: 110,
      padding: '15px 0'
    }
  },
  arrowIcon: {
    padding: '3px 2px 1px 2px',
    borderRadius: 3,
    transition: 'background-color 300ms',
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.1)'
    }
  }
});
