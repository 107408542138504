export const styles = () => ({
  actionButton: {
    padding: '10px 12px',
    color: '#D8D8D8',
    '&:hover': {
      color: '#fff'
    }
  },
  actionLoader: {
    margin: '0 2px'
  },
  progress: {
    margin: '10px',
    animationDuration: '2000ms'
  }
});
