/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import AdminRoutes from '../../routes/AdminRoutes';
import { styles } from './AdministrationPage.styles';

class AdministrationPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AdminRoutes />
      </div>
    );
  }
}

export default withStyles(styles)(AdministrationPage);
