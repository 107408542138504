import { useVideoStoreDispatch } from '../../store/videoStore';

export const useToggleCC = (videoElement: HTMLVideoElement | null) => {
  const updateVideoStore = useVideoStoreDispatch();

  const toggleCC = () => {
    updateVideoStore((store) => {
      const newIsCCshown = !store.isCCshown;

      if (videoElement) {
        const tracks = videoElement.textTracks;
        if (tracks?.[0]) {
          tracks[0].mode = newIsCCshown ? 'showing' : 'disabled';
        }
      }

      return {
        ...store,
        isCCshown: newIsCCshown
      };
    });
  };

  return toggleCC;
};
