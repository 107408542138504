import React, { ReactNode, useEffect, useRef } from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { useShallow } from 'zustand/react/shallow';

import { useSearchStore } from '../../stores';
import { useResizeObserver } from '../../hooks';

import ChatControls from './ChatControls';

import { useStyles } from './Drawer.styles';

type Props = {
  children: ReactNode;
};

const Drawer = ({ children }: Props) => {
  const { isChatOpen, chatMessages } = useSearchStore(
    useShallow((state) => ({
      isChatOpen: state.isChatOpen,
      chatMessages: state.chatMessages
    }))
  );

  const [chatWrapperRef, { height: availableHeight }] =
    useResizeObserver<HTMLDivElement>(200);
  const [controlsWrapperRef, { height: controlsHeight }] =
    useResizeObserver<HTMLDivElement>(200);

  const styles = useStyles();

  const chatContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [chatMessages]);

  return (
    <MuiDrawer
      className={styles.drawer}
      variant="persistent"
      anchor="right"
      open={isChatOpen}
      classes={{
        paper: styles.drawerPaper
      }}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.chatWrapper} ref={chatWrapperRef}>
          <div
            className={styles.messagesContainer}
            style={{
              height: availableHeight - controlsHeight - 29
            }}
          >
            <div
              className={styles.scrollable}
              ref={chatContainerRef}
              tabIndex={1}
            >
              {children}
            </div>
          </div>
          <div className={styles.controls} ref={controlsWrapperRef}>
            <ChatControls />
          </div>
        </div>
      </div>
    </MuiDrawer>
  );
};

export default Drawer;
