import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  slide: {
    width: '100%',
    height: 90,
    minHeight: 90,
    backgroundColor: '#1f1f1f',
    borderRadius: 4,
    listStyle: 'none',
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none',

    '&:focus-visible': {
      outline: `1px solid ${theme.palette.secondary.main}`,
      outlineOffset: -1
    }
  }
}));
