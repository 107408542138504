import {
  FETCH_PROGRAM_GUIDE_REQUEST,
  FETCH_PROGRAM_GUIDE_ERROR,
  FETCH_PROGRAM_GUIDE_SUCCESS
} from '../constants/actionTypes';

export function programGuideIsLoading() {
  return {
    type: FETCH_PROGRAM_GUIDE_REQUEST
  };
}

export function programGuideFetchError() {
  return {
    type: FETCH_PROGRAM_GUIDE_ERROR
  };
}

export function programGuideFetchSuccess(response) {
  return {
    type: FETCH_PROGRAM_GUIDE_SUCCESS,
    payload: response
  };
}

export function programGuideFetchData(channelId) {
  return (dispatch) => {
    dispatch(programGuideIsLoading());

    const baseUrl = process.env.API_PATH || 'https://accelerator.media';
    const API_GET_SINGLE_EPG = (id) => `${baseUrl}/api/epg/${id}`;

    fetch(API_GET_SINGLE_EPG(channelId))
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json())
      .then((response) => dispatch(programGuideFetchSuccess(response.events)))
      .catch((error) => {
        const message = error.message || error;
        console.error(`ERROR in programGuideFetch: ${message}`);

        return dispatch(programGuideFetchError());
      });
  };
}
