/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Input,
  Button,
  CircularProgress
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

const styles = (theme) => ({
  modal: {
    fontFamily: 'Open Sans',
    backgroundColor: '#1D1E21'
  },
  modalTitle: {
    backgroundColor: '#141518',
    padding: '15px 0 15px 20px',
    textTransform: 'uppercase',
    height: '50px',
    boxSizing: 'border-box',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalTitleText: {
    color: '#F37037',
    fontSize: '14px'
  },
  modalContent: {
    padding: '24px 24px 8px 24px',
    color: 'white',
    lineHeight: '24px',
    fontSize: '14px'
  },
  modalCloseButton: {
    color: '#D8D8D8',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'white'
    }
  },
  modalActions: {
    margin: '8px 24px'
  },
  actionBtn: {
    margin: 0
  },
  editor: {
    resize: 'vertical',
    fontSize: '14px',
    color: theme.palette.primary.contrastText
  },
  formatBtn: {
    marginRight: 'auto'
  },
  cancelBtn: {
    marginRight: '8px'
  }
});

const formatJson = (data) => JSON.stringify(JSON.parse(data), null, '\t');
const isValidJson = (data) => {
  let isValid = true;
  try {
    JSON.parse(data);
  } catch (e) {
    isValid = false;
  }
  return isValid;
};

const JsonEditorModal = ({ classes, title, json, onClose, onSave, saving }) => {
  const [editableJson, setEditableJson] = useState(formatJson(json));

  const onFormatClick = () => {
    setEditableJson(formatJson(editableJson));
  };

  return (
    <Dialog
      open
      onClose={onClose}
      classes={{ paper: classes.modal }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle classes={{ root: classes.modalTitle }} disableTypography>
        <Typography className={classes.modalTitleText} variant="h6">
          {title}
        </Typography>
        <IconButton
          aria-label="Close"
          onClick={onClose}
          className={classes.modalCloseButton}
        >
          <Close fontSize="26px" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Input
          onChange={(e) => setEditableJson(e.currentTarget.value)}
          value={editableJson}
          classes={{
            input: classes.editor
          }}
          rows={20}
          error={!isValidJson(editableJson)}
          fullWidth
          multiline
        />
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        <Button
          variant="contained"
          color="primary"
          className={`${classes.formatBtn} ${classes.actionBtn}`}
          disabled={!isValidJson(editableJson)}
          onClick={onFormatClick}
        >
          Format
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`${classes.actionBtn} ${classes.cancelBtn}`}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={`${classes.actionBtn} ${!isValidJson(editableJson) ? classes.btnDisabled : ''}`}
          onClick={() => onSave(JSON.parse(editableJson))}
          disabled={!isValidJson(editableJson) || saving}
        >
          {saving ? <CircularProgress size={22} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(JsonEditorModal);
