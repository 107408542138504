export const styles = (theme) => ({
  cardWrapper: {
    position: 'relative',
    minHeight: '260px'
  },
  cardWithSpeechWrapper: {
    position: 'relative',
    minHeight: '346px'
  },
  card: {
    maxWidth: 320,
    backgroundColor: 'rgba(255,255,255,0.12)',
    borderRadius: 0,
    margin: 'auto',
    '&:hover': {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 10,
      backgroundColor: '#333333'
    },
    '&:hover $speech': {
      height: 'auto',
      display: 'block'
    },
    '&:hover $imageOnHover': {
      display: 'block'
    }
  },
  cardRoot: {
    '&:hover $cardFocusHighlight': {
      opacity: 0
    },
    '&:hover $image': {
      boxShadow: `inset 0 4px 0 ${theme.palette.secondary.main}`
    },
    '&:hover $imageOnHover': {
      boxShadow: `inset 0 4px 0 ${theme.palette.secondary.main}`
    }
  },
  cardFocusHighlight: {
    backgroundColor: 'white'
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit'
  },
  image: {
    height: 180,
    position: 'relative',
    backgroundSize: '135%'
  },
  imageOnHover: {
    height: 180,
    position: 'relative',
    display: 'none'
  },
  time: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    margin: '10px',
    width: '80px',
    height: '26px',
    backgroundColor: '#3770F3',
    color: theme.palette.primary.contrastText,
    opacity: 0.9,
    cursor: 'pointer'
  },
  flag: {
    height: '26px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.5)',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    borderRadius: 0,
    marginLeft: '5px',
    marginTop: '5px',
    float: 'right',
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px'
  },
  flagsWrapper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: '10px',
    cursor: 'pointer'
  },
  content: {
    padding: '15px'
  },
  title: {
    whiteSpace: 'normal',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '18px',
    lineHeight: '24px',
    height: '50px',
    overflow: 'hidden',
    display: 'block',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  speech: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '18px',
    lineHeight: '19px',
    fontWeight: 300,
    minHeight: '76px',
    height: '76px',
    paddingTop: '10px',
    overflow: 'hidden',
    textAlign: 'justify',
    display: 'block',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical'
  },
  // commented by request of Alex Pogrebnoy
  // highlightText: {
  //   color: theme.palette.secondary.main,
  //   backgroundColor: 'transparent',
  //   fontWeight: 600
  // },
  popper: {
    opacity: 1
  },
  tooltip: {
    borderRadius: 0,
    transform: 'none!important'
  },
  actions: {
    padding: '0 8px',
    marginTop: '-15px',
    justifyContent: 'flex-end'
  }
});
