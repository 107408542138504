import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  slides: {
    overflowX: 'auto',
    display: 'flex',
    gap: 5,
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    height: '100%',

    '&::-webkit-scrollbar': {
      width: 4,
      backgroundColor: 'rgba(201, 201, 201, 0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 10
    }
  }
}));
