import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    all: 'unset',
    width: 88,
    height: 43,
    backgroundColor: '#2b333fb3',
    border: '.06666em solid #fff',
    borderRadius: 9,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    '& svg': {
      width: 20
    },

    '&:focus-visible': {
      backgroundColor: '#73859f80'
    }
  },
  hovered: {
    backgroundColor: '#73859f80'
  }
}));
