/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextareaAutosize,
  FormHelperText
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { styles } from '../../../components/ProgramDetailsDialog/ProgramDetailsDialog.styles';
import { Close as CloseIcon } from '@material-ui/icons';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  segmentDuration: Yup.string().required('Required'),
  hlsParameters: Yup.string().test(
    'hlsParameters',
    'Required',
    function (value) {
      const { type } = this.parent;
      if (type === 'hls') return !!value;
      return true;
    }
  ),
  videoParameters: Yup.string().test(
    'videoParameters',
    'Required',
    function (value) {
      const { type } = this.parent;
      if (type === 'transcode') return !!value;
      return true;
    }
  )
});

const ProfileModal = ({
  selectedItem,
  handleClose,
  onSave,
  classes: {
    modalLight,
    modalTitle,
    modalTitleText,
    modalCloseButton,
    modalCloseIcon,
    modalContent
  }
}) => {
  const isEdit = Object.keys(selectedItem || {}).length > 0;
  const item = useMemo(() => {
    const _ = {
      name: selectedItem.name,
      type: selectedItem.type,
      segmentDuration: selectedItem.segmentDuration
    };
    if (_.type === 'transcode') {
      _.videoParameters = (selectedItem.videoParameters || []).join(' ');
      _.audioParameters = (selectedItem.audioParameters || []).join(' ');
    }
    if (_.type === 'hls') {
      _.hlsParameters = selectedItem.hlsParameters.join(' ');
    }
    return _;
  }, [selectedItem]);

  const formik = useFormik({
    initialValues: item,
    validationSchema: schema,
    onSubmit: null
  });

  const handleSave = (values) => {
    const _ = {
      name: values.name,
      type: values.type,
      segmentDuration: values.segmentDuration
    };
    if (_.type === 'transcode') {
      _.videoParameters = values.videoParameters.split(' ');
      _.audioParameters = values.audioParameters.split(' ');
    }
    if (_.type === 'hls') {
      _.hlsParameters = values.hlsParameters.split(' ');
    }
    if (selectedItem._id) {
      onSave(selectedItem._id, _);
    } else {
      onSave(_);
    }
  };

  return (
    <Dialog
      open={!!selectedItem}
      onClose={handleClose}
      classes={{ paper: modalLight }}
    >
      <DialogTitle classes={{ root: modalTitle }}>
        <Typography className={modalTitleText}>
          {isEdit ? 'Edit Profile' : 'Add new Profile'}
        </Typography>
        <IconButton
          disableRipple
          onClick={handleClose}
          className={modalCloseButton}
        >
          <CloseIcon className={modalCloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: modalContent }}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
        >
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.errors.name}
          />
          <FormControl fullWidth>
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.errors.type}
            >
              <MenuItem value={'transcode'}>transcode</MenuItem>
              <MenuItem value={'hls'}>hls</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            id="segmentDuration"
            name="segmentDuration"
            label="Segment duration"
            value={formik.values.segmentDuration}
            onChange={formik.handleChange}
            error={
              formik.touched.segmentDuration &&
              Boolean(formik.errors.segmentDuration)
            }
            helperText={formik.errors.segmentDuration}
          />
          {formik.values.type === 'transcode' && (
            <>
              <TextField
                fullWidth
                id="videoParameters"
                name="videoParameters"
                label="videoParameters"
                value={formik.values.videoParameters}
                onChange={formik.handleChange}
                error={
                  formik.touched.videoParameters &&
                  Boolean(formik.errors.videoParameters)
                }
                helperText={formik.errors.videoParameters}
              />
              <TextField
                fullWidth
                id="audioParameters"
                name="audioParameters"
                label="audioParameters"
                value={formik.values.audioParameters}
                onChange={formik.handleChange}
                error={
                  formik.touched.audioParameters &&
                  Boolean(formik.errors.audioParameters)
                }
                helperText={formik.errors.audioParameters}
              />
            </>
          )}
          {formik.values.type === 'hls' && (
            <>
              <InputLabel
                htmlFor="hlsParameters"
                error={
                  formik.touched.hlsParameters &&
                  Boolean(formik.errors.hlsParameters)
                }
              >
                hlsParameters
              </InputLabel>
              <TextareaAutosize
                id="hlsParameters"
                fullWidth
                name="hlsParameters"
                label="hlsParameters"
                value={formik.values.hlsParameters}
                onChange={formik.handleChange}
              />
              <FormHelperText id="hlsParameters-error-text">
                {formik.errors.hlsParameters}
              </FormHelperText>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSave(formik.values)}
          disabled={!formik.dirty || !formik.isValid}
        >
          Save
        </Button>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ProfileModal);
