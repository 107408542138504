import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_ERROR,
  FETCH_GROUPS_SUCCESS,
  CLEAR_GROUPS,
  FETCH_GROUP_CONTENT_REQUEST,
  FETCH_GROUP_CONTENT_ERROR,
  FETCH_GROUP_CONTENT_SUCCESS
} from '../constants/actionTypes';

export const initialState = {
  areLoading: false,
  haveError: false,
  error: '',
  data: []
};

export function groups(state = initialState, action) {
  switch (action.type) {
    case CLEAR_GROUPS:
      return {
        ...state,
        data: []
      };

    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        areLoading: true,
        haveError: false,
        data: [],
        error: ''
      };

    case FETCH_GROUPS_ERROR:
      return {
        ...state,
        areLoading: false,
        haveError: true,
        error: action.payload
      };

    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        areLoading: false,
        data: action.payload
      };

    case FETCH_GROUP_CONTENT_REQUEST: {
      const index = state.data.findIndex(
        (group) => group.id === action.payload.id
      );

      if (index < 0) return state;

      const groupsList = [...state.data];
      groupsList[index] = {
        ...groupsList[index],
        isLoading: true,
        hasError: false,
        items: [],
        error: ''
      };

      return {
        ...state,
        data: groupsList
      };
    }

    case FETCH_GROUP_CONTENT_ERROR: {
      const index = state.data.findIndex(
        (group) => group.id === action.payload.id
      );

      if (index < 0) return state;

      const groupsList = [...state.data];
      groupsList[index] = {
        ...groupsList[index],
        isLoading: false,
        hasError: true,
        items: [],
        error: action.payload.error
      };

      return {
        ...state,
        data: groupsList
      };
    }

    case FETCH_GROUP_CONTENT_SUCCESS: {
      const index = state.data.findIndex(
        (group) => group.id === action.payload.id
      );

      if (index < 0) return state;

      const groupsList = [...state.data];
      groupsList[index] = {
        ...groupsList[index],
        isLoading: false,
        hasError: false,
        items: action.payload.items,
        error: ''
      };

      return {
        ...state,
        data: groupsList
      };
    }

    default:
      return state;
  }
}
