import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { searchVideos } from './searchApi';
import { SearchVideo } from './searchTypes';

export const useSearchVideos = (
  searchString: string,
  options: UseQueryOptions<SearchVideo[]> = {}
) =>
  useQuery<SearchVideo[], unknown>({
    queryKey: ['search', searchString],
    queryFn: () => searchVideos(searchString),
    ...options
  });
