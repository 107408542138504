import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useMutation, useQuery } from '@tanstack/react-query';

import { styles } from '../TranscodingPage.styles';
import { Delete, Edit } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import ProfileModal from './ProfileModal';
import Modal from '../Modal';
import { TranscodingAPI } from '../../../api/transcoding';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const ProfilesTab = () => {
  const classes = useStyles();
  const [action, setAction] = useState({});
  const { data = {}, refetch } = useQuery({
    queryKey: 'profiles',
    queryFn: () => TranscodingAPI.getProfiles(),
    refetchOnWindowFocus: false,
    staleTime: 60000
  });

  const { mutateAsync: addNewProfile } = useMutation({
    mutationFn: (profile) => TranscodingAPI.createProfile(profile)
  });
  const { mutateAsync: editProfile } = useMutation({
    mutationFn: ({ id, profile }) =>
      TranscodingAPI.updateProfileById(id, profile)
  });
  const { mutateAsync: deleteProfile } = useMutation({
    mutationFn: (id) => TranscodingAPI.deleteProfileById(id)
  });
  const rows = data.profiles || [];
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 235px)' }}
      >
        <Table className={classes.table} stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>segmentDuration</TableCell>
              <TableCell>Video Parameters</TableCell>
              <TableCell>Audio Parameters</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.segmentDuration}</TableCell>
                {row.type === 'hls' && (
                  <TableCell>{(row.hlsParameters || []).join(' ')}</TableCell>
                )}
                {row.type === 'transcode' && (
                  <TableCell>{(row.videoParameters || []).join(' ')}</TableCell>
                )}
                <TableCell>{(row.audioParameters || []).join(' ')}</TableCell>
                <TableCell>
                  <IconButton
                    className={classes.notifyButton}
                    classes={{ root: classes.iconButton }}
                    style={{ padding: 0 }}
                    onClick={() => setAction({ type: 'edit', data: row })}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    className={classes.notifyButton}
                    classes={{ root: classes.iconButton }}
                    style={{ padding: 0 }}
                    onClick={() => setAction({ type: 'delete', data: row })}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAction({ type: 'add', data: {} })}
        >
          Add profile
        </Button>
      </div>
      {action.type === 'add' && (
        <ProfileModal
          selectedItem={action.data}
          onSave={(newProfile) => {
            addNewProfile(newProfile)
              .then(() => refetch())
              .finally(() => setAction({}));
          }}
          handleClose={() => setAction({})}
        />
      )}
      {action.type === 'edit' && (
        <ProfileModal
          selectedItem={action.data}
          onSave={(id, profile) => {
            editProfile({ id, profile })
              .then(() => refetch())
              .finally(() => setAction({}));
          }}
          handleClose={() => setAction({})}
        />
      )}
      {action.type === 'delete' && (
        <Modal
          title="Delete confirmation"
          handleConfirm={() => {
            deleteProfile(action.data._id)
              .then(() => refetch())
              .finally(() => setAction({}));
          }}
          handleCancel={() => setAction({})}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ProfilesTab);
