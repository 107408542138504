import React, { memo, useEffect, useRef, useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import moment from 'moment';
import { Typography, Card, CardMedia, CardContent } from '@material-ui/core';

import Tooltip from '../../Tooltip';
import { formatDate } from '../../../shared/utils';
import { useStyles } from './ShowTile.styles';

const propTypes = {
  show: shape({
    startTime: string,
    endTime: string,
    title: string,
    programId: string,
    thumbnail: string
  }).isRequired,
  channelId: string.isRequired,
  getShift: func.isRequired,
  onTileClick: func,
  pixelsPerMs: number
};

const defaultProps = {
  onTileClick: () => {},
  pixelsPerMs: 1
};

const getWidth = (start, end, pixelsPerMs) =>
  (moment(end).valueOf() - moment(start).valueOf()) * pixelsPerMs;

const ShowTile = ({ show, channelId, getShift, onTileClick, pixelsPerMs }) => {
  const classes = useStyles();
  const [isEllipsisActive, setEllipsisActive] = useState(false);

  const cardWidth = getWidth(show.startTime, show.endTime, pixelsPerMs);
  const displayCover = cardWidth < 172 ? 'none' : 'initital';

  const titleRef = useRef();
  const timeRef = useRef();

  useEffect(() => {
    const titleDOM = titleRef.current;
    const timeDOM = timeRef.current;

    if (titleDOM && timeDOM) {
      setEllipsisActive(
        titleDOM.offsetWidth < titleDOM.scrollWidth ||
          timeDOM.offsetWidth < timeDOM.scrollWidth
      );
    }
  }, []);

  const tooltip = (
    <div style={{ width: 200 }}>
      <div
        className={classes.tooltipImage}
        style={{ backgroundImage: `url('${show.thumbnail}')` }}
      />
      <Typography className={classes.tooltipTitle}>{show.title}</Typography>
      <Typography className={classes.tooltipTime}>
        {formatDate(show.startTime, show.endTime, false)}
      </Typography>
    </div>
  );

  return (
    <div
      className={classes.showCardWrapper}
      style={{ left: getShift(show.startTime) }}
    >
      <Tooltip title={isEllipsisActive ? tooltip : ''} shadow>
        <Card
          className={classes.showCard}
          style={{ width: cardWidth }}
          onClick={() => onTileClick(show.programId, show, channelId)}
        >
          {show.thumbnail && (
            <CardMedia
              style={{ display: displayCover }}
              className={classes.showCover}
              image={show.thumbnail}
              title=""
            />
          )}
          <CardContent className={classes.showCardContent}>
            <Typography className={classes.title} ref={titleRef}>
              {show.title}
            </Typography>
            <Typography className={classes.time} ref={timeRef}>
              {formatDate(show.startTime, show.endTime, false)}
            </Typography>
          </CardContent>
        </Card>
      </Tooltip>
    </div>
  );
};

ShowTile.propTypes = propTypes;
ShowTile.defaultProps = defaultProps;

export default memo(ShowTile);
