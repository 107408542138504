import {
  FETCH_RECORDINGS_REQUEST,
  FETCH_RECORDINGS_ERROR,
  FETCH_RECORDINGS_SUCCESS
} from '../constants/actionTypes';

import { API_GET_RECORDINGS, API_GET_RECORDINGS_DB } from '../api/apiEndpoints';

const recordingsAreLoading = () => ({ type: FETCH_RECORDINGS_REQUEST });
const recordingsFetchError = () => ({ type: FETCH_RECORDINGS_ERROR });
const recordingsFetchSuccess = (payload) => ({
  type: FETCH_RECORDINGS_SUCCESS,
  payload
});

export const recordingsFetchData = () => (dispatch) => {
  dispatch(recordingsAreLoading());

  fetch(API_GET_RECORDINGS())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(recordingsFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in recordingsFetchData: ${message}`);
      return dispatch(recordingsFetchError(message));
    });
};

export const recordingsFetchDataDB = () => (dispatch) => {
  fetch(API_GET_RECORDINGS_DB())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(recordingsFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in recordingsFetchData: ${message}`);
      return dispatch(recordingsFetchError(message));
    });
};
