import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  item: {
    height: 101,
    aspectRatio: '16 / 9',
    border: '1px solid rgba(255, 255, 255, 0.3)'
  },
  active: {
    scale: 1.2
  }
}));
