export const styles = (theme) => ({
  playerWrp: {
    padding: '0 10px'
  },
  controls: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginTop: 28
    }
  },
  videoWrapResponse: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '75%'
    }
  },
  videoDetailsContainer: {
    margin: '23px 20px 20px 0'
  },
  programGuideContainer: {
    overflowX: 'auto',

    '&&&': {
      translate: '0 0'
    },

    '&::-webkit-scrollbar': {
      width: 4,
      backgroundColor: 'rgba(201, 201, 201, 0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 10
    }
  }
});
