import React from 'react';

import { useStyles } from './GearIcon.styles';
import { GearIcon as GearIconSvg } from './assets/icons';

const GearIcon = () => {
  const styles = useStyles();
  return <GearIconSvg className={styles.gearIcon} />;
};

export default GearIcon;
