/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import {
  withStyles,
  MuiThemeProvider,
  Typography,
  InputAdornment,
  IconButton
} from '@material-ui/core';

import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/ErrorMessage';
import { programGuideFetchData } from '../../../actions/programGuide.action';
import { CalendarIcon } from '../../../assets/icons';
import {
  programDeleteRecording,
  programPostRecording
} from '../../../actions/videoDetails.action';
import { recordingsFetchDataDB } from '../../../actions/recordings.action';

import ProgramGuideList from './ProgramGuideList';

import { styles, customTheme } from './ProgramGuide.styles';

class ProgramGuide extends Component {
  state = {
    scheduled: false,
    selectedDate: null,
    recordHover: false
  };

  componentDidMount() {
    this.props.fetchProgramGuide(this.props.id);
    this.props.recordingsFetchDataDB();
  }

  updateSelectedDate = (selectedDate) =>
    this.setState({ selectedDate: selectedDate.valueOf() });

  dismissRecording = (currentShow) => {
    this.setState({ scheduled: false });
    this.props.deleteRecording(currentShow.id);
  };

  handleMouseHover = () =>
    this.setState({ recordHover: !this.state.recordHover });

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={customTheme}>
        <div className={classes.headerWrapper}>
          {this.props.header || (
            <div className={classes.headerContainer}>
              <Typography className={classes.headerTitle}>EPG</Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  adornmentPosition="start"
                  disableOpenOnEnter
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <CalendarIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  value={this.state.selectedDate}
                  onChange={this.updateSelectedDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
        </div>
        {this.props.isLoading && (
          <Loader loaderProps={{ size: 80 }} fullHeightCentralized />
        )}
        {this.props.isFailed && <ErrorMessage fullHeightCentralized />}
        {this.props.data && (
          <ProgramGuideList
            schedule={this.props.schedule}
            dismiss={this.dismissRecording}
            recordings={this.props.recordings}
            handleMouseOver={this.handleMouseHover}
            setShow={this.props.setShow}
            recordText={this.state.recordHover}
            data={this.props.data}
            showRecordButton={this.state.scheduled}
            changeTime={this.props.changeTime}
            recorded={this.props.recorded}
          />
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.programGuide.isLoading,
  isFailed: state.programGuide.isFailed,
  data: state.programGuide.data,
  recordings: state.recordings.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgramGuide: (programType, date) =>
    dispatch(programGuideFetchData(programType, date)),
  postRecording: (body) => dispatch(programPostRecording(body)),
  deleteRecording: (id) => dispatch(programDeleteRecording(id)),
  recordingsFetchDataDB: () => dispatch(recordingsFetchDataDB())
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ProgramGuide)
);
