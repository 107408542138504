import {
  FETCH_DASHBOARD_RECOMMENDATIONS_REQUEST,
  FETCH_DASHBOARD_RECOMMENDATIONS_ERROR,
  FETCH_DASHBOARD_RECOMMENDATIONS_SUCCESS,
  FETCH_DASHBOARD_HOT_REQUEST,
  FETCH_DASHBOARD_HOT_ERROR,
  FETCH_DASHBOARD_HOT_SUCCESS,
  FETCH_DASHBOARD_TOP_CHANNELS_REQUEST,
  FETCH_DASHBOARD_TOP_CHANNELS_ERROR,
  FETCH_DASHBOARD_TOP_CHANNELS_SUCCESS,
  FETCH_DASHBOARD_FAVORITE_CHANNELS_REQUEST,
  FETCH_DASHBOARD_FAVORITE_CHANNELS_ERROR,
  FETCH_DASHBOARD_FAVORITE_CHANNELS_SUCCESS,
  FETCH_DASHBOARD_FAVORITE_VIDEOS_REQUEST,
  FETCH_DASHBOARD_FAVORITE_VIDEOS_ERROR,
  FETCH_DASHBOARD_FAVORITE_VIDEOS_SUCCESS
} from '../constants/actionTypes';

import {
  API_GET_RECOMMENDATIONS,
  API_GET_HOT,
  API_GET_TOP_CHANNELS,
  API_GET_FAVORITE_CHANNELS,
  API_GET_FAVORITE_VIDEOS
} from '../api/apiEndpoints';

const recommendationsAreLoading = () => ({
  type: FETCH_DASHBOARD_RECOMMENDATIONS_REQUEST
});
const recommendationsFetchError = () => ({
  type: FETCH_DASHBOARD_RECOMMENDATIONS_ERROR
});
const recommendationsFetchSuccess = (payload) => ({
  type: FETCH_DASHBOARD_RECOMMENDATIONS_SUCCESS,
  payload
});

const hotAreLoading = () => ({ type: FETCH_DASHBOARD_HOT_REQUEST });
const hotFetchError = () => ({ type: FETCH_DASHBOARD_HOT_ERROR });
const hotFetchSuccess = (payload) => ({
  type: FETCH_DASHBOARD_HOT_SUCCESS,
  payload
});

const topChannelsAreLoading = () => ({
  type: FETCH_DASHBOARD_TOP_CHANNELS_REQUEST
});
const topChannelsFetchError = () => ({
  type: FETCH_DASHBOARD_TOP_CHANNELS_ERROR
});
const topChannelsFetchSuccess = (payload) => ({
  type: FETCH_DASHBOARD_TOP_CHANNELS_SUCCESS,
  payload
});

const favoriteChannelsAreLoading = () => ({
  type: FETCH_DASHBOARD_FAVORITE_CHANNELS_REQUEST
});
const favoriteChannelsFetchError = () => ({
  type: FETCH_DASHBOARD_FAVORITE_CHANNELS_ERROR
});
const favoriteChannelsFetchSuccess = (payload) => ({
  type: FETCH_DASHBOARD_FAVORITE_CHANNELS_SUCCESS,
  payload
});

const favoriteVideosAreLoading = () => ({
  type: FETCH_DASHBOARD_FAVORITE_VIDEOS_REQUEST
});
const favoriteVideosFetchError = () => ({
  type: FETCH_DASHBOARD_FAVORITE_VIDEOS_ERROR
});
const favoriteVideosFetchSuccess = (payload) => ({
  type: FETCH_DASHBOARD_FAVORITE_VIDEOS_SUCCESS,
  payload
});

export const recommendationsFetchData = () => (dispatch) => {
  dispatch(recommendationsAreLoading());

  fetch(API_GET_RECOMMENDATIONS())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(recommendationsFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in recommendationsFetchData: ${message}`);
      return dispatch(recommendationsFetchError(message));
    });
};

export const hotFetchData = () => (dispatch) => {
  dispatch(hotAreLoading());

  fetch(API_GET_HOT())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(hotFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in hotFetchData: ${message}`);
      return dispatch(hotFetchError(message));
    });
};

export const topChannelsFetchData = () => (dispatch) => {
  dispatch(topChannelsAreLoading());

  fetch(API_GET_TOP_CHANNELS())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(topChannelsFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in topChannelsFetchData: ${message}`);
      return dispatch(topChannelsFetchError(message));
    });
};

export const favoriteChannelsFetchData = () => (dispatch) => {
  dispatch(favoriteChannelsAreLoading());

  fetch(API_GET_FAVORITE_CHANNELS())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(favoriteChannelsFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in favoriteChannelsFetchData: ${message}`);
      return dispatch(favoriteChannelsFetchError(message));
    });
};

export const favoriteVideosFetchData = () => (dispatch) => {
  dispatch(favoriteVideosAreLoading());

  fetch(API_GET_FAVORITE_VIDEOS())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(favoriteVideosFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in favoriteVideosFetchData: ${message}`);
      return dispatch(favoriteVideosFetchError(message));
    });
};
