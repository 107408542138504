import { MutableRefObject, useEffect, useRef, useState } from 'react';

// focus idle management
export const useDetectIdle = () => {
  const detectIdleRef = useRef<HTMLDivElement | null>(null);
  const [isFocusWithin, setIsFocusWithin] = useState(false);
  useEffect(() => {
    if (!detectIdleRef.current) return;

    const wrapper = detectIdleRef.current;

    let timeout: ReturnType<typeof setTimeout> | null = null;
    const handleFocusIn = () => {
      setIsFocusWithin(true);
    };
    const handleFocusOut = () => {
      setIsFocusWithin(false);
    };

    wrapper.addEventListener('focusin', handleFocusIn);
    wrapper.addEventListener('focusout', handleFocusOut);

    return () => {
      timeout && clearTimeout(timeout);
      wrapper.removeEventListener('focusin', handleFocusIn);
      wrapper.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  // mouse idle management
  const [isMouseMoving, setMouseMoving] = useState(false);
  const mouseMoveTimeout = useRef(null) as MutableRefObject<ReturnType<
    typeof setTimeout
  > | null>;
  useEffect(
    () => () => {
      if (mouseMoveTimeout.current) clearTimeout(mouseMoveTimeout.current);
    },
    []
  );
  const onMouseMove = () => {
    mouseMoveTimeout.current && clearTimeout(mouseMoveTimeout.current);
    setMouseMoving(true);
    mouseMoveTimeout.current = setTimeout(() => {
      setMouseMoving(false);
    }, 2500);
  };

  return {
    detectIdleRef,
    onMouseMove,
    isMouseMoving,
    setMouseMoving,
    isFocusWithin
  };
};
