import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Video, Storyboard, Advertisement, Brand } from './videoTypes';
import { getVideo, getStoryboard, getAds, getBrands } from './videoApi';

export const useVideo = (
  videoId?: string,
  options: UseQueryOptions<Video> = {}
) =>
  useQuery<Video, unknown>({
    queryKey: ['video', videoId],
    queryFn: () => getVideo(videoId ?? ''),
    ...options,
    enabled: options.enabled ?? !!videoId
  });

export const useStoryboard = (
  videoId?: string,
  options: UseQueryOptions<Storyboard[]> = {}
) =>
  useQuery<Storyboard[], unknown>({
    queryKey: ['storyboard', videoId],
    queryFn: () => getStoryboard(videoId ?? ''),
    ...options,
    enabled: options.enabled ?? !!videoId
  });

export const useAds = (
  videoId?: string,
  options: UseQueryOptions<Advertisement[]> = {}
) =>
  useQuery<Advertisement[], unknown>({
    queryKey: ['ads', videoId],
    queryFn: () => getAds(videoId ?? ''),
    ...options,
    enabled: options.enabled ?? !!videoId
  });

export const useBrands = (
  videoId?: string,
  options: UseQueryOptions<Brand[]> = {}
) =>
  useQuery<Brand[], unknown>({
    queryKey: ['brands', videoId],
    queryFn: () => getBrands(videoId ?? ''),
    ...options,
    enabled: options.enabled ?? !!videoId
  });
