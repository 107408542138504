import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    marginBottom: 32,
    color: '#fff',
    backgroundColor: '#272628',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  title: {
    backgroundColor: '#1F1F1F',
    color: '#F37037',
    fontSize: 14,
    padding: '12px 20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: 0,

    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    padding: '20px 20px 25px',
    backgroundColor: '#181818'
  }
}));
