import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    outline: 'none',
    aspectRatio: '16 / 9'
  },
  video: {
    objectFit: 'cover',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.secondary.main}`,
      outlineOffset: -2
    }
  },
  bar: {
    position: 'absolute',
    bottom: 10,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  mainControlBlock: {
    width: '100%',
    gap: 15
  },
  sound: {
    translate: '6px 2px'
  },
  time: {
    width: '100%'
  },
  startButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 1,
    transition: 'opacity 0.3s'
  },
  startButtonHidden: {
    opacity: 0,
    pointerEvents: 'none'
  },
  rightControlsBlock: {
    padding: '0 15px',
    gap: 13
  },
  fullscreenControl: {
    translate: '0 1px'
  },
  poster: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
    userDrag: 'none'
  },
  live: {
    color: '#fff',
    fontSize: 16,
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    position: 'relative',
    marginRight: 10,
    marginTop: -1,

    '&:before': {
      content: `""`,
      position: 'absolute',
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'red',
      top: '50%',
      left: -14,
      translate: '0 -50%'
    }
  },
  playbackButtons: {
    width: 136,
    minWidth: 136,
    justifyContent: 'center'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));
