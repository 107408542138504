/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardActions,
  Input,
  Tabs,
  Tab,
  Grid
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import Refresh from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';

import {
  libraryFetchData,
  videoDelete,
  videoLogsFetchData,
  videoLogsClear,
  videoUpdate,
  libraryUpdate,
  videoAdd,
  libraryClear
} from '../../actions/videoContentManagement.action';

import Loader from '../../components/Loader';
import VideoCard from '../../components/VideoCard';
import ErrorMessage from '../../components/ErrorMessage';
import JsonEditorModal from '../../components/JsonEditorModal';
import VideoCardActionsCMS from '../../components/videoContentManagement/VideoCardActionsCMS';
import { styles } from './VideoContentManagementPage.styles';

class VideoContentManagement extends Component {
  state = {
    videoLogs: {
      id: null,
      open: false
    },
    jsonEditor: {
      open: false,
      resourceType: null,
      data: null
    },
    addVideoDialog: {
      open: false,
      groupId: null,
      url: null,
      seconds: null
    }
  };

  componentDidMount() {
    this.props.libraryFetchData();
  }

  componentWillUnmount() {
    this.props.libraryClear();
  }

  onDeleteVideo = (e, video) => {
    e.preventDefault();

    this.props.videoDelete(video.id);
  };

  onEditVideo = (e, video) => {
    e.preventDefault();

    this.setState({
      jsonEditor: {
        open: true,
        resourceType: 'video',
        data: JSON.stringify(video)
      }
    });
  };

  onEditLibrary = () => {
    this.setState({
      jsonEditor: {
        open: true,
        resourceType: 'library',
        data: JSON.stringify(this.props.library)
      }
    });
  };

  onJsonEditorClose = () => {
    this.setState({
      jsonEditor: {
        open: false,
        resourceType: null,
        data: null
      }
    });
  };

  onShowVideoLogsModal = (e, video) => {
    e.preventDefault();

    this.setState({
      videoLogs: {
        id: video.id,
        open: true
      }
    });
    this.props.videoLogsFetchData(video.id);
  };

  onReloadVideoLogs = () => {
    this.props.videoLogsFetchData(this.state.videoLogs.id);
  };

  onVideoLogsModalClose = () => {
    this.setState({
      videoLogs: {
        id: null,
        open: false
      }
    });
    this.props.videoLogsClear();
  };

  onSaveJson = (data) => {
    if (this.state.jsonEditor.resourceType === 'video') {
      this.props.videoUpdate(data).then(() => {
        this.setState({
          jsonEditor: {
            open: false,
            resourceType: null,
            data: null
          }
        });
      });
    }

    if (this.state.jsonEditor.resourceType === 'library') {
      this.props.libraryUpdate(data).then(() => {
        this.setState({
          jsonEditor: {
            open: false,
            resourceType: null,
            data: null
          }
        });
      });
    }
  };

  onAddNewVideo = (groupId) => {
    this.setState({
      addVideoDialog: {
        open: true,
        groupId: groupId,
        url: null
      }
    });
  };

  onAddNewVideoSubmit = () => {
    this.props
      .videoAdd({
        groupId: this.state.addVideoDialog.groupId,
        url: this.state.addVideoDialog.url,
        seconds: this.state.addVideoDialog.seconds
      })
      .then(() => {
        this.setState({
          addVideoDialog: {
            open: false,
            groupId: null,
            url: null
          }
        });
      });
  };

  onAddVideoDialogClose = () => {
    this.setState({
      addVideoDialog: {
        open: false,
        groupId: null,
        url: null
      }
    });
  };

  onAddVideoUrlChange = (e) => {
    this.setState({
      addVideoDialog: {
        ...this.state.addVideoDialog,
        url: e.currentTarget.value
      }
    });
  };

  onAddVideoSecondsChange = (e) => {
    this.setState({
      addVideoDialog: {
        ...this.state.addVideoDialog,
        seconds: Number(e.currentTarget.value)
      }
    });
  };

  renderGroup(group) {
    const { classes, deleteVideo } = this.props;

    return (
      <div className={classes.videoGroup} key={group.id}>
        <Typography className={classes.divider} color="secondary">
          {group.name}
        </Typography>
        <Tabs
          value={false}
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            scrollButtonsAuto: classes.scrollButton,
            flexContainer: classes.flexContainer
          }}
        >
          <Card className={classes.addVideoCard}>
            <CardContent className={classes.addVideoCardContent}>
              <IconButton
                className={classes.addButton}
                onClick={() => this.onAddNewVideo(group.id)}
              >
                <AddIcon fontSize="large" />
              </IconButton>
            </CardContent>
          </Card>
          {group.videos.map((video, idx) =>
            video.status === 'processing' ? (
              <Card
                key={video.id + idx}
                className={classes.processingVideoCard}
              >
                <CardContent className={classes.processingVideoCardContent}>
                  <Loader loaderProps={{ size: 32 }} fullHeightCentralized />
                </CardContent>
                <CardActions className={classes.processingVideoCardActions}>
                  <IconButton
                    className={classes.actionButton}
                    aria-label="Show Progress Log"
                    onClick={(e) => this.onShowVideoLogsModal(e, video)}
                  >
                    <ViewHeadline fontSize="small" />
                  </IconButton>
                </CardActions>
              </Card>
            ) : (
              <div key={video.id + idx} className={classes.cardContainer}>
                <VideoCard
                  video={video}
                  actions={
                    <VideoCardActionsCMS
                      videoId={video.id}
                      deleteVideo={deleteVideo}
                      deleteVideoFn={(e) => this.onDeleteVideo(e, video)}
                      showVideoLogsModalFn={(e) =>
                        this.onShowVideoLogsModal(e, video)
                      }
                      onEditVideoFn={(e) => this.onEditVideo(e, video)}
                    />
                  }
                />
              </div>
            )
          )}
        </Tabs>
      </div>
    );
  }

  render() {
    const {
      classes,
      isLoading,
      isFailed,
      isUpdatingVideo,
      isUpdatingLibrary,
      isAddingVideo
    } = this.props;

    if (isLoading) {
      return <Loader loaderProps={{ size: 40 }} fullHeightCentralized />;
    }

    if (isFailed) {
      return <ErrorMessage fullHeightCentralized />;
    }

    let content = null;

    if (this.props.library) {
      content = this.props.library.groups.map((group) =>
        this.renderGroup(group)
      );
    }

    return (
      <div className={classes.root}>
        <Dialog
          open={this.state.videoLogs.open}
          onClose={this.onVideoLogsModalClose}
          classes={{ paper: classes.modal }}
          maxWidth="lg"
        >
          <DialogTitle classes={{ root: classes.modalTitle }} disableTypography>
            <Typography className={classes.modalTitleText} variant="h6">
              Video Processing Log
            </Typography>

            {this.props.videoLogs.isLoading ? (
              <Loader loaderProps={{ size: 24 }} fullHeightCentralized />
            ) : (
              <IconButton aria-label="Refresh" onClick={this.onReloadVideoLogs}>
                <Refresh className={classes.refreshVideoLogButton} />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent className={classes.modalContent}>
            {this.props.videoLogs.isLoading && !this.props.videoLogs.data ? (
              <Loader loaderProps={{ size: 40 }} fullHeightCentralized />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: this.props.videoLogs.data }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onVideoLogsModalClose}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.addVideoDialog.open}
          onClose={this.onAddVideoDialogClose}
          classes={{ paper: classes.modal }}
          maxWidth="sm"
        >
          <DialogTitle classes={{ root: classes.modalTitle }} disableTypography>
            <Typography className={classes.modalTitleText} variant="h6">
              Add New Video
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.modalContent}>
            <Grid container alignItems="flex-end">
              <Grid item md={8} sm={12}>
                <Input
                  value={this.state.addVideoDialog.url}
                  onChange={this.onAddVideoUrlChange}
                  color="secondary"
                  label="Video URL"
                  classes={{
                    input: classes.input,
                    underline: classes.cssUnderline
                  }}
                  placeholder="Video URL"
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <Input
                  value={this.state.addVideoDialog.seconds}
                  onChange={this.onAddVideoSecondsChange}
                  color="secondary"
                  label="Video URL"
                  classes={{
                    input: classes.input,
                    underline: classes.cssUnderline
                  }}
                  placeholder="Seconds"
                  type="number"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onAddVideoDialogClose}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={this.onAddNewVideoSubmit}
              disabled={isAddingVideo || !this.state.addVideoDialog.url}
            >
              {isAddingVideo ? <CircularProgress size={22} /> : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.jsonEditor.open && (
          <JsonEditorModal
            title={
              this.state.jsonEditor.resourceType === 'video'
                ? 'Edit Video'
                : 'Edit Library'
            }
            json={this.state.jsonEditor.data}
            onClose={this.onJsonEditorClose}
            onSave={this.onSaveJson}
            saving={isUpdatingVideo || isUpdatingLibrary}
          />
        )}
        <div className={classes.header}>
          <Tabs value={0}>
            <Tab
              key="videos"
              className={classes.tab}
              index={0}
              label="Videos"
              component={Link}
              to="/admin/cms/videos"
            />
            <Tab
              key="channels"
              className={classes.tab}
              index={1}
              label="Channels"
              component={Link}
              to="/admin/cms/channels"
            />
          </Tabs>
          <Button
            variant="contained"
            color="primary"
            className={classes.editLibraryBtn}
            onClick={this.onEditLibrary}
          >
            Edit Library
          </Button>
        </div>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.videoContentManagement.library.isLoading,
  isFailed: state.videoContentManagement.library.isFailed,
  library: state.videoContentManagement.library.data,
  videoLogs: state.videoContentManagement.videoLogs,
  deleteVideo: state.videoContentManagement.deleteVideo,
  isUpdatingVideo: state.videoContentManagement.updateVideo.updating,
  isUpdatingLibrary: state.videoContentManagement.updateLibrary.updating,
  isAddingVideo: state.videoContentManagement.addVideo.adding
});

const mapDispatchToProps = (dispatch) => ({
  libraryFetchData: () => dispatch(libraryFetchData()),
  videoDelete: (id) => dispatch(videoDelete(id)),
  videoLogsFetchData: (id) => dispatch(videoLogsFetchData(id)),
  videoLogsClear: () => dispatch(videoLogsClear()),
  videoUpdate: (data) => dispatch(videoUpdate(data)),
  libraryUpdate: (data) => dispatch(libraryUpdate(data)),
  videoAdd: (data) => dispatch(videoAdd(data)),
  libraryClear: () => dispatch(libraryClear())
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(VideoContentManagement)
);
