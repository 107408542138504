import React, { HTMLAttributes } from 'react';

export const MicIdle = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="110"
    height="111"
    viewBox="0 0 110 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="54.9992" cy="56" r="51.6145" fill="#F37036" fillOpacity="0.2" />
    <g filter="url(#filter0_d_125_3175)">
      <rect
        x="21.4102"
        y="21.9775"
        width="67.1807"
        height="67.1807"
        rx="33.5904"
        fill="#F37036"
        fillOpacity="0.1"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_125_3175"
        x="0.410156"
        y="0.977463"
        width="109.182"
        height="109.181"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.383083 0 0 0 0 0.109945 0 0 0 0.57 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_125_3175"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_125_3175"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const MicActive = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="136"
    height="136"
    viewBox="0 0 136 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="68" cy="68" r="68" fill="#F37036" fillOpacity="0.2" />
    <circle cx="67.9992" cy="68" r="51.6145" fill="#F37036" fillOpacity="0.2" />
    <g filter="url(#filter0_d_136_894)">
      <rect
        x="34.4121"
        y="33.9775"
        width="67.1807"
        height="67.1807"
        rx="33.5904"
        fill="#F37036"
        fillOpacity="0.1"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_136_894"
        x="13.4121"
        y="12.9775"
        width="109.182"
        height="109.181"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.383083 0 0 0 0 0.109945 0 0 0 0.57 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_136_894"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_136_894"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
