import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    gap: 24,
    transition: 'background-color 0.2s',
    position: 'relative',

    '&:hover:not($activeCard)': {
      backgroundColor: '#282828'
    }
  },
  activeCard: {
    backgroundColor: '#6c6c6c',

    '& $img:before': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  img: {
    width: '40%',
    minWidth: '40%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: 0,
      bottom: 0,
      left: 0,
      width: 4,
      backgroundColor: 'transparent',
      transition: 'background-color 0.2s'
    }
  },
  text: {
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  description: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#fff',
    fontSize: 14,

    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@supports (-webkit-line-clamp: 2)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  time: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 12,
    color: '#fff'
  }
}));
