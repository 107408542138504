/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { KIBANA_LINK } from '../../constants';
import { styles } from './PlatformAnalyticsPage.styles';

class PlatformAnalyticsPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <iframe
          title="Platform Analytics"
          src={KIBANA_LINK}
          height="94%"
          width="94%"
        />
      </div>
    );
  }
}

export default withStyles(styles)(PlatformAnalyticsPage);
