import React, { memo } from 'react';
import { arrayOf, func, object } from 'prop-types';
import { Tabs } from '@material-ui/core';

import Tooltip from '../../Tooltip';
import { useStyles } from './MatchedVideos.styles';

const propTypes = {
  videos: arrayOf(object),
  onMatchSelect: func
};

const defaultProps = {
  videos: [],
  onMatchSelect: () => {}
};

const MatchedVideos = ({ videos, onMatchSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs
        value={false}
        variant="scrollable"
        scrollButtons="auto"
        classes={{
          scrollButtons: classes.scrollButton,
          flexContainer: classes.flexContainer
        }}
      >
        {videos.map((video, idx) => {
          const { id, thumbnail, thumbnailOnHover, title, description } = video;
          const backgroundImage = `url('${thumbnail}')`;
          const hoverBackgroundImage = thumbnailOnHover
            ? `url('${thumbnailOnHover}')`
            : backgroundImage;

          return (
            <button
              onClick={() => onMatchSelect(video)}
              className={classes.tile}
              key={id + idx}
            >
              <Tooltip
                title={
                  <>
                    <p className={classes.tooltipTitle}>{title}</p>
                    <p className={classes.tooltipText}>{description}</p>
                  </>
                }
              >
                <div>
                  <div
                    style={{ backgroundImage: hoverBackgroundImage }}
                    className={classes.thumbnailHover}
                  />
                  <div
                    style={{ backgroundImage }}
                    className={classes.thumbnail}
                  />
                </div>
              </Tooltip>
            </button>
          );
        })}
      </Tabs>
    </div>
  );
};

MatchedVideos.propTypes = propTypes;
MatchedVideos.defaultProps = defaultProps;

export default memo(MatchedVideos);
