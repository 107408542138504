import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  loginPage: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: '40px 50px 40px 50px',
    flex: 1
  },
  loader: {
    marginTop: '50px'
  },
  form: {
    margin: '0 auto',
    width: '350px'
  },
  input: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '32px',
    lineHeight: '43px',
    textAlign: 'center',
    '&::-webkit-input-placeholder': {
      fontStyle: 'italic'
    },
    '&::-ms-clear': {
      display: 'none'
    }
  },
  cssUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.contrastText
    },
    '&:hover:before': {
      borderBottom: `1px solid ${theme.palette.primary.contrastText}!important`
    },
    '&:after': {
      borderBottomColor: theme.palette.secondary.main
    }
  },
  button: {
    marginTop: '10px'
  },
  errorMessage: {
    marginTop: '10px'
  }
}));
