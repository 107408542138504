export const ADMIN_LINKS = [
  {
    value: 'CONTENT_MANAGEMENT',
    label: 'Content Management',
    path: '/admin/cms'
  },
  {
    value: 'PLATFORM_INTELLIGENCE',
    label: 'GenAI',
    path: '/admin/ml'
  },
  {
    value: 'PLATFORM_ANALYTICS',
    label: 'Platform Analytics',
    path: '/admin/analytics'
  },
  {
    value: 'PLATFORM_MONITORING',
    label: 'Platform Monitoring',
    path: '/admin/apm'
  },
  {
    value: 'TRANSCODING',
    label: 'Transcoding',
    path: '/admin/transcoding'
  }
];
