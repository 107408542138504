/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { styles } from './FavoriteChannel.styles';

const FavoriteChannel = ({ channel, classes }) => (
  <div className={classes.root}>
    <div className={`${classes.columnLeft} sm-hidden`}>
      <Link to="#">
        <img
          src={channel.thumbnail}
          title={channel.title}
          className={classes.channelThumbnail}
          alt=""
        />
      </Link>
    </div>
    <Link to="#" className={classes.columnRight}>
      <div
        className={classes.playingNowThumbnail}
        style={{ backgroundImage: `url('${channel.thumbnail}')` }}
      />
      <div className={classes.playingNowTitleWrp}>
        <Typography component="p" className={classes.playingNowTitle}>
          {channel.title}
        </Typography>
      </div>
    </Link>
  </div>
);

export default withStyles(styles)(FavoriteChannel);
