import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { SearchSlice, searchSlice } from './searchSlice';
import { RegularSearchSlice, regularSearchSlice } from './regularSearchSlice';
import { AISearchSlice, aiSearchSlice } from './aiSearchSlice';

type SearchStore = SearchSlice & RegularSearchSlice & AISearchSlice;

export const useSearchStore = create<SearchStore>()(
  devtools((set) => ({
    // searchSlice is for generic state that is not directly related to either regular or ai search
    ...searchSlice(set),
    ...regularSearchSlice(set),
    ...aiSearchSlice(set)
  }))
);
