import createContextStore from '../../../shared/createContextStore';

// Using context instead of zustand so store can have multiple instances

const {
  Provider: VideoStoreProvider,
  useStoreValue: useVideoStoreValue,
  useStoreDispatch: useVideoStoreDispatch
} = createContextStore(
  {
    isStarted: false,
    isHovered: false,
    isPlaying: false,
    isFullscreen: false,
    isCCshown: false,
    /** marks whether user navigates by keyboard - to show/hide toolbar */
    isFocusIdle: false,
    currentTime: 0,
    loadedTime: 0,
    duration: 0,
    volume: 1
  },
  'VideoStoreProvider,'
);

export { VideoStoreProvider, useVideoStoreValue, useVideoStoreDispatch };
