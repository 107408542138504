export const styles = () => ({
  root: {
    padding: 32,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 600px)',
    gap: '20px',
    justifyContent: 'center',
    '@media (max-width:670px)': {
      gridTemplateColumns: '1fr'
    }
  }
});
