import React from 'react';
import CircularProgress, {
  CircularProgressProps
} from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import { useStyles } from './Loader.styles';

type Props = {
  fullHeightCentralized?: boolean;
  loaderProps?: CircularProgressProps;
};

const Loader = ({ fullHeightCentralized = false, loaderProps }: Props) => {
  const styles = useStyles();

  const loader = (
    <CircularProgress
      variant="indeterminate"
      thickness={2}
      {...loaderProps}
      className={clsx(styles.progress, loaderProps?.className)}
    />
  );

  return fullHeightCentralized ? (
    <div className={styles.loader}>
      <div className={styles.progressDiv}>{loader}</div>
    </div>
  ) : (
    loader
  );
};

export default Loader;
