import React from 'react';
import { bool } from 'prop-types';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './Tooltip.styles';

const propTypes = { shadow: bool };
const defaultProps = { shadow: false };

const Tooltip = ({
  // eslint-disable-next-line react/prop-types
  classes: { popper, tooltip, tooltipWithShadow },
  shadow,
  ...props
}) => (
  <MaterialTooltip
    classes={{
      popper,
      tooltip: shadow ? tooltipWithShadow : tooltip
    }}
    {...props}
  />
);

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default withStyles(styles)(Tooltip);
