import React, { HTMLAttributes, forwardRef, useRef, useState } from 'react';

import {
  ChannelMulti,
  ChannelScreen,
  ChannelSingle
} from '../../../api/channels';
import mergeRefs from '../../../shared/mergeRefs';
import { Video } from '../../../api/video';
import { useGetUser } from '../../../api/user';
import { useLoopStore } from '../../../stores/loopStore';
import { AdItem } from '../../../api/adTech';

import VideoPlayer from '../VideoPlayer';
import GearIcon from '../VideoControls/GearIcon';
import SelectControl from '../VideoControls/SelectControl';
import { useReplaceParams } from './hooks';

export type VideoObject = ChannelSingle | ChannelScreen | Video | AdItem;

type Props = HTMLAttributes<HTMLDivElement> & {
  /** Generic object that could be very different video object types that are used in the app */
  videoObject: VideoObject;
  /** in case of multiscreen, and possible case of url template params - data of both objects required */
  parentObject?: ChannelMulti;
  autoplay?: boolean;
  disablePoster?: boolean;
  hideControls?: boolean;
  /** explicitly say is looping, if not specified - taken from global store */
  isLooping?: boolean;
  videoTabIndex?: number;
};

/** modify data that needed for video player here. e.g. different video object type transform to what is needed to VideoPlayer,
 * replace template params in URL, etc. */
const VideoContainer = forwardRef<HTMLVideoElement, Props>(
  (
    {
      videoObject,
      parentObject,
      autoplay: autoplayProps,
      disablePoster,
      isLooping: propsIsLooping,
      videoTabIndex,
      ...props
    },
    ref
  ) => {
    const [activeTrack, setActiveTrack] = useState(0);

    const autoplay =
      Boolean((videoObject as ChannelScreen).autoplay) || autoplayProps;

    const srcList = videoObject.src_list;
    const currentSource = srcList[activeTrack];
    const { src, type } = currentSource;
    const [srcWithParams, resetUrlParams] = useReplaceParams(
      src,
      videoObject,
      parentObject
    );

    const { data } = useGetUser();
    const sessionToken = data?.token;

    const isHls =
      (videoObject as ChannelScreen).stream_type === 'hls' ||
      src.includes('.m3u8') ||
      type === 'application/x-mpegURL';

    const videoRef = useRef<HTMLVideoElement>(null);
    const isStarted = useRef(false);

    const isLooping = useLoopStore((store) => store.isLooping);

    return (
      <VideoPlayer
        key={srcWithParams}
        src={srcWithParams}
        // you might need to test/debug close captions locally (external CC most likely won't work due to CORS issue)
        // cc="http://localhost:3000/cc.vtt"
        cc={(videoObject as Video).cc}
        ref={mergeRefs(ref, videoRef)}
        trackSelector={
          <SelectControl
            items={srcList}
            initialSelectedItem={srcList[activeTrack]}
            itemToString={(item) => item?.label ?? ''}
            renderItem={(item) => item.label}
            renderSelectedItem={(item) => (
              <div style={{ width: 16, height: 16 }} title={item?.label}>
                <GearIcon />
              </div>
            )}
            onSelectedItemChange={(changes) => {
              isStarted.current = (videoRef.current?.currentTime ?? 0) > 0;
              const index = srcList.findIndex(
                (item) => item.label === changes.selectedItem.label
              );
              setActiveTrack(index === -1 ? 0 : index);
              setTimeout(() => resetUrlParams());
              setTimeout(() => {
                isStarted && videoRef.current?.play();
              }, 50);
            }}
          />
        }
        isHls={isHls}
        poster={(videoObject as ChannelSingle).poster ?? parentObject?.poster}
        disablePoster={disablePoster}
        videoProps={{
          autoPlay: autoplay,
          loop:
            typeof propsIsLooping === 'boolean' ? propsIsLooping : isLooping,
          tabIndex: videoTabIndex
        }}
        sessionToken={sessionToken}
        {...props}
      />
    );
  }
);
VideoContainer.displayName = 'VideoContainer';

export default VideoContainer;
