import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    all: 'unset',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',

    '&:focus-visible': {
      outline: '1px solid rgb(255 255 255)',
      outlineOffset: 2
    },

    '& svg': {
      width: 20,
      height: 20
    },

    '&:hover svg': {
      filter: 'drop-shadow(0px 0px 6px rgb(255 255 255))'
    }
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4
  },
  title: {
    fontFamily: 'Open Sans',
    textAlign: 'justify',
    lineHeight: '20px',
    margin: 5,
    color: '#f37037',
    fontWeight: 600,
    fontSize: 16
  },
  text: {
    fontFamily: 'Open Sans',
    textAlign: 'justify',
    lineHeight: '20px',
    margin: 5,
    fontWeight: 500,
    fontSize: 15
  }
}));
