import React from 'react';
import { arrayOf, bool, func, node, object, oneOfType } from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './ProgramDetailsDialog.styles';

const propTypes = {
  isOpen: bool,
  handleClose: func,
  classes: object.isRequired,
  children: oneOfType([arrayOf(node), node])
};

const defaultProps = {
  isOpen: false,
  handleClose: () => {},
  children: null
};

const ProgramDetailsDialog = ({
  isOpen,
  handleClose,
  classes: {
    modal,
    modalTitle,
    modalTitleText,
    modalCloseButton,
    modalCloseIcon,
    modalContent
  },
  children
}) => (
  <Dialog open={isOpen} onClose={handleClose} classes={{ paper: modal }}>
    <DialogTitle classes={{ root: modalTitle }}>
      <Typography className={modalTitleText}>Program Details</Typography>
      <IconButton
        disableRipple
        onClick={handleClose}
        className={modalCloseButton}
      >
        <CloseIcon className={modalCloseIcon} />
      </IconButton>
    </DialogTitle>
    <DialogContent classes={{ root: modalContent }}>{children}</DialogContent>
  </Dialog>
);

ProgramDetailsDialog.propTypes = propTypes;
ProgramDetailsDialog.defaultProps = defaultProps;

export default withStyles(styles)(ProgramDetailsDialog);
