import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import { useStyles } from './VideoSlide.styles';

const VideoSlide = ({ className, ...props }: HTMLAttributes<HTMLLIElement>) => {
  const styles = useStyles();

  return <li className={clsx(styles.slide, className)} {...props} />;
};

export default VideoSlide;
