/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';

import { styles } from './ProgramActions.styles';
import { ScheduledIcon } from '../../assets/icons';
import { DismissIcon } from '../../assets/icons';
import { connect } from 'react-redux';
import { programGuideFetchData } from '../../actions/programGuide.action';
import {
  programFetchRecording,
  programPostRecording
} from '../../actions/videoDetails.action';
import { recordingsFetchDataDB } from '../../actions/recordings.action';

class ProgramActions extends Component {
  state = {
    scheduled: this.props.recorded || false,
    recordHover: false
  };

  componentDidMount() {
    this.setState({ state: this.props.position });
    this.props.recordingsFetchDataDB();

    // FIXME - what the damn hell. Most likely this was written for putting it in the end of queue, therefore convert to setTimeout with 0ms
    let promise = new Promise((resolve) => {
      resolve();
    });
    promise.then(() => {
      if (this.props.id) {
        let recording = this.props.recordings.find((item) => {
          return this.props.id === item.id;
        });
        if (recording) {
          this.setState({ scheduled: true, state: recording.state });
        }
      }
    });
  }

  scheduleRecording = () => {
    this.setState({ scheduled: true });
    this.props.schedule(this.props.recordings);
  };

  dismissRecording = () => {
    this.setState({ scheduled: false });
    this.props.dismiss(this.props.item);
  };

  handleMouseHover = () =>
    this.setState({ recordHover: !this.state.recordHover });

  render() {
    let scheduleButton;
    let scheduledIcon;
    let buttonHoverText = !this.state.recordHover ? this.state.state : 'Remove';

    if (this.state.scheduled) {
      scheduledIcon = !this.state.recordHover ? (
        <ScheduledIcon />
      ) : (
        <DismissIcon />
      );
    }

    if (this.state.scheduled) {
      scheduleButton = (
        <div
          className={this.props.classes.actionBlock}
          onClick={this.dismissRecording}
          onMouseEnter={this.handleMouseHover}
          onMouseLeave={this.handleMouseHover}
        >
          <IconButton classes={{ root: this.props.classes.iconButton }}>
            {scheduledIcon}
          </IconButton>
          <Typography className={this.props.classes.recordText}>
            {buttonHoverText}
          </Typography>
        </div>
      );
    } else {
      scheduleButton = (
        <div
          className={this.props.classes.actionBlock}
          onClick={this.scheduleRecording}
        >
          <IconButton
            className={this.props.classes.recordButton}
            classes={{ root: this.props.classes.iconButton }}
          >
            {scheduledIcon}
          </IconButton>
          <Typography className={this.props.classes.recordText}>
            Record
          </Typography>
        </div>
      );
    }

    return (
      <div className={this.props.classes.actionsContainer}>
        <div className={this.props.classes.actionBlock}>
          <IconButton
            className={this.props.classes.notifyButton}
            classes={{ root: this.props.classes.iconButton }}
          >
            <Notifications />
          </IconButton>
          <Typography className={this.props.classes.actionText}>
            Remind
          </Typography>
        </div>
        {scheduleButton}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.programGuide.isLoading,
  isFailed: state.programGuide.isFailed,
  data: state.programGuide.data,
  recordings: state.recordings.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgramGuide: (programType, date) =>
    dispatch(programGuideFetchData(programType, date)),
  fetchRecording: (id) => dispatch(programFetchRecording(id)),
  recordingsFetchDataDB: () => dispatch(recordingsFetchDataDB()),
  postRecording: (body) => dispatch(programPostRecording(body))
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ProgramActions)
);
