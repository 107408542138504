import {
  API_GET_ALL_PROFILES,
  API_CREATE_PROFILE,
  API_UPDATE_PROFILE_BY_ID,
  API_DELETE_PROFILE_BY_ID,
  API_CREATE_JOB,
  API_GET_JOBS,
  API_GET_JOB_BY_ID
} from './transcodingUrls';

export class TranscodingAPI {
  static getProfiles() {
    return fetch(API_GET_ALL_PROFILES())
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json());
  }

  static createProfile(data) {
    return fetch(API_CREATE_PROFILE(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response;
      })
      .then((response) => response.json());
  }

  static updateProfileById(id, data) {
    return fetch(API_UPDATE_PROFILE_BY_ID(id), {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response;
      })
      .then((response) => response.json());
  }

  static deleteProfileById(id) {
    return fetch(API_DELETE_PROFILE_BY_ID(id), {
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response;
      })
      .then((response) => response.json());
  }

  static getJobIds() {
    return fetch(API_CREATE_JOB())
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json())
      .then((data) => data.jobs);
  }

  static getJobById(id) {
    return fetch(API_GET_JOBS(id))
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json());
  }

  static createJobs(data) {
    return fetch(API_GET_JOB_BY_ID(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response;
      })
      .then((response) => response.json());
  }
}
