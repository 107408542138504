import {
  FETCH_LIBRARY_REQUEST,
  FETCH_LIBRARY_ERROR,
  FETCH_LIBRARY_SUCCESS,
  FETCH_VIDEO_LOGS_REQUEST,
  FETCH_VIDEO_LOGS_SUCCESS,
  FETCH_VIDEO_LOGS_ON_CHUNK,
  FETCH_VIDEO_LOGS_ERROR,
  CLEAR_VIDEO_LOGS,
  DELETE_LIBRARY_VIDEO_REQUEST,
  DELETE_LIBRARY_VIDEO_SUCCESS,
  DELETE_LIBRARY_VIDEO_ERROR,
  ADD_LIBRARY_VIDEO_REQUEST,
  ADD_LIBRARY_VIDEO_SUCCESS,
  ADD_LIBRARY_VIDEO_ERROR,
  UPDATE_LIBRARY_VIDEO_REQUEST,
  UPDATE_LIBRARY_VIDEO_ERROR,
  UPDATE_LIBRARY_VIDEO_SUCCESS,
  UPDATE_LIBRARY_REQUEST,
  UPDATE_LIBRARY_ERROR,
  UPDATE_LIBRARY_SUCCESS,
  CLEAR_LIBRARY
} from '../constants/actionTypes';

export const initialState = {
  library: {
    isLoading: false,
    isFailed: false,
    data: null
  },
  videoLogs: {
    isLoading: false,
    isFailed: false,
    data: null
  },
  deleteVideo: {
    id: null,
    deleting: false
  },
  addVideo: {
    adding: false
  },
  updateVideo: {
    updating: false
  },
  updateLibrary: {
    updating: false
  }
};

export function videoContentManagement(state = initialState, action) {
  switch (action.type) {
    case FETCH_LIBRARY_REQUEST:
      return { ...state, library: { ...state.library, isLoading: true } };
    case FETCH_LIBRARY_ERROR:
      return {
        ...state,
        library: { ...state.library, isLoading: false, isFailed: true }
      };
    case FETCH_LIBRARY_SUCCESS:
      return {
        ...state,
        library: { ...state.library, isLoading: false, data: action.payload }
      };

    case UPDATE_LIBRARY_VIDEO_REQUEST:
      return {
        ...state,
        updateVideo: { ...state.updateVideo, updating: true }
      };
    case UPDATE_LIBRARY_VIDEO_ERROR:
      return {
        ...state,
        updateVideo: { ...state.updateVideo, updating: false }
      };
    case UPDATE_LIBRARY_VIDEO_SUCCESS:
      return {
        ...state,
        updateVideo: { ...state.updateVideo, updating: false },
        library: {
          ...state.library,
          data: {
            groups: state.library.data.groups.map((group) => ({
              ...group,
              videos: group.videos.map((video) =>
                video.id === action.payload.id ? action.payload : video
              )
            }))
          }
        }
      };

    case UPDATE_LIBRARY_REQUEST:
      return {
        ...state,
        updateLibrary: { ...state.updateLibrary, updating: true }
      };
    case UPDATE_LIBRARY_ERROR:
      return {
        ...state,
        updateLibrary: { ...state.updateLibrary, updating: false }
      };
    case UPDATE_LIBRARY_SUCCESS:
      return {
        ...state,
        updateLibrary: { ...state.updateLibrary, updating: false },
        library: {
          ...state.library,
          data: action.payload
        }
      };

    case FETCH_VIDEO_LOGS_REQUEST:
      return {
        ...state,
        videoLogs: { ...state.videoLogs, isLoading: true, data: '' }
      };
    case FETCH_VIDEO_LOGS_ERROR:
      return {
        ...state,
        videoLogs: { ...state.videoLogs, isLoading: false, isFailed: true }
      };
    case FETCH_VIDEO_LOGS_SUCCESS:
      return {
        ...state,
        videoLogs: {
          ...state.videoLogs,
          isLoading: false,
          data: action.payload
        }
      };
    case FETCH_VIDEO_LOGS_ON_CHUNK:
      return {
        ...state,
        videoLogs: {
          ...state.videoLogs,
          isLoading: !action.payload.done,
          data: (state.videoLogs.data || '') + action.payload.value
        }
      };
    case CLEAR_VIDEO_LOGS:
      return { ...state, videoLogs: initialState.videoLogs };

    case DELETE_LIBRARY_VIDEO_REQUEST:
      return {
        ...state,
        deleteVideo: {
          ...state.deleteVideo,
          id: action.payload,
          deleting: true
        }
      };
    case DELETE_LIBRARY_VIDEO_ERROR:
      return {
        ...state,
        deleteVideo: { ...state.deleteVideo, id: null, deleting: false }
      };
    case DELETE_LIBRARY_VIDEO_SUCCESS:
      return {
        ...state,
        library: {
          ...state.library,
          data: {
            groups: state.library.data.groups.map((group) => ({
              ...group,
              videos: group.videos.filter(
                (video) => video.id !== action.payload
              )
            }))
          }
        },
        deleteVideo: { ...state.deleteVideo, id: null, deleting: false }
      };

    case ADD_LIBRARY_VIDEO_REQUEST:
      return { ...state, addVideo: { ...state.addVideo, adding: true } };
    case ADD_LIBRARY_VIDEO_ERROR:
      return { ...state, addVideo: { ...state.addVideo, adding: false } };
    case ADD_LIBRARY_VIDEO_SUCCESS:
      return {
        ...state,
        library: {
          ...state.library,
          data: {
            groups: state.library.data.groups.map((group) =>
              group.id === action.payload.groupId
                ? { ...group, videos: [action.payload.video, ...group.videos] }
                : group
            )
          }
        },
        addVideo: { ...state.addVideo, adding: false }
      };

    case CLEAR_LIBRARY:
      return initialState;

    default:
      return state;
  }
}
