import React from 'react';
import { object, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';

import { styles } from './VideoCardDashboard.styles';

const propTypes = {
  title: string,
  thumbnail: string.isRequired,
  classes: object.isRequired
};

const defaultProps = {
  title: ''
};

const VideoCardDashboard = ({ title, thumbnail, classes }) => (
  <Card className={classes.root}>
    <CardMedia className={classes.media} image={thumbnail} />
    <CardContent className={classes.content}>
      <Typography component="p" className={classes.contentTitle}>
        {title}
      </Typography>
    </CardContent>
  </Card>
);

VideoCardDashboard.propTypes = propTypes;
VideoCardDashboard.defaultProps = defaultProps;

export default withStyles(styles)(VideoCardDashboard);
