import React, { HTMLAttributes } from 'react';
import * as SliderRadix from '@radix-ui/react-slider';
import clsx from 'clsx';

import { useStyles } from './CarouselSlider.styles';

type Props = SliderRadix.SliderProps & HTMLAttributes<HTMLSpanElement>;

const CarouselSlider = ({
  className,
  max = 0,
  value = [],
  ...props
}: Props) => {
  const styles = useStyles();

  return (
    <SliderRadix.Root
      orientation="horizontal"
      defaultValue={[0]}
      step={1}
      max={max}
      value={value}
      className={clsx(styles.sliderRoot, className)}
      {...props}
    >
      <SliderRadix.Track className={styles.sliderTrack}>
        <SliderRadix.Range className={styles.sliderRange} />
        {/*
          As per this day (2024.06.05), Radix Slider does not support step markers, so custom one are made.
          But just in case, check this issue: https://github.com/radix-ui/primitives/issues/1188
          It might not be needed in the future.
        */}
        <div className={styles.markers}>
          {new Array(max).fill(null).map((_, idx) => (
            <div
              key={idx}
              className={clsx(
                styles.marker,
                value[0] >= idx && styles.markerBehind
              )}
            />
          ))}
        </div>
      </SliderRadix.Track>
      <SliderRadix.Thumb
        className={styles.sliderThumb}
        aria-label="Carousel position"
      />
    </SliderRadix.Root>
  );
};

export default CarouselSlider;
