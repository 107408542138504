/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { styles } from './NotFoundPage.styles';

class NotFoundPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>
          404 - Oops, page not found...
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(NotFoundPage);
