export const styles = (theme) => ({
  videoGroup: {
    padding: '15px 0'
  },
  divider: {
    display: 'table',
    whiteSpace: 'nowrap',
    paddingRight: '20px',
    marginBottom: '15px',
    lineHeight: '24px',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    '&:after': {
      display: 'table-cell',
      position: 'relative',
      width: '100%',
      top: '12px',
      left: '20px',
      borderTop: '2px solid',
      borderTopColor: theme.palette.primary.main,
      opacity: 0.5,
      content: "''"
    }
  },
  flexContainer: {
    justifyContent: 'space-between'
  },
  cardContainer: {
    width: '300px',
    minWidth: '300px',
    marginRight: '15px',
    marginLeft: '15px'
  },
  scrollButton: {
    color: '#D8D8D8',
    '& svg': {
      fontSize: 40
    }
  }
});
