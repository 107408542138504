import React, { HTMLAttributes, forwardRef, useEffect } from 'react';
import clsx from 'clsx';

import mergeRefs from '../../shared/mergeRefs';

import { useStyles } from './VideoSlides.styles';

const VideoSlides = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLUListElement>
>(({ className, children, ...props }, ref) => {
  const styles = useStyles();

  useEffect(() => {}, []);

  return (
    <ul
      className={clsx(styles.slides, className)}
      {...props}
      ref={mergeRefs(ref)}
    >
      {children}
    </ul>
  );
});
VideoSlides.displayName = 'VideoSlides';

export default VideoSlides;
