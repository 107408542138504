import React, { forwardRef } from 'react';
import { InputProps } from '@material-ui/core';
import { useShallow } from 'zustand/react/shallow';

import { useSearchStore, useSetSearchQueryStr } from '../../../../stores';
import { useSearchVideos } from '../../../../api/search';
import { useInitAiSearch } from '../../../../api/searchAi';
import { useGetUser } from '../../../../api/user';

import HeadInput from '../../HeadInput';

const SearchRegular = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    searchStr,
    setSearchStr,
    searchQueryStr,
    isMutating,
    setActiveSearch,
    setChatOpen
  } = useSearchStore(
    useShallow((state) => ({
      searchStr: state.searchStr,
      setSearchStr: state.setSearchStr,
      searchQueryStr: state.searchQueryStr,
      isMutating: state.isMutating,
      setActiveSearch: state.setActiveSearch,
      setChatOpen: state.setChatOpen
    }))
  );
  const setSearchQueryStr = useSetSearchQueryStr();

  const { isLoading, isFetching } = useSearchVideos(searchQueryStr ?? '');
  const { isLoading: isInitLoading, isFetching: isInitFetching } =
    useInitAiSearch();
  const { isError: isNotLoggedIn } = useGetUser();

  const loading =
    isLoading ||
    isFetching ||
    isMutating ||
    (isNotLoggedIn ? false : isInitLoading || isInitFetching);

  const onSubmit = () => {
    setSearchQueryStr(searchStr);
    setActiveSearch('regular');
    setChatOpen(false);
  };

  return (
    <HeadInput
      {...props}
      ref={ref}
      value={searchStr}
      onChange={(e) => setSearchStr(e.target.value)}
      onKeyPress={(e) => e.key === 'Enter' && !loading && onSubmit()}
      isLoading={loading}
      disabled={isNotLoggedIn ? false : isInitLoading || isInitFetching}
      placeholder="Search..."
    />
  );
});
SearchRegular.displayName = 'SearchRegular';

export default SearchRegular;
