import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '& > div': {
      width: '100%'
    }
  },
  input: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    width: '100%',
    fontSize: 14,
    '&::-webkit-input-placeholder': {
      color: theme.palette.primary.contrastText,
      opacity: 0.6
    },
    '&::-ms-clear': {
      display: 'none'
    },

    '&[disabled]': {
      color: 'rgba(255, 255, 255, 0.6)'
    }
  },
  cssUnderline: {
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& svg': {
      color: theme.palette.secondary.main,
      fontSize: '32px'
    }
  },
  clearIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: '16px'
  },
  invisible: {
    visibility: 'hidden',
    pointerEvents: 'none'
  },
  inputLoader: {
    marginRight: 16
  }
}));
