/* eslint-disable react/prop-types */
import React, { Component, forwardRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

class DraggableScrollbars extends Component {
  state = {
    isDown: false,
    startX: undefined,
    scrollLeft: undefined
  };

  onMouseDown = (e) => {
    const startX = e.clientX;
    const scrollLeft = this.props.innerRef.current.getScrollLeft();
    this.setState({ isDown: true, startX, scrollLeft });
  };

  onMouseLeave = () => this.setState({ isDown: false });

  onMouseUp = () => this.setState({ isDown: false });

  onMouseMove = (e) => {
    if (!this.state.isDown) return;
    e.preventDefault();

    const x = e.clientX;
    const walk = (x - this.state.startX) * (this.props.scrollSpeed || 1);

    this.props.innerRef.current.scrollLeft(this.state.scrollLeft - walk);
  };

  render() {
    const { innerRef, ...restProps } = this.props;
    return (
      <Scrollbars
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        onMouseUp={this.onMouseUp}
        ref={innerRef}
        style={this.state.isDown ? { cursor: 'grab' } : null}
        {...restProps}
      >
        {this.props.children}
      </Scrollbars>
    );
  }
}

const DraggableScrollbarsWithRef = forwardRef((props, ref = 'recordings') => (
  <DraggableScrollbars innerRef={ref} {...props} />
));
DraggableScrollbarsWithRef.displayName = 'DraggableScrollbars';

export default DraggableScrollbarsWithRef;
