export const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '32px',
    lineHeight: '43px',
    fontStyle: 'italic',
    opacity: 0.5,
    textAlign: 'center'
  }
});
