import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    containerType: 'inline-size',

    // if there are 4 or more children, hide the layout and show the error
    '& $layout:has(>:nth-child(4))': {
      display: 'none',
      '& + $error': {
        display: 'block',
        '& $qtyError': {
          display: 'block'
        }
      }
    },

    // if no video element, hide the layout and show the error
    '& $layout:not(:has(video))': {
      display: 'none',
      '& + $error': {
        display: 'block',
        '& $noVideoError': {
          display: 'block'
        }
      }
    }
  },
  layout: {
    display: 'flex',
    gap: 10,

    '& > *': {
      height: 0
    },
    '& > *:has(video)': {
      height: 'fit-content'
    },

    '&:has(>:nth-child(3))': {
      '@container (width < 1600px)': {
        '& > :not(:has(video))': {
          width: '49%',
          translate: '0 0'
        },
        '& > :has(video)': {
          width: '100%',
          minWidth: '100%',
          order: -1
        },
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      },
      '@container (width < 768px)': {
        flexDirection: 'column',
        '& > :not(:has(video))': {
          width: '100%',
          minWidth: '100%',
          '&:last-child': {
            translate: '0 26px'
          }
        }
      }
    },
    '&:not(:has(>:nth-child(3)))': {
      '@container (width < 1280px)': {
        flexDirection: 'column',
        alignItems: 'center',
        '& > :has(video)': {
          order: -1
        }
      }
    },

    // video
    '& > :has(video)': {
      width: '100%',
      margin: '0 auto',

      '& :has(video):first-child': {
        width: '75%',
        margin: '0 auto',
        '@container (width < 1280px)': {
          width: '100%'
        }
      }
    },
    '&:has(>:nth-child(3)) > :has(video)': {
      width: '50%',

      '& :has(video):first-child': {
        width: '100%'
      }
    },
    '&:has(>:nth-child(2)) > :has(video)': {
      width: '75%',

      '@container (width < 1280px)': {
        width: '100%'
      }
    },

    // sidebars
    '& > :not(:has(video))': {
      width: '25%',
      minWidth: '25%',
      translate: '0 -26px',
      marginBottom: 1,

      '@container (width < 1280px)': {
        translate: '0 0',
        width: '100%'
      }
    }
  },
  error: {
    display: 'none'
  },
  qtyError: {
    display: 'none'
  },
  noVideoError: {
    display: 'none'
  }
}));
