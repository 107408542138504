import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_ERROR,
  FETCH_GROUPS_SUCCESS,
  CLEAR_GROUPS,
  FETCH_GROUP_CONTENT_REQUEST,
  FETCH_GROUP_CONTENT_ERROR,
  FETCH_GROUP_CONTENT_SUCCESS
} from '../constants/actionTypes';

import { API_GET_GROUPS, API_GET_GROUP } from '../api/apiEndpoints';

export function groupsClearData() {
  return {
    type: CLEAR_GROUPS
  };
}

export function groupsAreLoading() {
  return {
    type: FETCH_GROUPS_REQUEST
  };
}

export function groupsFetchError(error) {
  return {
    type: FETCH_GROUPS_ERROR,
    payload: error
  };
}

export function groupsFetchSuccess(groups) {
  return {
    type: FETCH_GROUPS_SUCCESS,
    payload: groups
  };
}

const fetchContentData = (id) =>
  fetch(API_GET_GROUP(id))
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .catch((error) =>
      console.error(
        `ERROR in groupContentFetchData (${id}): ${error.message || error}`
      )
    );

export function groupsFetchData(fetchContent = false) {
  return (dispatch) => {
    dispatch(groupsAreLoading());

    fetch(API_GET_GROUPS())
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json())
      .then(({ groups }) => {
        if (!fetchContent) {
          dispatch(groupsFetchSuccess(groups));
        } else {
          Promise.all(groups.map(({ id }) => fetchContentData(id))).then(
            (values) => {
              const groupsWithItems = groups.map((group, idx) => ({
                ...group,
                items: values[idx]
              }));
              dispatch(groupsFetchSuccess(groupsWithItems));
            }
          );
        }
      })
      .catch((error) => {
        const message = error.message || error;
        console.error(`ERROR in groupsFetchData: ${message}`);

        return dispatch(groupsFetchError(message));
      });
  };
}

export function groupContentIsLoading(id) {
  return {
    type: FETCH_GROUP_CONTENT_REQUEST,
    payload: {
      id
    }
  };
}

export function groupContentFetchError(id, error) {
  return {
    type: FETCH_GROUP_CONTENT_ERROR,
    payload: {
      id,
      error
    }
  };
}

export function groupContentFetchSuccess(id, items) {
  return {
    type: FETCH_GROUP_CONTENT_SUCCESS,
    payload: {
      id,
      items
    }
  };
}

export function groupContentFetchData(id) {
  return (dispatch) => {
    dispatch(groupContentIsLoading(id));
    fetchContentData(id)
      .then((response) => dispatch(groupContentFetchSuccess(id, response)))
      .catch((error) =>
        dispatch(groupContentFetchError(id, error.message || error))
      );
  };
}
