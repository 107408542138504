import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',

    margin: 0,

    fontFamily: 'Open Sans',
    color: '#fff',
    opacity: '0.6',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '28px',
    textTransform: 'uppercase',
    marginTop: -2
  }
}));
