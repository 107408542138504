/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { programGuideFetchData } from '../../actions/programGuide.action';

import { programPostRecording } from '../../actions/videoDetails.action';

import VideoPageBannerSection from '../../components/VideoPageBannerSection';
import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import VideoDetails from '../../components/VideoDetails';
import { recordingsFetchDataDB } from '../../actions/recordings.action';
import { useProgramDetails, useChannel } from '../../api/channels';
import VideoContainer from '../../components/video/VideoContainer';

import ChannelMultiScreenPage from '../ChannelMultiScreenPage';
import ProgramGuide from './ProgramGuide';

import { styles } from './ChannelPage.styles';
import VideoLayout from '../../components/video/VideoLayout';

function isCurrentlyPlaying(from, to) {
  const currentDate = moment().year(2019).month(8).date(1);
  return currentDate.isBetween(moment(from), moment(to));
}

// wrapper over the functional component to decide what page to render (single/multiple screens)
const ChannelPageWrapper = (props) => {
  return <ChannelPage {...props} />;
};

// functional component (wrapper for the class component - eventually both will be single functional component)
const ChannelPage = (props) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [show, setShow] = useState();
  const [scheduled, setScheduled] = useState(false);
  const [videoDetailsId, setVideoDetailsId] = useState('');

  const changePlayingTime = (show) => {
    setStartTime(show.startTime);
    setEndTime(show.endTime);
    setShow(show);
    setVideoDetailsId(show.programId);
  };

  const params = useParams();

  const {
    data = {},
    isLoading: videoDetailsIsLoading,
    isError: videoDetailsIsError
  } = useProgramDetails(videoDetailsId);

  const {
    data: channelData,
    isLoading,
    isError,
    error
  } = useChannel(params.id);

  if (isLoading) {
    return <Loader loaderProps={{ size: 80 }} fullHeightCentralized />;
  }

  if (isError) {
    return <ErrorMessage error={error} fullHeightCentralized />;
  }

  const isMultiscreen = channelData.type === 'multiscreen';

  if (isMultiscreen) {
    return <ChannelMultiScreenPage />;
  }

  return (
    <ChannelPageImpl
      {...props}
      id={params.id}
      videoDetails={{ data }}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      videoDetailsIsLoading={videoDetailsIsLoading}
      videoDetailsIsError={videoDetailsIsError}
      show={show}
      setShow={setShow}
      scheduled={scheduled}
      setScheduled={setScheduled}
      videoDetailsId={videoDetailsId}
      setVideoDetailsId={setVideoDetailsId}
      video={channelData}
      changePlayingTime={changePlayingTime}
    />
  );
};

// class component (eventually will become a functional component)
class ChannelPageImpl extends Component {
  componentDidMount() {
    const id = this.props.id;
    this.props.fetchProgramGuide(id);
    this.props.recordingsFetchDataDB();
  }

  componentDidUpdate() {
    const {
      programGuide,
      videoDetails,
      videoDetailsIsLoading,
      videoDetailsIsError
    } = this.props;

    if (
      programGuide?.length &&
      (!videoDetailsIsLoading || !this.props.videoDetailsId) &&
      !videoDetailsIsError &&
      !Object.keys(videoDetails.data).length
    ) {
      const currentDate = moment().year(2019).month(8).date(1);
      const program = programGuide.find((program) =>
        currentDate.isBetween(
          moment(program.startTime),
          moment(program.endTime),
          null,
          '[]'
        )
      );
      this.props.setVideoDetailsId(program.programId);
    }
  }

  scheduleRecording = (item) => {
    this.props.setScheduled(true);
    let recording = {
      id: item.id,
      channelId: this.props.match.params.id,
      programId: item.programId,
      start: item.startTime,
      end: item.endTime,
      uid: '',
      state: 'Scheduled',
      recordStart: '',
      source: '',
      details: this.props.videoDetails
    };
    this.props.postRecording(recording);
  };

  render() {
    let show = this.props.show || null;
    this.props.programGuide.forEach((program) => {
      if (isCurrentlyPlaying(program.startTime, program.endTime)) {
        if (!show) {
          show = program;
        }
      }
    });
    const { classes, video } = this.props;
    if (this.props.startTime) {
      video.src_list.src +=
        'start=' + this.props.startTime + '&end=' + this.props.endTime;
    }

    return video ? (
      <>
        <VideoPageBannerSection
          poster={video.poster}
          title={video.title}
          description={video.description}
          tagCloud={video.tagCloud}
        />
        <VideoLayout>
          <div>
            <VideoContainer videoObject={video} autoplay />
            <div className={classes.videoDetailsContainer}>
              <VideoDetails programId={this.props.videoDetailsId} show={show} />
            </div>
          </div>

          <div className={classes.programGuideContainer}>
            <ProgramGuide
              schedule={this.scheduleRecording}
              setShow={this.props.setShow}
              id={this.props.match.params.id}
              changeTime={this.props.changePlayingTime}
            />
          </div>
        </VideoLayout>
      </>
    ) : null;
  }
}

const mapStateToProps = ({ programGuide, recordings }) => ({
  programGuide: programGuide.data,
  recordings: recordings.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgramGuide: (id) => dispatch(programGuideFetchData(id)),
  postRecording: (body) => dispatch(programPostRecording(body)),
  recordingsFetchDataDB: () => dispatch(recordingsFetchDataDB())
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ChannelPageWrapper)
);
