import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { UseEmblaCarouselType } from 'embla-carousel-react';

/** Please check embla carousel docs this hook might not be needed anymore, as at the moment of its creation (2024.06.04) the wheel feature of the library was under development */
export const useMouseWheel = (
  emblaApi: UseEmblaCarouselType[1],
  setActiveCarouselIndex: (index: number) => void
) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wheelListener = debounce((e) => {
      e.preventDefault();
      if (!emblaApi) return;

      const currentSlide = emblaApi.selectedScrollSnap();

      const goTo = (index: number) => {
        emblaApi.scrollTo(index);
        setActiveCarouselIndex(index);
      };

      if (e.deltaY < 0) {
        const newIndex = currentSlide - 1;
        goTo(newIndex);
      } else {
        const newIndex = currentSlide + 1;
        goTo(newIndex);
      }
    }, 0);

    const wrapper = wrapperRef.current;

    wrapper?.addEventListener('wheel', wheelListener, { passive: true });
    return () => wrapper?.removeEventListener('wheel', wheelListener);
  }, [emblaApi, setActiveCarouselIndex]);

  return wrapperRef;
};
