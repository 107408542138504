/* eslint-disable react/prop-types */
import React, { Component, createRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  MuiThemeProvider,
  IconButton,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

import {
  programDeleteRecording,
  programFetchRecording,
  programPostRecording
} from '../../actions/videoDetails.action';
import { recordingsFetchDataDB } from '../../actions/recordings.action';
import { CalendarIcon } from '../../assets/icons';
// TODO: use native scroll instead of custom
import DraggableScrollbars from '../../components/DraggableScrollbars';
import ProgramDetailsDialog from '../../components/ProgramDetailsDialog';
import VideoDetails from '../../components/VideoDetails';
import Tooltip from '../../components/Tooltip';
import ShowTile from '../../components/channelsPage/ShowTile';
import { useEpg } from '../../api/channels';
import { getImageUrl } from '../../shared';

import { useStyles, calendarTheme } from './ChannelsPage.styles';

const ChannelsPage = (props) => {
  const [videoDetailsId, setVideoDetailsId] = useState('');

  const { data = [] } = useEpg();
  const styles = useStyles();
  return (
    <ChannelsPageImpl
      {...props}
      styles={styles}
      data={data}
      videoDetailsId={videoDetailsId}
      setVideoDetailsId={setVideoDetailsId}
    />
  );
};

class ChannelsPageImpl extends Component {
  pixelsPerHour = 142;
  pixelsPerMs = this.pixelsPerHour / 3600 / 1000;
  scrollbars = createRef();

  state = {
    selectedDate: moment().year(2019).date(1).month(8),
    timelineStart: moment()
      .year(2019)
      .date(1)
      .month(8)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0),
    modalOpen: false,
    scheduled: false,
    recordHover: false,
    recording: { state: 'Schedule' }
  };

  componentDidMount() {
    this.props.recordingsFetchDataDB();
    this.scrollbars.current.scrollLeft(
      this.pixelsPerHour * this.state.selectedDate.hour()
    );
  }

  handleModalOpen = (id, show, channelId) => {
    this.props.setVideoDetailsId(id);
    this.props.fetchRecording(show.id);
    const recording = this.props.recordings.find(
      (record) => record.id === show.id
    );

    this.setState({
      modalOpen: true,
      show: { ...show, channelId },
      recorded: !!recording,
      recording: recording || { state: 'Schedule' }
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  scheduleRecording = () => {
    this.setState({ scheduled: true });
    const {
      id,
      channelId,
      programId,
      startTime: start,
      endTime: end
    } = this.state.show;

    this.props.postRecording({
      id,
      channelId,
      programId,
      start,
      end,
      uid: '',
      state: 'Scheduled',
      recordStart: '',
      source: '',
      details: this.props.details.data
    });
  };

  dismissRecording = () => {
    this.setState({ scheduled: false });
    this.props.deleteRecording(this.state.show && this.state.show.id);
  };

  handleMouseHover = () =>
    this.setState({ recordHover: !this.state.recordHover });

  getShift = (showStart) => {
    if (!showStart) return 0;

    const showStartMs = moment(showStart).valueOf();
    const thirtyMinutesInMS = 1800000;

    const shift =
      (showStartMs -
        ((this.state && this.state.timelineStart) || 0) +
        thirtyMinutesInMS) *
      this.pixelsPerMs;
    return `${shift}px`;
  };

  getCalendarFormat() {
    const today = moment().date(1).month(8).year(2019);
    return this.state.selectedDate.isSame(today, 'day')
      ? '[Today]'
      : 'DD/MM/YYYY';
  }

  getProgramHeight() {
    const headerElement = document.getElementsByTagName('header');
    const headerHeight = headerElement.length
      ? headerElement[0].clientHeight
      : 0;

    return `calc(100vh - ${headerHeight + 59}px)`;
  }

  renderTimeline(
    start = this.state.timelineStart,
    end = start + 3600 * 26 * 1000,
    step = 3600 * 1000
  ) {
    const { styles } = this.props;
    const timeline = [];
    for (let i = start; i <= end; i += step) {
      const timeFormat =
        moment(i).date() === start.date() ? 'HH:mm' : 'DD.MM  HH:mm';
      const block = (
        <div
          key={i}
          style={{ width: this.pixelsPerHour }}
          className={styles.timelineBlock}
        >
          <Typography
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              color: '#868789',
              textAlign: 'center',
              fontSize: '11px',
              fontFamily: 'Open Sans, sans-serif'
            }}
          >
            {moment(i).format(timeFormat)}
          </Typography>
        </div>
      );
      timeline.push(block);
    }

    return timeline;
  }

  render() {
    const { styles } = this.props;
    return (
      <>
        <span style={{ color: 'white' }}>{this.props.videoDetailsId}</span>
        <Grid container>
          <Grid item xs={2}>
            <MuiThemeProvider theme={calendarTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableOpenOnEnter
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  format={this.getCalendarFormat()}
                  value={this.state.selectedDate}
                  onChange={() => {}}
                />
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
            <IconButton
              style={{ padding: '12px 0' }}
              className={styles.iconSwitch}
            >
              <SvgIcon viewBox="0 0 22 40" style={{ fontSize: '17px' }}>
                <path
                  d="M2.34307257,40 C1.76248822,40 1.14043355,39.789391 0.684260132,39.3260513 C-0.228086711,38.3993718 -0.228086711,36.9251091 0.684260132,35.9984296 L16.6896552,19.9921481 L0.684260132,4.02798835 C-0.228086711,3.10130889 -0.228086711,1.62704612 0.684260132,0.700366661 C1.59660697,-0.226312796 3.04806786,-0.226312796 3.9604147,0.658244867 L21.336475,18.3072763 C21.7926484,18.7706161 22,19.3603212 22,19.9921481 C22,20.623975 21.7511781,21.2136801 21.336475,21.6770198 L3.9604147,39.3260513 C3.50424128,39.789391 2.92365693,40 2.34307257,40 Z"
                  id="Path"
                  fill="#D8D8D8"
                  transform="translate(11.000000, 20.000000) scale(-1, 1) translate(-11.000000, -20.000000) "
                ></path>
              </SvgIcon>
            </IconButton>
            <IconButton
              style={{ padding: '12px 0' }}
              className={styles.iconSwitch}
            >
              <SvgIcon viewBox="0 0 22 40" style={{ fontSize: '17px' }}>
                <path
                  d="M2.34307257,40 C1.76248822,40 1.14043355,39.789391 0.684260132,39.3260513 C-0.228086711,38.3993718 -0.228086711,36.9251091 0.684260132,35.9984296 L16.6896552,19.9921481 L0.684260132,4.02798835 C-0.228086711,3.10130889 -0.228086711,1.62704612 0.684260132,0.700366661 C1.59660697,-0.226312796 3.04806786,-0.226312796 3.9604147,0.658244867 L21.336475,18.3072763 C21.7926484,18.7706161 22,19.3603212 22,19.9921481 C22,20.623975 21.7511781,21.2136801 21.336475,21.6770198 L3.9604147,39.3260513 C3.50424128,39.789391 2.92365693,40 2.34307257,40 Z"
                  id="Path"
                  fill="#D8D8D8"
                ></path>
              </SvgIcon>
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography
              style={{
                color: '#F37037',
                fontSize: '32px',
                textAlign: 'center',
                fontFamily: 'Open Sans, sans-serif'
              }}
            >
              {moment()
                .date(new Date().getDate())
                .month(new Date().getMonth())
                .format('MMM D, HH:mm')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={styles.channelColumn}
            style={{ height: this.getProgramHeight() }}
          >
            <Grid item>
              <Typography
                style={{
                  color: '#868789',
                  fontSize: '11px',
                  marginLeft: '20px',
                  height: '20px',
                  fontFamily: 'Open Sans, sans-serif'
                }}
              >
                Channels
              </Typography>
            </Grid>
            {this.props.data?.map(
              ({ id, details: { title, thumbnail } }, idx) => (
                <Grid item className={styles.channel} key={idx}>
                  <div className={styles.channelLinkWrapper}>
                    <Tooltip title={title}>
                      <Link
                        className={styles.channelLink}
                        to={`/channels/${id}`}
                      >
                        <img
                          style={{ height: '100%' }}
                          src={getImageUrl(thumbnail)}
                          alt=""
                        />
                      </Link>
                    </Tooltip>
                  </div>
                </Grid>
              )
            )}
          </Grid>
          <Grid
            item
            xs={10}
            className={styles.programColumn}
            id="ProgramColumn"
          >
            <DraggableScrollbars
              ref={this.scrollbars}
              renderThumbHorizontal={(props) => (
                <div {...props} className={styles.thumbHorizontal} />
              )}
            >
              <div
                className={styles.currentTimeVerticalLine}
                style={{ left: this.getShift(this.state.selectedDate) }}
              />
              <Grid item xs={12} className={styles.timeline}>
                {this.renderTimeline()}
              </Grid>
              {this.props.data &&
                this.props.data.map((channel, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={12}
                    className={styles.channelProgram}
                  >
                    {channel.epg.events.map((show, idx) => (
                      <ShowTile
                        key={idx}
                        show={show}
                        channelId={channel.id}
                        getShift={this.getShift}
                        onTileClick={this.handleModalOpen}
                        pixelsPerMs={this.pixelsPerMs}
                      />
                    ))}
                  </Grid>
                ))}
            </DraggableScrollbars>
          </Grid>
          <ProgramDetailsDialog
            isOpen={this.state.modalOpen}
            handleClose={this.handleModalClose}
          >
            <VideoDetails
              programId="0578d36a-227f-4d6c-94ab-e86791002b27"
              showNotFound
              show={this.state.show}
              schedule={this.scheduleRecording}
              dismiss={this.dismissRecording}
              handleHover={this.handleMouseHover}
              recordText={this.state.recordHover}
              showRecordButton={this.state.scheduled}
              recorded={this.state.recorded}
              position={this.state.recording.state}
            />
          </ProgramDetailsDialog>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ recordings }) => ({
  recordings: recordings.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecording: (id) => dispatch(programFetchRecording(id)),
  postRecording: (body) => dispatch(programPostRecording(body)),
  deleteRecording: (id) => dispatch(programDeleteRecording(id)),
  recordingsFetchDataDB: () => dispatch(recordingsFetchDataDB())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsPage);
