import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import { useStyles } from './VideoLoader.styles';

const VideoLoader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const styles = useStyles();

  return <div className={clsx(styles.loader, className)} {...props} />;
};
export default VideoLoader;
