import { throttle } from 'lodash';
import { Children, ReactNode, useEffect, useRef, useState } from 'react';

/** Hooks that does resize observation on video block height and set the same height to
 * columns that are not video blocks (it is needed for scrollbars to work properly)
 */
export const useColumnsHeight = (children: ReactNode) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const childrenQty = Children.toArray(children).length;
  const jsChildren = Array.from(
    wrapperRef.current?.querySelectorAll('& > *') ?? []
  );
  const [childrenMatched, setChildrenMatched] = useState(false);
  const [, forceUpdate] = useState(0);
  const isInit = useRef(false);
  useEffect(() => {
    if (isInit.current) return;
    const interval = setInterval(() => {
      forceUpdate((prev) => prev + 1);
      if (jsChildren.length === childrenQty) {
        setChildrenMatched(true);
        clearInterval(interval);
      }
    }, 0);
    return () => clearInterval(interval);
  }, [jsChildren.length, childrenQty]);

  const [shouldUpdate2ndEffect, forceUpdate2ndEffect] = useState(0);
  useEffect(() => {
    if (!childrenMatched) return;

    const childrenVideo = wrapperRef.current?.querySelector('& > *:has(video)');
    const nonVideoChildren = Array.from(
      wrapperRef.current?.querySelectorAll('& > *') ?? []
    ).filter((elem) => elem !== childrenVideo);
    const wrapperElem = wrapperRef.current;

    // handle very rare case when due to async rendering nonVideoChildren might be empty but in reality there are children
    if (nonVideoChildren.length === 0 && childrenQty > 1) {
      const timer = setTimeout(() => {
        forceUpdate2ndEffect((prev) => prev + 1);
      }, 100);
      return () => clearTimeout(timer);
    }

    if (!childrenVideo || !wrapperElem || !nonVideoChildren.length) return;

    const observer = throttle(([entry]) => {
      const newBounds = Array.isArray(entry)
        ? entry[0].contentRect
        : entry!.contentRect;

      nonVideoChildren.forEach(
        (elem) => ((elem as HTMLElement).style.height = `${newBounds.height}px`)
      );
    }, 0);

    const resizeObserver = new ResizeObserver(observer);
    resizeObserver.observe(childrenVideo);

    return () => resizeObserver.disconnect();
  }, [childrenQty, childrenMatched, shouldUpdate2ndEffect]);

  return wrapperRef;
};
