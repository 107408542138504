/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { Close as CloseIcon } from '@material-ui/icons';
import * as Yup from 'yup';

import { styles } from '../../../components/ProgramDetailsDialog/ProgramDetailsDialog.styles';
import { TranscodingAPI } from '../../../api/transcoding';

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  flow: Yup.string().required('Required'),
  input: Yup.string().required('Required'),
  output: Yup.string().required('Required'),
  profiles: Yup.array().min(1, 'Required')
});

const WorkflowModal = ({
  selectedItem,
  handleClose,
  onSave,
  classes: {
    modalLight,
    modalTitle,
    modalTitleText,
    modalCloseButton,
    modalCloseIcon,
    modalContent
  }
}) => {
  const isEdit = Object.keys(selectedItem || {}).length > 0;

  // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
  const { data = { profiles: [] } } = useQuery(
    'profiles',
    () => TranscodingAPI.getProfiles(),
    { refetchOnWindowFocus: false, staleTime: 60000 }
  );
  const profilesList = data.profiles.map((_) => _.name);

  const item = useMemo(() => {
    const _ = selectedItem;
    if (_.flow === 'hls-packaging') {
      _.profiles = [_.profile, ...(_.additionalProfiles || [])];
    }
    if (_.flow === 'transcode') {
      _.profiles = [_.profile];
    }
    return _;
  }, [selectedItem]);

  const handleSave = async (values) => {
    const _ = { ...values };
    _.profile = _.profiles[0];
    if (_.flow === 'hls-packaging') {
      _.additionalProfiles = _.profiles.slice(1);
    }
    delete _.profiles;

    if (selectedItem._id) {
      onSave(_, selectedItem._id);
    } else {
      onSave(_);
    }
  };

  const formik = useFormik({
    initialValues: item,
    validationSchema: schema,
    onSubmit: null
  });

  return (
    <Dialog
      open={!!selectedItem}
      onClose={handleClose}
      classes={{ paper: modalLight }}
    >
      <DialogTitle classes={{ root: modalTitle }}>
        <Typography className={modalTitleText}>
          {isEdit ? 'Edit Workflow' : 'Add new Workflow'}
        </Typography>
        <IconButton
          disableRipple
          onClick={handleClose}
          className={modalCloseButton}
        >
          <CloseIcon className={modalCloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: modalContent }}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
        >
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FormControl fullWidth>
            <InputLabel id="flow">Type</InputLabel>
            <Select
              labelId="flow"
              name="flow"
              value={formik.values.flow}
              onChange={formik.handleChange}
              error={formik.touched.flow && Boolean(formik.errors.flow)}
              helperText={formik.touched.flow && formik.errors.flow}
            >
              <MenuItem value={'transcode'}>transcode</MenuItem>
              <MenuItem value={'hls-packaging'}>hls-packaging</MenuItem>
            </Select>
          </FormControl>
          {formik.values.flow && (
            <FormControl fullWidth>
              <InputLabel id="profiles">Profile(s)</InputLabel>
              <Select
                multiple={formik.values.flow === 'hls-packaging'}
                labelId="profiles"
                id="profiles"
                value={formik.values.profiles || []}
                onChange={(_) =>
                  formik.setFieldValue(
                    'profiles',
                    Array.isArray(_.target.value)
                      ? _.target.value
                      : [_.target.value]
                  )
                }
                error={
                  formik.touched.profiles && Boolean(formik.errors.profiles)
                }
                helperText={formik.touched.profiles && formik.errors.profiles}
              >
                {profilesList.map((p) => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth>
            <div style={{ display: 'flex', gap: '10px' }}>
              <TextField
                fullWidth
                name="partitionByParts"
                label="partition by parts"
                value={formik.values.partitionByParts}
                onChange={formik.handleChange}
                error={
                  formik.touched.partitionByParts &&
                  Boolean(formik.errors.partitionByParts)
                }
                helperText={
                  formik.touched.partitionByParts &&
                  formik.errors.partitionByParts
                }
              />
              <TextField
                fullWidth
                name="partitionByTime"
                label="partition by time"
                value={formik.values.partitionByTime}
                onChange={formik.handleChange}
                error={
                  formik.touched.partitionByTime &&
                  Boolean(formik.errors.partitionByTime)
                }
                helperText={
                  formik.touched.partitionByTime &&
                  formik.errors.partitionByTime
                }
              />
            </div>
          </FormControl>
          <TextField
            fullWidth
            id="input"
            name="input"
            label="Input"
            value={formik.values.input}
            onChange={formik.handleChange}
            error={formik.touched.input && Boolean(formik.errors.input)}
            helperText={formik.touched.input && formik.errors.input}
          />
          <TextField
            fullWidth
            id="output"
            name="output"
            label="Output"
            value={formik.values.output}
            onChange={formik.handleChange}
            error={formik.touched.output && Boolean(formik.errors.output)}
            helperText={formik.touched.output && formik.errors.output}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!formik.isValid || !formik.dirty}
          variant="contained"
          color="secondary"
          onClick={() => handleSave(formik.values)}
        >
          Save
        </Button>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(WorkflowModal);
