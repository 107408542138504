import { baseUrl } from '../apiEndpoints';

export const getVideoUrl = (id: string) => `${baseUrl}/api/${id}/metadata.json`;

export const getStoryboardUrl = (id: string) =>
  `${baseUrl}/api/${id}/storyboard.json`;

export const getAdsUrl = (id: string) => `${baseUrl}/api/${id}/ads.json`;

export const getBrandsUrl = (id: string) => `${baseUrl}/api/${id}/brands.json`;
