import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Hls from 'hls.js';

function getCustomLoader(src: string, sessionToken?: string) {
  return class extends Hls.DefaultConfig.loader {
    load(context: any, config: any, callbacks: any) {
      if (context.url.startsWith('http')) {
        const srcUrl = new URL(src);
        const allSrcKeys = Array.from(srcUrl.searchParams.keys());

        const contextUrl = new URL(context.url);
        allSrcKeys.forEach((key) => {
          const value = srcUrl.searchParams.get(key) ?? '';
          contextUrl.searchParams.set(key, value);
        });

        if (sessionToken) {
          contextUrl.searchParams.set('ads.session', sessionToken);
        }

        context.url = contextUrl.toString();
      }
      super.load(context, config, callbacks);
    }
  };
}

export const useHls = (
  video: HTMLVideoElement | null,
  src: string,
  isHls?: boolean,
  sessionToken?: string
) => {
  if (!src.startsWith('http')) {
    throw new Error('Invalid src prop. Must be a valid URL. Got: ' + src);
  }

  const [levels, setLevels] = useState<{ id: number; label: string }[]>([]);
  const [activeLevel, _setActiveLevel] = useState(-1);
  const [isLive, setIsLive] = useState(false);
  const hlsRef = useRef<Hls | null>(null);
  const [isAdvertisement, setIsAdvertisement] = useState(false);

  const setActiveLevel = useCallback((level: number) => {
    _setActiveLevel(level);
    if (hlsRef.current) hlsRef.current.currentLevel = level;
  }, []);

  const [, forceUpdate] = useState({});
  useEffect(() => {
    if (video === null) return forceUpdate({});
    if (Hls.isSupported() && video && isHls) {
      const hls = new Hls({
        loader: getCustomLoader(src, sessionToken)
      });
      hlsRef.current = hls;

      hls.loadSource(src);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        if (data.levels.length > 1) {
          const newLevels = [
            {
              id: -1,
              label: 'Auto'
            }
          ].concat(
            data.levels.map((level, index) => ({
              id: index,
              label: level.height
                ? `${level.height}p`
                : level.width
                  ? `${Math.round((level.width * 9) / 16)}p`
                  : level.bitrate
                    ? `${parseInt(String(level.bitrate / 1000))}kbps`
                    : `Level ${index}`
            }))
          );
          setLevels(newLevels);
        }
      });

      hls.on(Hls.Events.FRAG_CHANGED, (event, data) => {
        setIsAdvertisement(data.frag.url.includes('mediatailor'));
      });

      hls.on(Hls.Events.LEVEL_LOADED, (e, data) => {
        setIsLive(data.details.live);
      });

      hls.on(Hls.Events.ERROR, (e, data) => {
        console.error('HLS error:', data);
      });

      return () => hls.destroy();
    }
  }, [src, video, isHls, sessionToken]);

  return useMemo(
    () => ({ isAdvertisement, activeLevel, setActiveLevel, levels, isLive }),
    [isAdvertisement, activeLevel, setActiveLevel, levels, isLive]
  );
};
