export const styles = () => ({
  actionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  actionBlock: {
    cursor: 'pointer',
    margin: '0 35px',
    display: 'flex',
    alignItems: 'center',
    '&:hover $actionText': {
      color: '#fff',
      transition: '0.4s ease-out'
    },
    '&:hover $recordButton': {
      backgroundColor: '#ff443a',
      boxShadow: '0 0 0px 1px #ff443a',
      transition: '0.4s ease-out'
    },
    '&:hover $recordText': {
      color: '#ff443a',
      transition: '0.4s ease-out'
    },
    '&:hover $iconButton': {
      color: '#ff443a',
      transition: '0.4s ease-out'
    },
    '&:hover $notifyButton': {
      color: '#fff',
      transition: '0.4s ease-out'
    }
  },
  iconButton: {
    padding: 0,
    transition: '0.4s ease-out'
  },
  notifyButton: {
    display: 'inline-block',
    color: '#D8D8D8'
  },
  recordButton: {
    display: 'inline-block',
    float: 'left',
    backgroundColor: '#D8D8D8',
    border: '2px solid #1F1F1F',
    boxShadow: '0 0 0px 1px #D8D8D8',
    height: '20px',
    width: '20px',
    transition: '0.4s ease-out'
  },
  actionText: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#bfbfbf',
    fontFamily: 'Open Sans',
    transition: '0.4s ease-out'
  },
  recordText: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#bfbfbf',
    fontFamily: 'Open Sans',
    transition: '0.4s ease-out'
  },
  '#left_block': {
    textAlign: 'right'
  }
});
