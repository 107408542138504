export const styles = () => ({
  date: {
    color: '#fff',
    borderRadius: 2,
    fontFamily: 'Open Sans',
    fontSize: 14,
    letterSpacing: -0.1,
    padding: '3px 11px'
  }
});
