import { useEffect, useState } from 'react';

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

export const useVoice = (
  onVoiceSearch?: (text: string) => void,
  onEnd?: () => void
) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    if (!SpeechRecognition) return;

    let speechTimer: ReturnType<typeof setTimeout> | null = null;
    let allText = '';

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onspeechstart = () => setIsSpeaking(true);
    recognition.onspeechend = () => setIsSpeaking(false);

    recognition.onerror = (event: any) => {
      console.error('Speech recognition error', event.error);
    };

    recognition.onresult = (event: any) => {
      speechTimer && clearTimeout(speechTimer);

      const last = event.results.length - 1;
      const text = event.results[last][0].transcript.trim();

      if (event.results[last].isFinal) {
        allText += ' ' + text;
        setCurrentText((prev) => prev + ' ' + text);
      }

      speechTimer = setTimeout(() => {
        onEnd?.();
        onVoiceSearch?.(allText);
        recognition.stop();
      }, 2200);
    };

    recognition.start();

    return () => void recognition.stop();
  }, [onVoiceSearch, onEnd]);

  return {
    isSpeaking,
    currentText,
    SpeechRecognition
  };
};
