import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchChatContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32
  },
  chatMessage: {
    display: 'flex',
    fontSize: 14,
    lineHeight: '20px',
    gap: 12
  },
  chatMessageReverse: {
    flexDirection: 'row-reverse',
    gap: 8
  },
  author: {
    fontWeight: 'bold'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 52,
    height: 52,
    borderRadius: '50%'
  },
  aiIcon: {
    background: theme.palette.secondary.main,

    '& > svg': {
      width: 35,
      height: 35
    },

    '& > svg > path': {
      fill: theme.palette.secondary.main
    }
  },
  userIcon: {
    background: theme.palette.secondary.contrastText,

    '& > svg': {
      width: 28,
      height: 28
    }
  },
  userMsg: {
    color: 'rgba(255, 255, 255, 0.8)',
    padding: '10px 20px',
    background: '#373737',
    borderRadius: 30,

    display: 'flex',
    alignItems: 'center'
  },
  msgError: {
    fontStyle: 'italic'
  },
  dots: {
    position: 'relative',
    width: 8,
    height: 8,
    borderRadius: ' 5px',
    backgroundColor: ' #f37037',
    color: '#f37037',
    animation: '$load 1s infinite linear alternate',
    animationDelay: ' 0.5s',

    translate: '18px 23px',

    '&::before, &::after': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      top: 0
    },

    '&::before': {
      left: '-13px',
      width: 8,
      height: 8,
      borderRadius: '5px',
      backgroundColor: '#f37037',
      color: '#f37037',
      animation: '$load 1s infinite alternate',
      animationDelay: '0s'
    },

    '&::after': {
      left: '13px',
      width: 8,
      height: 8,
      borderRadius: '5px',
      backgroundColor: '#f37037',
      color: '#f37037',
      animation: '$load 1s infinite alternate',
      animationDelay: '1s'
    }
  },
  '@keyframes load': {
    '0%': {
      backgroundColor: '#red'
    },
    '50%': {
      backgroundColor: '#f3703722'
    },
    '100%': {
      backgroundColor: '#f3703722'
    }
  }
}));
