import { request } from '../request';

import { API_AI_INIT, API_AI_SEARCH } from './searchAiUrls';
import { AiSearchInit, AiSearch, AiSearchData } from './searchAiTypes';

export const initAiSearch = () =>
  request<AiSearchInit>(API_AI_INIT, { method: 'POST' });

export const searchAi = ({ threadId, query }: AiSearchData) =>
  request<AiSearch>(API_AI_SEARCH(threadId), { body: { query } });
