import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    aspectRatio: '16 / 9',
    width: '100%'
  },
  player: {
    marginTop: '-7vw'
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, #000, #000000a8 20%, transparent 40%, transparent 60%, #000000a8 80%, #000)`,
    zIndex: 2
  },
  videoOverlayContent: {
    position: 'absolute',
    bottom: '250px',
    left: '60px',
    right: '100px',
    opacity: 1,
    transition: 'opacity 1s'
  },
  videoOverlayText: {
    width: '75%',
    color: '#fff'
  },
  videoOverlayTitle: {
    fontSize: '42px',
    fontFamily: 'Open Sans',
    textShadow: '2px 2px 0px #000',
    margin: 0,
    width: 'fit-content'
  },
  videoOverlayDescription: {
    fontSize: '24px',
    fontFamily: 'Open Sans',
    textShadow: '2px 2px 0px #000',
    marginBottom: 0,
    lineHeight: '32px',
    transition: 'display 1s'
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px'
  },
  link: {
    display: 'block',
    height: 'fit-content',
    color: 'white',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.secondary.main}`
    }
  },
  button: {
    lineHeight: 'inherit',
    textTransform: 'none',
    backgroundColor: '#696969c4 !important',
    '&:hover': {
      backgroundColor: 'rgb(170, 78, 38) !important'
    },
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.secondary.main}`
    }
  },
  buttonLabel: {
    display: 'flex',
    fontSize: '20px',
    fontFamily: 'Open Sans'
  }
}));
