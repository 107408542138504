import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';

import { NAV_LINKS } from './constants/navigationLinks';
import { ADMIN_LINKS } from './constants/adminLinks';

import { useStyles } from './Navigation.styles';

const getBasePathname = (pathname: string) => {
  const sliceIndex = pathname.indexOf('/', 1);
  return sliceIndex > 0 ? pathname.slice(0, sliceIndex) : pathname;
};

const Navigation = () => {
  const styles = useStyles();
  const { pathname } = useLocation();

  const basePathname = getBasePathname(pathname);
  const links = basePathname === '/admin' ? ADMIN_LINKS : NAV_LINKS;
  const path = basePathname === '/admin' ? pathname : basePathname;
  const activeTab = links.find(
    (link) => link.path === path || path.startsWith(link.path)
  );

  return (
    <Tabs
      centered
      value={activeTab ? activeTab.value : false}
      indicatorColor="secondary"
      className={styles.tabs}
    >
      {links.map((link) => (
        <Tab
          disableRipple
          key={link.value}
          className={styles.tab}
          label={link.label}
          value={link.value}
          // @ts-ignore
          component={Link}
          to={link.path}
        />
      ))}
    </Tabs>
  );
};

export default Navigation;
