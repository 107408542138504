import React, { forwardRef } from 'react';
import { InputProps } from '@material-ui/core';
import { useShallow } from 'zustand/react/shallow';

import { useSearchStore } from '../../../../stores';
import { useAiChat } from '../../../../hooks';
import { useSearchVideos } from '../../../../api/search';

import HeadInput from '../../HeadInput';

const SearchAI = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    searchAiStr,
    setSearchAiStr,
    isMutating,
    searchQueryStr,
    setChatOpen,
    setIsSearchOpen
  } = useSearchStore(
    useShallow((state) => ({
      searchAiStr: state.searchAiStr,
      setSearchAiStr: state.setSearchAiStr,
      isMutating: state.isMutating,
      searchQueryStr: state.searchQueryStr,
      setChatOpen: state.setChatOpen,
      setIsSearchOpen: state.setIsSearchOpen
    }))
  );

  const {
    onAiSearch,
    initAiData: { isLoading: isInitLoading, isFetching: isInitFetching }
  } = useAiChat();
  const { isLoading: isRegularLoading, isFetching: isRegularFetching } =
    useSearchVideos(searchQueryStr ?? '');
  const initLoading = isInitLoading || isInitFetching;
  const loading =
    initLoading || isMutating || isRegularLoading || isRegularFetching;

  const onSubmit = () => {
    setChatOpen(true);
    setIsSearchOpen(false);
    onAiSearch(searchAiStr);
    setSearchAiStr('');
  };

  return (
    <HeadInput
      {...props}
      ref={ref}
      value={searchAiStr}
      onChange={(e) => setSearchAiStr(e.target.value)}
      onKeyPress={(e) => e.key === 'Enter' && !loading && onSubmit()}
      isLoading={loading}
      disabled={initLoading}
      placeholder="Talk to Assistant..."
    />
  );
});
SearchAI.displayName = 'SearchAI';

export default SearchAI;
