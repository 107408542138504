import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    backgroundColor: '#414042',
    borderRadius: '15px',
    height: 35,
    width: 'fit-content'
  }
}));
