import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { IconButton, CircularProgress } from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ViewHeadline as ViewHeadlineIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';

import Tooltip from '../../Tooltip';
import MatchIconPng from '../../../assets/images/match_icon_white.png';
import { styles } from './VideoCardActionsCMS.styles';

const propTypes = {
  videoId: string.isRequired,
  deleteVideo: shape({
    id: string,
    deleting: bool
  }).isRequired,
  deleteVideoFn: func,
  showVideoLogsModalFn: func,
  onEditVideoFn: func,
  classes: object.isRequired
};

const defaultProps = {
  deleteVideoFn: () => {},
  showVideoLogsModalFn: () => {},
  onEditVideoFn: () => {}
};

const VideoCardActionsCMS = ({
  videoId,
  deleteVideo,
  deleteVideoFn,
  showVideoLogsModalFn,
  onEditVideoFn,
  classes,
  // eslint-disable-next-line react/prop-types
  location: { pathname }
}) => (
  <>
    <Link
      to={`${pathname}/matchings/${videoId}`}
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip title="Show Video Matches">
        <IconButton
          className={classes.actionButton}
          aria-label="Show Video Matches"
        >
          <img src={MatchIconPng} width="18.5" alt="" />
        </IconButton>
      </Tooltip>
    </Link>
    <Tooltip title="Show Progress Log">
      <IconButton
        className={classes.actionButton}
        aria-label="Show Progress Log"
        onClick={showVideoLogsModalFn}
      >
        <ViewHeadlineIcon fontSize="small" />
      </IconButton>
    </Tooltip>
    <Tooltip title="Edit Video">
      <IconButton
        className={classes.actionButton}
        aria-label="Edit Video"
        onClick={onEditVideoFn}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Tooltip>
    {deleteVideo.deleting && deleteVideo.id === videoId ? (
      <div className={classes.actionLoader}>
        <CircularProgress
          className={classes.progress}
          variant="indeterminate"
          size={20}
          thickness={2}
        />
      </div>
    ) : (
      <Tooltip title="Delete Video">
        <IconButton
          className={classes.actionButton}
          aria-label="Delete Video"
          onClick={deleteVideoFn}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    )}
  </>
);

VideoCardActionsCMS.propTypes = propTypes;
VideoCardActionsCMS.defaultProps = defaultProps;

export default withStyles(styles)(withRouter(VideoCardActionsCMS));
