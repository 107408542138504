import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Editor from '@monaco-editor/react';
import { useQueries, useQuery } from '@tanstack/react-query';

import { styles } from '../TranscodingPage.styles';
import { TranscodingAPI } from '../../../api/transcoding';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const RuntimeTab = () => {
  const classes = useStyles();

  const { data: jobIds = [] } = useQuery({
    queryKey: ['jobs'],
    queryFn: TranscodingAPI.getJobIds,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    cacheTime: 60000,
    refetchInterval: 60000
  });
  const queries = useQueries(
    jobIds.map((id) => {
      return {
        queryKey: ['user', id],
        queryFn: () => TranscodingAPI.getJobById(id),
        refetchInterval: 30000
      };
    })
  );
  const jobs = queries
    .map((q) => q.data)
    .filter((_) => !!_)
    .reverse();
  const [selectedJob, setSelectedJob] = useState();
  const [selectedObj, setSelectedObj] = useState();
  const handlerSetSelectedJob = (_) => {
    setSelectedObj(_);
    setSelectedJob(_);
  };

  return (
    <div>
      <div>
        <div>Workflow instances</div>
        <TableContainer
          component={Paper}
          style={{
            height: 'calc(33vh - 78px)',
            maxHeight: 'calc(33vh - 75px)'
          }}
        >
          <Table
            className={classes.table}
            stickyHeader
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Flow</TableCell>
                <TableCell>Input</TableCell>
                <TableCell>Output</TableCell>
                <TableCell>Profiles</TableCell>
                <TableCell>partitionByTime</TableCell>
                <TableCell>status</TableCell>
                <TableCell>progress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((row) => (
                <TableRow
                  hover
                  selected={row === selectedJob}
                  key={row._id}
                  onClick={() => handlerSetSelectedJob(row)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell>{row.parameters.flow}</TableCell>
                  <TableCell>{row.parameters.input}</TableCell>
                  <TableCell>{row.parameters.output}</TableCell>
                  <TableCell>{row.parameters.profile}</TableCell>
                  <TableCell>{row.parameters.partitionByTime}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.progress}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <div>Tasks</div>
        <TableContainer
          component={Paper}
          style={{
            height: 'calc(33vh - 78px)',
            maxHeight: 'calc(33vh - 78px)'
          }}
        >
          <Table
            className={classes.table}
            stickyHeader
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Flow</TableCell>
                <TableCell>Input</TableCell>
                <TableCell>Output</TableCell>
                <TableCell>Profiles</TableCell>
                <TableCell>partitionByTime</TableCell>
                <TableCell>status</TableCell>
                <TableCell>progress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedJob &&
                selectedJob.tasks.map((row) => (
                  <TableRow
                    key={row._id}
                    hover
                    selected={row === selectedObj}
                    onClick={() => setSelectedObj(row)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell component="th" scope="row">
                      {row._id}
                    </TableCell>
                    <TableCell>{row.parameters.flow}</TableCell>
                    <TableCell>{row.parameters.input}</TableCell>
                    <TableCell>{row.parameters.output}</TableCell>
                    <TableCell>{row.parameters.profile}</TableCell>
                    <TableCell>{row.parameters.partitionByTime}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.progress}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {selectedObj && (
        <div style={{ height: '200px', maxHeight: 'calc(33vh - 78px)' }}>
          <div>Object</div>
          <Editor
            value={JSON.stringify(selectedObj, null, 2)}
            defaultValue=""
            defaultLanguage="json"
            language="json"
            options={{ readOnly: true, domReadOnly: true }}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(RuntimeTab);
