import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    gap: 16,
    textDecoration: 'none',

    '&:focus-visible': {
      outline: '1px solid #fff',
      outlineOffset: 1
    }
  },
  logo: {
    width: 48,
    height: 33
  },
  title: {
    zIndex: 2,
    color: '#fff',
    fontFamily: 'Open Sans',
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.3,
    margin: 0,

    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 22
    }
  }
}));
