import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Advertisement, useAds, useBrands } from '../../api/video';
import { tooltipADS } from '../../constants/tooltipsHTML';
import SlidesTitle from '../VideoSlides/SlidesTitle';
import VideoSlides from '../VideoSlides';
import VideoSlide from '../VideoSlides/VideoSlide';

import { useStyles } from './AdList.styles';
import { getImageUrl } from '../../shared';

type Props = HTMLAttributes<HTMLDivElement> & {
  videoElem: HTMLVideoElement | null;
};

const emptyAds: Advertisement[] = [];
const emptyBrands: string[] = [];

const AdList = ({ videoElem, ...props }: Props) => {
  const params = useParams<{ id: string }>();
  const { data: brands = [] } = useBrands(params.id);

  const { data: ads = emptyAds } = useAds(params.id);

  const [currentBrands, setCurrentBrands] = useState<string[]>(emptyBrands);
  useEffect(() => {
    if (!videoElem) return;

    const handleTimeUpdate = () => {
      const currentTime = Math.round(videoElem.currentTime);
      const result = ads.find(({ time }) => time === currentTime);
      result ? setCurrentBrands(result.brands) : setCurrentBrands(emptyBrands);
    };

    videoElem.addEventListener('timeupdate', handleTimeUpdate);
    return () => videoElem.removeEventListener('timeupdate', handleTimeUpdate);
  }, [videoElem, ads]);

  const styles = useStyles();

  return (
    <div {...props}>
      <SlidesTitle tooltipHtml={tooltipADS}>Advertisement</SlidesTitle>
      <VideoSlides>
        {brands.map(
          ({ brandId, bgcolor, description, gotoUrl, image, seconds }) => {
            const brandActive = currentBrands.includes(brandId);
            return (
              <VideoSlide
                key={brandId}
                className={styles.card}
                style={{
                  backgroundColor: bgcolor,
                  color: bgcolor > '#999999' ? '#222' : '#fff',
                  order: brandActive ? '1' : '2',
                  opacity: brandActive ? 1 : 0.5
                }}
              >
                <a href={gotoUrl} className={styles.link}>
                  <span className={styles.listItemTiming}>
                    Total on screen: {seconds}s{' '}
                    <span className={styles.listItemTimingAfter}></span>
                  </span>
                  <img
                    src={
                      getImageUrl(image) ??
                      'http://nure.ua/wp-content/uploads/images.png'
                    }
                    alt={description}
                    className={styles.media}
                  />

                  <div className={styles.info}>
                    <span>{description}</span>
                    <span>{gotoUrl}</span>
                  </div>
                </a>
              </VideoSlide>
            );
          }
        )}
      </VideoSlides>
    </div>
  );
};

export default AdList;
