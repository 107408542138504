export const baseUrl = process.env.API_PATH || 'https://accelerator.media';

// Video List Page
export const API_GET_GROUPS = () => `${baseUrl}/api/library/groups`;
export const API_GET_GROUP = (id: string) => `${baseUrl}/api/library/${id}`;

// Video Page
export const API_GET_VIDEO = (id: string) =>
  `${baseUrl}/api/${id}/metadata.json`;
export const API_GET_VIDEO_NAV = (id: string) =>
  `${baseUrl}/api/${id}/storyboard.json`;
export const API_GET_PROMO_TIME = (id: string) =>
  `${baseUrl}/api/${id}/ads.json`;
export const API_GET_BRANDS_LIST = (id: string) =>
  `${baseUrl}/api/${id}/brands.json`;
// Header
export const API_GET_TUTORIAL = () => `${baseUrl}/api/introtour.json`;

// Dashboard
export const API_GET_RECOMMENDATIONS = () => `${baseUrl}/api/library/all`;
export const API_GET_HOT = () => `${baseUrl}/api/library/all`;
export const API_GET_TOP_CHANNELS = () => `${baseUrl}/api/library/all`;
export const API_GET_FAVORITE_CHANNELS = () => `${baseUrl}/api/library/all`;
export const API_GET_FAVORITE_VIDEOS = () => `${baseUrl}/api/library/all`;

// Recordings
export const API_GET_RECORDING_META = (id: string) =>
  `${baseUrl}/api/recordings/${id}/metadata.json`;
export const API_GET_RECORDING = (id: string) =>
  `${baseUrl}/api/dvr/recordings/${id}`;
export const API_POST_RECORDING = () => `${baseUrl}/api/dvr/recording`;
export const API_DELETE_RECORDING = (id: string) =>
  `${baseUrl}/api/dvr/delRecordings/${id}`;
export const API_GET_RECORDINGS = () => `${baseUrl}/api/library/all`;
export const API_GET_RECORDINGS_DB = () => `${baseUrl}/api/dvr/recordings`;

// Video Content Management
export const API_GET_LIBRARY = () => `${baseUrl}/api/library`;
export const API_UPDATE_LIBRARY = () => `${baseUrl}/api/library`;
export const API_DELETE_VIDEO = (id: string) => `${baseUrl}/api/vod/${id}`;
export const API_GET_VIDEO_LOGS = (id: string) =>
  `${baseUrl}/api/vod/${id}/log`;
export const API_UPDATE_VIDEO = (id: string) => `${baseUrl}/api/vod/${id}`;
export const API_ADD_VIDEO = (id: string) => `${baseUrl}/api/vod`;
