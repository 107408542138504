import React from 'react';
import { CardMedia } from '@material-ui/core';

import { capitalizeFirstChar } from '../../shared';
import { EpgEvent, useProgramDetails } from '../../api/channels';

import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';
import CastCard from './CastCard';
import ProgramActions from '../ProgramActions';
import TimeCard from '../TimeCard';

import { useStyles } from './VideoDetails.styles';

type Props = {
  programId: string;
  show?: EpgEvent & { channelId: string };
  showNotFound?: boolean;
  schedule?: () => void;
  dismiss?: () => void;
  handleHover?: () => void;
  recordText?: boolean;
  showRecordButton?: boolean;
  recorded?: boolean;
  position?: string;
};

const VideoDetails = ({
  programId,
  show,
  showNotFound,
  schedule,
  dismiss,
  handleHover,
  recordText,
  showRecordButton,
  recorded,
  position
}: Props) => {
  const styles = useStyles();

  const { data, isLoading, isError, error } = useProgramDetails(programId);

  if (isLoading)
    return (
      <Loader
        loaderProps={{ size: 80, style: { marginTop: 45 } }}
        fullHeightCentralized
      />
    );
  if (!data || !Object.keys(data)) return null;
  if (isError && (error !== 'Not Found' || showNotFound))
    return (
      <ErrorMessage
        error={error === 'Not Found' ? 'No details found' : String(error)}
        fullHeightCentralized
      />
    );

  const { thumbnail, title, genres, director, cast, storyline } = data;

  return (
    <>
      <div className={styles.hgroup}>
        <div className={styles.imageContainer}>
          {thumbnail && (
            <CardMedia className={styles.image} image={thumbnail} />
          )}
        </div>
        <div className={styles.dateContainer}>
          {show && (
            <TimeCard startTime={show.startTime} endTime={show.endTime} />
          )}
        </div>
        <div className={styles.genresContainer}>
          {genres?.map((genre) => (
            <span className={styles.tag} key={genre}>
              {genre}
            </span>
          ))}
        </div>
        <div className={styles.titleContainer}>
          {title && (
            <>
              <span className={styles.title}>Title:</span>
              <span className={styles.titleText}>
                {capitalizeFirstChar(title)}
              </span>
            </>
          )}
        </div>
      </div>
      <div className={styles.personsGrid}>
        {director && (
          <CastCard
            isDirector
            key={director.name}
            title={director.name}
            subtitle="Director"
            thumbnail={director.thumbnail}
            url={director.url}
          />
        )}
        {cast?.map(({ actor, role, thumbnail, url }) => (
          <CastCard
            key={actor}
            title={actor}
            subtitle={role}
            thumbnail={thumbnail}
            url={url}
          />
        ))}
      </div>
      <p className={styles.storyline}>{storyline}</p>
      <div className={styles.programActions}>
        <ProgramActions
          schedule={schedule}
          dismiss={dismiss}
          handleHover={handleHover}
          recordText={recordText}
          showRecordButton={showRecordButton}
          recorded={recorded}
          position={position}
        />
      </div>
    </>
  );
};

export default VideoDetails;
