import {
  FETCH_LIBRARY_REQUEST,
  FETCH_LIBRARY_ERROR,
  FETCH_LIBRARY_SUCCESS,
  FETCH_VIDEO_LOGS_REQUEST,
  FETCH_VIDEO_LOGS_ON_CHUNK,
  FETCH_VIDEO_LOGS_ERROR,
  CLEAR_VIDEO_LOGS,
  DELETE_LIBRARY_VIDEO_REQUEST,
  DELETE_LIBRARY_VIDEO_ERROR,
  DELETE_LIBRARY_VIDEO_SUCCESS,
  ADD_LIBRARY_VIDEO_REQUEST,
  ADD_LIBRARY_VIDEO_ERROR,
  ADD_LIBRARY_VIDEO_SUCCESS,
  UPDATE_LIBRARY_VIDEO_REQUEST,
  UPDATE_LIBRARY_VIDEO_ERROR,
  UPDATE_LIBRARY_VIDEO_SUCCESS,
  UPDATE_LIBRARY_REQUEST,
  UPDATE_LIBRARY_ERROR,
  UPDATE_LIBRARY_SUCCESS,
  CLEAR_LIBRARY
} from '../constants/actionTypes';

import {
  API_GET_LIBRARY,
  API_DELETE_VIDEO,
  API_GET_VIDEO_LOGS,
  API_UPDATE_VIDEO,
  API_UPDATE_LIBRARY,
  API_ADD_VIDEO
} from '../api/apiEndpoints';

const libraryAreLoading = () => ({ type: FETCH_LIBRARY_REQUEST });
const libraryFetchError = () => ({ type: FETCH_LIBRARY_ERROR });
const libraryFetchSuccess = (payload) => ({
  type: FETCH_LIBRARY_SUCCESS,
  payload
});

const videoLogsAreLoading = () => ({ type: FETCH_VIDEO_LOGS_REQUEST });
const videoLogsFetchError = () => ({ type: FETCH_VIDEO_LOGS_ERROR });
const videoLogsFetchOnChunk = (payload) => ({
  type: FETCH_VIDEO_LOGS_ON_CHUNK,
  payload
});

const videoAreDeleting = (payload) => ({
  type: DELETE_LIBRARY_VIDEO_REQUEST,
  payload
});
const videoDeleteError = () => ({ type: DELETE_LIBRARY_VIDEO_ERROR });
const videoDeleteSuccess = (payload) => ({
  type: DELETE_LIBRARY_VIDEO_SUCCESS,
  payload
});

const videoAreAdding = (payload) => ({
  type: ADD_LIBRARY_VIDEO_REQUEST,
  payload
});
const videoAddError = () => ({ type: ADD_LIBRARY_VIDEO_ERROR });
const videoAddSuccess = (payload) => ({
  type: ADD_LIBRARY_VIDEO_SUCCESS,
  payload
});

const videoAreUpdating = (payload) => ({
  type: UPDATE_LIBRARY_VIDEO_REQUEST,
  payload
});
const videoUpdateError = () => ({ type: UPDATE_LIBRARY_VIDEO_ERROR });
const videoUpdateSuccess = (payload) => ({
  type: UPDATE_LIBRARY_VIDEO_SUCCESS,
  payload
});

const libraryAreUpdating = () => ({ type: UPDATE_LIBRARY_REQUEST });
const libraryUpdateError = () => ({ type: UPDATE_LIBRARY_ERROR });
const libraryUpdateSuccess = (payload) => ({
  type: UPDATE_LIBRARY_SUCCESS,
  payload
});

export const libraryClear = () => (dispatch) =>
  dispatch({ type: CLEAR_LIBRARY });

export const libraryFetchData = () => (dispatch) => {
  dispatch(libraryAreLoading());

  fetch(API_GET_LIBRARY())
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(libraryFetchSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in libraryFetchData: ${message}`);
      return dispatch(libraryFetchError(message));
    });
};

export const videoLogsFetchData = (videoId) => (dispatch) => {
  dispatch(videoLogsAreLoading());

  fetch(API_GET_VIDEO_LOGS(videoId))
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      return reader.read().then(function processData({ done, value }) {
        if (done) {
          dispatch(
            videoLogsFetchOnChunk({
              done,
              value: ''
            })
          );
          return Promise.resolve();
        }

        dispatch(
          videoLogsFetchOnChunk({
            done,
            value: decoder.decode(value).replace(/(?:\r\n|\r|\n)/g, '<br/>')
          })
        );

        return reader.read().then(processData);
      });
    })
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in videoLogsFetchData: ${message}`);
      return dispatch(videoLogsFetchError(message));
    });
};

export const videoLogsClear = () => ({ type: CLEAR_VIDEO_LOGS });

export const videoDelete = (videoId) => (dispatch) => {
  dispatch(videoAreDeleting(videoId));

  fetch(API_DELETE_VIDEO(videoId), {
    method: 'DELETE'
  })
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then((response) => dispatch(videoDeleteSuccess(response)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in videoDelete: ${message}`);
      return dispatch(videoDeleteError(message));
    });
};

export const videoAdd =
  ({ groupId, url, seconds }) =>
  (dispatch) => {
    dispatch(videoAreAdding());

    return fetch(API_ADD_VIDEO(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        groupId,
        url,
        seconds
      })
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response;
      })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          videoAddSuccess({
            groupId,
            video: response
          })
        )
      )
      .catch((error) => {
        const message = error.message || error;
        console.error(`ERROR in videoAdd: ${message}`);
        return dispatch(videoAddError(message));
      });
  };

export const videoUpdate = (data) => (dispatch) => {
  dispatch(videoAreUpdating(data.id));

  return fetch(API_UPDATE_VIDEO(data.id), {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then(() => dispatch(videoUpdateSuccess(data)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in videoUpdate: ${message}`);
      return dispatch(videoUpdateError(message));
    });
};

export const libraryUpdate = (data) => (dispatch) => {
  dispatch(libraryAreUpdating());

  return fetch(API_UPDATE_LIBRARY(), {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response;
    })
    .then((response) => response.json())
    .then(() => dispatch(libraryUpdateSuccess(data)))
    .catch((error) => {
      const message = error.message || error;
      console.error(`ERROR in libraryUpdate: ${message}`);
      return dispatch(libraryUpdateError(message));
    });
};
