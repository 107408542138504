import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gearIcon: {
    color: '#fff',
    width: 16,
    height: 16,
    cursor: 'pointer'
  }
}));
