import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  componentRoot: {
    containerType: 'inline-size',

    '&:hover $slider': {
      opacity: 1
    }
  },
  carouselWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  carouselRoot: {
    overflow: 'hidden',
    width: '100%',
    padding: '10px 0'
  },
  carouselContainer: {
    display: 'flex',
    gap: 2
  },
  slide: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'scale 0.2s',
    cursor: 'pointer',

    '@container (width < 900px)': {
      height: 75
    }
  },
  arrow: {
    '@container (width < 900px)': {
      height: 75
    }
  },
  slider: {
    opacity: 0.5,
    transition: 'opacity 0.2s',
    margin: '0 auto 12px auto',
    width: 'calc(100% - (38px * 2))'
  }
}));
