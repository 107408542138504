import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  iconContainer: {
    position: 'relative',
    width: 18,

    '&:hover svg': {
      filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'
    },

    '& svg': {
      cursor: 'pointer',
      width: '100%',
      color: '#fff'
    },

    '&:hover > $sliderContainer': {
      display: 'block'
    }
  },
  button: {
    all: 'unset',
    '&:focus-visible': {
      outline: '1px solid #fff',
      outlineOffset: 5
    }
  },
  sliderContainer: {
    display: 'none',
    position: 'absolute',
    width: 29,
    height: 85,
    left: '50%',
    transform: 'translate(-50%, -100%)',
    top: 0
  },
  sliderLayer: {
    height: 80,
    width: 29,
    backgroundColor: '#414042',
    display: 'flex',
    alignItems: 'center'
  },
  sliderRoot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    touchAction: 'none',
    width: '100%',
    height: '50px'
  },
  sliderTrack: {
    backgroundColor: '#73859f80',
    position: 'relative',
    borderRadius: '9999px',
    width: '3px',
    cursor: 'pointer',
    transition: 'all .2s',
    height: '100%'
  },
  sliderRange: {
    position: 'absolute',
    backgroundColor: '#f37037',
    borderRadius: '9999px',
    width: '3px'
  },
  sliderThumb: {
    position: 'relative',
    display: 'block',
    width: '9px',
    height: '9px',
    backgroundColor: '#f37037',
    boxShadow: '0 0 1px gray',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all .2s',

    '&:active, &:focus': {
      boxShadow: '0 0 1em #fff'
    },

    '&:focus-visible': {
      outline: 'none'
    }
  }
}));
