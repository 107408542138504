import {
  FETCH_VIDEO_PROMO_REQUEST,
  FETCH_VIDEO_PROMO_ERROR,
  FETCH_VIDEO_PROMO_SUCCESS,
  CLEAR_VIDEO_PROMO,
  FETCH_VIDEO_PROMO_TIME_REQUEST,
  FETCH_VIDEO_PROMO_TIME_ERROR,
  FETCH_VIDEO_PROMO_TIME_SUCCESS,
  CLEAR_VIDEO_PROMO_TIME
} from '../constants/actionTypes';

export const initialState = {
  brandsAreLoading: false,
  brandsHaveError: false,
  brandsError: '',
  brands: [],
  timeIsLoading: false,
  timeHasError: false,
  timeError: '',
  time: []
};

export function videoPromo(state = initialState, action) {
  switch (action.type) {
    case FETCH_VIDEO_PROMO_REQUEST:
      return {
        ...state,
        brandsAreLoading: true,
        brandsHaveError: false,
        brands: [],
        brandsError: ''
      };

    case FETCH_VIDEO_PROMO_ERROR:
      return {
        ...state,
        brandsAreLoading: false,
        brandsHaveError: true,
        brandsError: action.payload
      };

    case FETCH_VIDEO_PROMO_SUCCESS:
      return {
        ...state,
        brandsAreLoading: false,
        brands: action.payload
      };

    case CLEAR_VIDEO_PROMO:
      return {
        ...state,
        brands: []
      };

    case FETCH_VIDEO_PROMO_TIME_REQUEST:
      return {
        ...state,
        timeIsLoading: true,
        timeHasError: false,
        time: [],
        timeError: ''
      };

    case FETCH_VIDEO_PROMO_TIME_ERROR:
      return {
        ...state,
        timeIsLoading: false,
        timeHasError: true,
        timeError: action.payload
      };

    case FETCH_VIDEO_PROMO_TIME_SUCCESS:
      return {
        ...state,
        timeIsLoading: false,
        time: action.payload
      };

    case CLEAR_VIDEO_PROMO_TIME:
      return {
        ...state,
        time: []
      };

    default:
      return state;
  }
}
