import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '32px',
    padding: 32,

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    },

    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  tilesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '8px'
  },
  topChannels: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  topChannelsTitle: {
    color: '#F37037',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  topChannelsImg: {
    display: 'block',
    maxWidth: '100%'
  },
  favoriteChannels: {
    width: '100%',
    marginTop: -9
  },
  favoriteChannelsTitleWrp: {
    display: 'flex',
    marginBottom: 18
  },
  favoriteChannelsTitle: {
    color: '#E6E7E8',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '19px',

    '&:first-child': {
      width: 188
    }
  },
  lastRecordings: {
    flexGrow: 1,
    margin: '-20px -20px -25px -20px'
  },
  viewAllLink: {
    textTransform: 'none',
    float: 'right',
    color: '#F37037',
    transition: '.3s ease-out',
    fontWeight: '100',
    '&:hover': {
      color: '#ffa657',
      transition: '.3s ease-out'
    }
  },
  thumbnailLink: {
    textDecoration: 'none'
  }
}));
