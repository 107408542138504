import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#272628',
    position: 'relative',
    transition: 'height 0.5s',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    paddingLeft: 44,
    paddingRight: 51,

    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: 27
  },
  searchButton: {
    '&&': {
      marginRight: 3
    }
  },
  toolbarButton: {
    zIndex: 2,
    margin: 0,
    padding: 0,
    borderRadius: 0,
    '& svg': {
      color: theme.palette.secondary.main
    },
    '&:focus-visible': {
      outline: '1px solid #fff',
      outlineOffset: 2
    }
  },
  menuIcon: {
    color: '#D8D8D8'
  },
  menu: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    marginLeft: '-60px',
    top: '80px!important'
  },
  menuLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  menuItem: {
    color: '#D8D8D8',
    fontFamily: 'Open Sans',
    fontSize: '20px',
    lineHeight: '30px'
  },
  switcher: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '10px'
  },
  loginLink: {
    textDecoration: 'none'
  },
  navigation: {},
  navigationSearchOpen: {
    display: 'none'
  },
  searchContainer: {
    fontSize: 18,
    translate: '6px 0px'
  },
  searchIcon: {
    width: 26,
    height: 26,

    '& path': {
      fill: theme.palette.secondary.main,
      stroke: theme.palette.secondary.main
    }
  }
}));
