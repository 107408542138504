import {
  FETCH_PROGRAM_GUIDE_REQUEST,
  FETCH_PROGRAM_GUIDE_ERROR,
  FETCH_PROGRAM_GUIDE_SUCCESS
} from '../constants/actionTypes';

export const initialState = {
  isLoading: false,
  isFailed: false,
  data: []
};

export function programGuide(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROGRAM_GUIDE_REQUEST:
      return { ...state, isLoading: true, data: [] };

    case FETCH_PROGRAM_GUIDE_ERROR:
      return { ...state, isLoading: false, isFailed: true };

    case FETCH_PROGRAM_GUIDE_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };

    default:
      return state;
  }
}
