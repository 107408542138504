/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Tab, Tabs, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './TranscodingPage.styles';
import WorkflowsTab from './WorkflowsTab';
import ProfileTab from './ProfilesTab';
import RuntimeTab from './RuntimeTab';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3}>
        <Typography component={'div'}>{children}</Typography>
      </Box>
    )}
  </div>
);

const TranscodingPage = ({ classes }) => {
  const [tabId, setTabId] = useState(0);
  const handleChange = (event, newValue) => {
    setTabId(newValue);
  };
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Tabs value={tabId} onChange={handleChange}>
          <Tab
            key="workflows"
            className={classes.tab}
            index={0}
            label="Workflows"
          />
          <Tab
            key="profiles"
            className={classes.tab}
            index={1}
            label="Profiles"
          />
          <Tab
            key="runtime"
            className={classes.tab}
            index={2}
            label="Runtime"
          />
        </Tabs>
      </div>
      <TabPanel value={tabId} index={0}>
        <WorkflowsTab />
      </TabPanel>
      <TabPanel value={tabId} index={1}>
        <ProfileTab />
      </TabPanel>
      <TabPanel value={tabId} index={2}>
        <RuntimeTab />
      </TabPanel>
    </div>
  );
};

const QueryWrapped = (props) => {
  return <TranscodingPage {...props} />;
};

export default withStyles(styles)(QueryWrapped);
