import React, { HTMLAttributes } from 'react';

import { useStyles } from './CarouselItem.styles';
import clsx from 'clsx';

type Props = HTMLAttributes<HTMLDivElement> & {
  isActive?: boolean;
};

const CarouselItem = ({ className, isActive, ...props }: Props) => {
  const classes = useStyles();
  return (
    <div
      {...props}
      className={clsx(className, classes.item, isActive && classes.active)}
    />
  );
};

export default CarouselItem;
