import { PrevStateFn, SetType } from '../storeTypeUtils';

type ActiveSearch = 'regular' | 'ai';

export type SearchSlice = {
  activeSearch: ActiveSearch | null;
  setActiveSearch: (input: ActiveSearch) => void;
  isSearchOpen: boolean;
  setIsSearchOpen: (input: boolean | PrevStateFn<boolean>) => void;
};

export function searchSlice(set: SetType<SearchSlice>) {
  return {
    activeSearch: null,
    setActiveSearch: (input: ActiveSearch) => {
      set({ activeSearch: input }, false, 'setActiveSearch');
    },
    isSearchOpen: false,
    setIsSearchOpen: (input: boolean | PrevStateFn<boolean>) => {
      const setRest = [false, 'setIsSearchOpen'] as const;
      if (typeof input === 'boolean') {
        set({ isSearchOpen: input }, ...setRest);
      } else {
        set(
          (state) => ({ isSearchOpen: input(state.isSearchOpen) }),
          ...setRest
        );
      }
    }
  } satisfies SearchSlice;
}
