export const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
    padding: '10px 50px 20px 50px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tab: {
    textTransform: 'none',
    fontSize: '14px'
  },
  videoGroup: {
    padding: '15px 0',
    clear: 'both'
  },
  divider: {
    display: 'table',
    whiteSpace: 'nowrap',
    paddingRight: '20px',
    marginBottom: '15px',
    lineHeight: '24px',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    '&:after': {
      display: 'table-cell',
      position: 'relative',
      width: '100%',
      top: '12px',
      left: '20px',
      borderTop: '2px solid',
      borderTopColor: theme.palette.primary.main,
      opacity: 0.5,
      content: "''"
    }
  },
  modal: {
    fontFamily: 'Open Sans',
    backgroundColor: '#1D1E21'
  },
  modalTitle: {
    backgroundColor: '#141518',
    padding: '15px 20px',
    textTransform: 'uppercase',
    height: '50px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalTitleText: {
    color: '#F37037',
    fontSize: '14px'
  },
  modalContent: {
    padding: '24px',
    color: 'white',
    lineHeight: '24px',
    fontSize: '14px'
  },
  editLibraryBtn: {
    float: 'right'
  },
  addVideoCard: {
    margin: '0 15px',
    backgroundColor: 'rgba(255,255,255,0.12)',
    width: '150px',
    height: '285px',
    flexShrink: 0
  },
  addVideoCardContent: {
    height: '100%',
    display: 'flex',

    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  processingVideoCard: {
    backgroundColor: 'rgba(255,255,255,0.12)',
    margin: '0 15px',
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0
  },
  processingVideoCardContent: {
    height: '100%',
    display: 'flex',

    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  processingVideoCardActions: {
    justifyContent: 'flex-end'
  },
  input: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '32px',
    lineHeight: '43px',
    '&::-webkit-input-placeholder': {
      fontStyle: 'italic'
    },
    '&::-ms-clear': {
      display: 'none'
    }
  },
  cssUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.contrastText
    },
    '&:hover:before': {
      borderBottom: `1px solid ${theme.palette.primary.contrastText}!important`
    },
    '&:after': {
      borderBottomColor: theme.palette.secondary.main
    }
  },
  flexContainer: {
    justifyContent: 'space-between'
  },
  cardContainer: {
    width: '300px',
    minWidth: '300px',
    marginRight: '15px',
    marginLeft: '15px'
  },
  scrollButton: {
    color: '#D8D8D8',
    '& svg': {
      fontSize: '40px'
    }
  },
  actionButton: {
    padding: '10px 12px',
    color: '#D8D8D8',
    '&:hover': {
      color: '#fff'
    }
  },
  addButton: {
    color: '#D8D8D8',
    '&:hover': {
      color: '#fff'
    }
  },
  refreshVideoLogButton: {
    color: '#D8D8D8',
    '&:hover': {
      color: '#fff'
    }
  }
});
