import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  searchPage: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: '40px 50px 40px 50px',
    flex: 1
  },
  title: {
    margin: 0,
    fontSize: 18,
    lineHeight: '24.51px',
    color: '#fff',
    marginBottom: 24
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: 40,

    '& > *': {
      width: 320
    }
  }
});
