import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ForwardIcon = (props: SvgIconProps) => (
  <SvgIcon
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="200px"
    width="200px"
    {...props}
  >
    <path d="m16 400 240-144L16 112v288zm240 0 240-144-240-144v288z" />
  </SvgIcon>
);

export const BackwardIcon = (props: SvgIconProps) => (
  <SvgIcon
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="200px"
    width="200px"
    {...props}
  >
    <path d="M496 400 256 256l240-144v288zm-240 0L16 256l240-144v288z" />
  </SvgIcon>
);

export const PauseIcon = (props: SvgIconProps) => (
  <SvgIcon
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M96 448h106.7V64H96v384zM309.3 64v384H416V64H309.3z" />
  </SvgIcon>
);

export const ReplayIcon = (props: SvgIconProps) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="200px"
    width="200px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none">
      <path d="M0 0h24v24H0z" />
      <path d="M0 0h24v24H0z" />
      <path d="M0 0h24v24H0z" />
    </g>
    <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
  </svg>
);
