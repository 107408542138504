import { Button, IconButton, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useShallow } from 'zustand/react/shallow';
import { Link } from 'react-router-dom';

import { useAiChat, usePrevious } from '../../../hooks';
import { useSearchStore } from '../../../stores';
import Loader from '../../Loader';
import { useInitAiSearch } from '../../../api/searchAi';
import { useSearchVideos } from '../../../api/search';
import { useGetUser } from '../../../api/user';
import VoiceSearch from '../../VoiceSearch';

import { useStyles } from './ChatControls.styles';
import { MicIcon } from '../../../assets/icons';

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const ChatControls = () => {
  const styles = useStyles();

  const [value, setValue] = useState('');

  const { onAiSearch } = useAiChat();
  const { isMutating, searchQueryStr, isChatOpen } = useSearchStore(
    useShallow((state) => ({
      isMutating: state.isMutating,
      searchQueryStr: state.searchQueryStr,
      isChatOpen: state.isChatOpen
    }))
  );
  const { isLoading: isInitLoading, isFetching: isInitFetching } =
    useInitAiSearch();
  const { isLoading: isRegularLoading, isFetching: isRegularFetching } =
    useSearchVideos(searchQueryStr ?? '');
  const initLoading = isInitLoading || isInitFetching;
  const loading =
    initLoading || isMutating || isRegularLoading || isRegularFetching;

  const onSubmit = () => {
    onAiSearch(value);
    setValue('');
  };

  // auto focus on input logic
  const inputRef = React.useRef<HTMLInputElement>(null);
  const prevIsMutating = usePrevious(isMutating);
  const prevIsChatOpen = usePrevious(isChatOpen);
  useEffect(() => {
    if ((!isMutating && prevIsMutating) || (isChatOpen && !prevIsChatOpen)) {
      inputRef.current?.focus();
    }
  }, [isChatOpen, isMutating, prevIsChatOpen, prevIsMutating]);

  const [isVoiceOpen, setIsVoiceOpen] = useState(false);
  /** Must be memoized as it used in useEffect */
  const onClose = useCallback(() => {
    setIsVoiceOpen(false);
    inputRef.current?.focus();
  }, []);
  /** Must be memoized as it used in useEffect */
  const onVoiceSearch = useCallback((text: string) => {
    setValue((prevText) => (prevText + ' ' + text).trim());
  }, []);

  const { isSuccess: isLoggedIn } = useGetUser();

  return (
    <div>
      <div className={styles.textareaContainer}>
        <div className={styles.textfieldContainer}>
          {initLoading && isLoggedIn && (
            <Loader loaderProps={{ className: styles.loader, size: 15 }} />
          )}
          {!isLoggedIn && (
            <div className={styles.loginMsg}>
              Please, <Link to="/login">login</Link> to use AI Assistant.
            </div>
          )}
          {SpeechRecognition && isLoggedIn && (
            <>
              <IconButton
                title="Search using voice"
                disableRipple
                className={styles.mic}
                onClick={() => setIsVoiceOpen(true)}
              >
                <MicIcon />
              </IconButton>
              {isVoiceOpen && (
                <VoiceSearch
                  onClose={onClose}
                  onVoiceSearch={onVoiceSearch}
                  size="small"
                  className={styles.voiceOverlay}
                />
              )}
            </>
          )}
          <TextField
            inputRef={inputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            multiline
            minRows={3}
            disabled={initLoading}
            className={styles.textarea}
            placeholder={isLoggedIn ? 'Talk to Assistant...' : ''}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.keyCode === 13 && value) {
                e.preventDefault();
                !loading && onSubmit();
              }
            }}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className={clsx(styles.button, styles.secondaryButton)}
          disableRipple
          // temporary disabled and hidden
          style={{ opacity: 0, pointerEvents: 'none' }}
          // temporary not focusable
          tabIndex={-1}
        >
          New search
        </Button>
        <Button
          className={clsx(styles.button, styles.primaryButton)}
          disableRipple
          disabled={loading || !value || !isLoggedIn}
          onClick={onSubmit}
          title="Send message (Ctrl + Enter)"
        >
          Send message
        </Button>
      </div>
    </div>
  );
};

export default ChatControls;
