export const styles = () => ({
  root: {
    borderRadius: 0,
    background: 'rgba(255,255,255,0.12)',

    '&:hover': {
      cursor: 'pointer',
      background: '#545763'
    }
  },
  mediaWrapper: {
    '&:before': {
      content: "''",
      display: 'block',
      paddingTop: '56%'
    },
    position: 'relative'
  },
  media: {
    height: 0,
    paddingTop: '56%'
  },
  content: {
    padding: '10px 10px 5px 10px',
    height: '55px',
    boxSizing: 'border-box'
  },
  contentTitle: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '19px',
    height: '40px',
    overflow: 'hidden'
  }
});
