/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tabs,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { styles } from './PlatformIntelligencePage.styles';

const PlatformIntelligencePage = ({ classes }) => {
  const videoList = [
    {
      id: '9498a214-9ada-4b0b-88dd-05d669321fab',
      title:
        '80+ Happy Family Stock Videos Footage | Family Having a Fun - Family Gathering - Family Bonding',
      thumbnail: 'https://i.ytimg.com/vi_webp/7R7vULZuvwM/maxresdefault.webp'
    },
    {
      id: '11823cb0-447e-47fd-a396-970e9fa8284c',
      title:
        '50+ Family Gathering Free Stock Video Footage - No Copyright | Family Dinner - Gathered Together',
      thumbnail: 'https://i.ytimg.com/vi_webp/gPi0kOVT6cI/maxresdefault.webp'
    },
    {
      id: '8f9c5172-bf8e-4b25-bc74-b1fb1d86ceff',
      title:
        '38+ People Drinking Coffee Free Stock Video Footage | Woman & Man Drink Coffee While Looking Phone',
      thumbnail: 'https://i.ytimg.com/vi/bkDI7O8F9yw/maxresdefault.jpg'
    }
  ];

  let content = null;

  content = (
    <Tabs
      value={false}
      variant="scrollable"
      scrollButtons="auto"
      classes={{
        scrollButtons: classes.scrollButton,
        flexContainer: classes.flexContainer
      }}
    >
      {videoList.map((video, index) => (
        <div className={classes.cardWrapper} key={`${index}-${video.id}`}>
          <Card className={classes.card}>
            <Link
              className={classes.link}
              to={{
                pathname: `/admin/ml/video/${video.id}`
              }}
            >
              <CardActionArea
                classes={{
                  focusHighlight: classes.cardFocusHighlight
                }}
              >
                <CardMedia
                  className={classes.image}
                  title={video.title}
                  image={video.thumbnail}
                />
                <CardContent className={classes.content}>
                  <Typography className={classes.title}>
                    {video.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </div>
      ))}
    </Tabs>
  );

  return (
    <div className={classes.root}>
      <div className="">
        <Typography className={classes.divider} color="secondary">
          VIDEOS
        </Typography>
        {content}
      </div>
    </div>
  );
};

export default withStyles(styles)(PlatformIntelligencePage);
