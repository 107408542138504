import { request } from '../request';

import { Video, Storyboard, Advertisement, Brand } from './videoTypes';
import {
  getVideoUrl,
  getStoryboardUrl,
  getAdsUrl,
  getBrandsUrl
} from './videoUrls';

export const getVideo = (id: string) => request<Video>(getVideoUrl(id));

export const getStoryboard = (id: string) =>
  request<Storyboard[]>(getStoryboardUrl(id));

export const getAds = (id: string) => request<Advertisement[]>(getAdsUrl(id));

export const getBrands = (id: string) => request<Brand[]>(getBrandsUrl(id));
