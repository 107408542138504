import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (size: 'large' | 'small' = 'large') => {
  const isBig = size === 'large';
  return makeStyles((theme) => ({
    root: {
      backgroundColor: '#404040',
      color: theme.palette.primary.contrastText,
      borderRadius: isBig ? 30 : 10,
      height: isBig ? 212 : 97,

      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'space-between',
      gap: 20,

      fontFamily: '"Open Sans"',
      fontSize: isBig ? 24 : 18,

      paddingLeft: isBig ? 49 : 25,
      paddingRight: isBig ? 37 : 10
    },
    empty: {
      opacity: 0.8
    },
    micContainer: {
      position: 'relative',
      ...(!isBig && { scale: 0.66, transformOrigin: '106% 52%' })
    },
    theMic: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: isBig
        ? 'translate(calc(-50% + 4px), calc(-50% + 3px))'
        : 'translate(calc(-50% + 2px), calc(-50%))',

      scale: isBig ? 1.55 : 1.2,
      transformOrigin: '50% 50%',

      '& path': {
        fill: theme.palette.secondary.main
      }
    },
    micIdle: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: -3,
      transition: 'opacity 0.3s'
    },
    micActive: {
      transition: 'opacity 0.3s'
    },
    close: {
      position: 'absolute',
      padding: 0,
      backgroundColor: 'transparent',
      border: 'none',
      color: '#C4C6CC',
      fontFamily: '"Open Sans"',
      fontSize: 14,
      top: isBig ? 16 : 6,
      right: isBig ? 23 : 15,
      cursor: 'pointer',

      '&:focus-visible': {
        outline: `1px solid ${theme.palette.secondary.main}`,
        outlineOffset: 1
      },

      ...(!isBig && {
        '& svg': {
          width: 10,
          height: 10
        },
        '& path': {
          fill: '#C4C6CC'
        }
      })
    }
  }));
};
