import moment from 'moment';

export const formatDate = (from, to, withDay) =>
  `${moment(from).format('HH:mm')} — ${moment(to).format('HH:mm')} ${withDay ? ', Today' : ''}`;

export function findClosestMinDate() {
  const currentDate = new Date();
  currentDate.setMonth(8);
  currentDate.setDate(1);
  return currentDate.getMinutes() < 30
    ? currentDate.setMinutes(0)
    : currentDate.setMinutes(30);
}

export function debounce(fn, delay) {
  let timer = null;
  return function () {
    const context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export const shuffleArray = (a) => {
  const arr = [...a];

  let j, x;
  for (let i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = arr[i];
    arr[i] = arr[j];
    arr[j] = x;
  }

  return arr;
};
