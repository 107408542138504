import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  showCardWrapper: {
    display: 'inline-block',
    position: 'absolute',
    '&:hover': {
      cursor: 'pointer',
      '& $showCard': {
        backgroundColor: '#545763'
      }
    }
  },
  showCard: {
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: '#454851',
    borderRadius: 0,
    borderRight: '2px solid black',
    borderBottom: '2px solid black',
    height: 62
  },
  showCardContent: {
    flex: 'auto',
    width: 35,
    padding: '9px 14px 13px 10px'
  },
  showCover: {
    width: 107,
    minWidth: 107
  },
  title: {
    color: '#FFF',
    fontFamily: 'Open Sans, sans-serif',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  time: {
    color: '#FFF',
    fontSize: 11,
    fontFamily: 'Open Sans, sans-serif'
  },
  tooltipImage: {
    width: 200,
    height: 112,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  tooltipTitle: {
    color: '#FFF',
    fontFamily: 'Open Sans, sans-serif',
    padding: '5px 0'
  },
  tooltipTime: {
    color: '#FFF',
    fontSize: 11,
    paddingBottom: 3
  }
}));
