import { useHistory, useLocation } from 'react-router-dom';

import { useGetUser } from '../../../api/user';

export const useLoggedInRedirect = () => {
  const history = useHistory();
  const location = useLocation();

  const { isSuccess: isAuthenticated } = useGetUser();

  const redirect = () =>
    history.push((location.state as { from: string })?.from ?? '/');

  if (isAuthenticated) redirect();

  return redirect;
};
