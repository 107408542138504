import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',

    width: 653,
    height: 52,
    padding: 2,
    borderRadius: 120,
    backgroundColor: '#404040',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inputs: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    gap: 14
  },
  buttonsContainer: {
    backgroundColor: '#D9D9D933',
    borderRadius: 80,
    height: '100%',
    minWidth: 207,
    padding: 2,
    display: 'flex',
    gap: 1
  },
  toggleButton: {
    cursor: 'pointer',
    opacity: 0.6,
    height: '100%',
    color: '#fff',
    textTransform: 'none',
    borderRadius: 110,

    padding: '0 14px',
    '&:last-child': {
      padding: '0 13px',

      '& svg > path': {
        fill: '#5e5e5e'
      }
    },

    backgroundColor: 'transparent',
    border: 0,
    display: 'flex',
    alignItems: 'center',

    '& img': {
      marginRight: 3
    }
  },
  activeToggleButton: {
    opacity: 1,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },

    '&:last-child': {
      '& svg > path': {
        fill: theme.palette.secondary.main
      }
    }
  },
  inputsContainer: {
    width: '100%'
  },
  controls: {
    marginRight: 14,
    display: 'flex',
    gap: 6
  },
  control: {
    width: 14,
    '& svg': {
      width: 14
    },

    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent',

      '& svg path': { fill: theme.palette.secondary.main }
    },
    '&:focus-visible': {
      outline: '1px solid #fff',
      outlineOffset: -8
    }
  },
  voice: {
    position: 'absolute',
    top: 0,
    left: -1,
    right: 0,
    zIndex: 1
  },
  loginMsg: {
    fontSize: 14,
    fontFamily: 'Open Sans',

    '& a': {
      color: theme.palette.secondary.main
    }
  }
}));
