import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './ErrorMessage.styles';

type Props = {
  fullHeightCentralized?: boolean;
  error?: string;
};

const ErrorMessage = ({ error, fullHeightCentralized = false }: Props) => {
  const styles = useStyles();

  const text = (
    <Typography className={styles.text}>
      {String(error || 'Oops, something went wrong...')}
    </Typography>
  );

  return fullHeightCentralized ? (
    <div className={styles.errorMessage}>{text}</div>
  ) : (
    text
  );
};

export default ErrorMessage;
