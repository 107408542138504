import {
  FETCH_TUTORIAL_REQUEST,
  FETCH_TUTORIAL_ERROR,
  FETCH_TUTORIAL_SUCCESS
} from '../constants/actionTypes';

import { API_GET_TUTORIAL } from '../api/apiEndpoints';

export function tutorialIsLoading() {
  return {
    type: FETCH_TUTORIAL_REQUEST
  };
}

export function tutorialFetchError(error) {
  return {
    type: FETCH_TUTORIAL_ERROR,
    payload: error
  };
}

export function tutorialFetchSuccess(responce) {
  return {
    type: FETCH_TUTORIAL_SUCCESS,
    payload: responce
  };
}

export function tutorialFetchData() {
  return (dispatch) => {
    dispatch(tutorialIsLoading());

    fetch(API_GET_TUTORIAL())
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        return response;
      })
      .then((response) => response.json())
      .then((response) => dispatch(tutorialFetchSuccess(response)))
      .catch((error) => {
        const message = error.message || error;
        console.error(`ERROR in tutorialFetchData: ${message}`);
        return dispatch(tutorialFetchError(message));
      });
  };
}
