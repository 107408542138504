import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { recommendationsFetchData } from '../../../actions/dashboard.action'; // TODO: TMP ACTION replace when real data will be available

export function useConnectStateMatchings() {
  const dispatch = useDispatch();

  const { areLoading, haveError, error } = useSelector((state) => state.groups);

  const sampleMatchings = useSelector(
    (state) => state.dashboard.recommendations.data
  );
  useEffect(() => void dispatch(recommendationsFetchData()), [dispatch]);

  return {
    areLoading,
    haveError,
    error,
    sampleMatchings
  };
}
