import React from 'react';
import { bool, object, oneOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { formatDate } from '../../shared/utils';
import { styles } from './TimeCard.styles';

const propTypes = {
  startTime: string,
  endTime: string,
  showDay: bool,
  theme: oneOf(['blue', 'gray', 'turquoise']),
  classes: object.isRequired
};

const defaultProps = {
  startTime: '2019-09-01T00:00:00.000Z',
  endTime: '2019-09-01T00:00:00.000Z',
  theme: 'blue',
  showDay: false
};

const COLORS = new Map([
  ['blue', '#3770f3'],
  ['gray', '#414042'],
  ['turquoise', '#048075']
]);

const TimeCard = ({
  startTime,
  endTime,
  showDay,
  theme,
  classes: { date }
}) => (
  <span className={date} style={{ backgroundColor: COLORS.get(theme) }}>
    {formatDate(startTime, endTime, false)}
    {showDay && ', Today'}
  </span>
);

TimeCard.propTypes = propTypes;
TimeCard.defaultProps = defaultProps;

export default withStyles(styles)(TimeCard);
