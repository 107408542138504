import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    color: '#ffffff'
  },
  sliderMarkLabel: {
    color: '#ffffff'
  },
  filename: {
    color: '#ffffff'
  },
  sliderMark: {
    backgroundColor: '#ffffff',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginTop: '-5px',
    marginLeft: '-6px'
  },
  alert: {
    marginTop: '20px'
  },
  buttonBlock: {
    marginTop: '10px',
    marginBottom: '20px'
  },
  info: {
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: '15px'
  },
  thumbsContainer: {
    width: '92%',
    margin: '0 auto',
    position: 'relative'
  },
  thumbWrapper: {
    height: 140,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    '@media (max-width: 1366px)': {
      height: 110
    }
  },
  thumb: {
    width: '200px',
    height: '101.25px',
    margin: '0 1px',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    border: '2px solid rgba(255, 255, 255, 0.3)',
    transition: 'transform 600ms',

    display: 'block',
    backgroundColor: 'transparent',
    padding: 0,
    cursor: 'pointer',
    outline: 'none',

    '@media (max-width: 1366px)': {
      height: 75
    }
  },
  slick: {
    '& .slick-track': {
      display: 'flex'
    }
  },
  underVideo: {
    padding: '0 50px 20px 50px'
  }
}));
