import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textareaContainer: {
    position: 'relative'
  },
  textfieldContainer: {
    position: 'relative',
    marginBottom: 20
  },
  mic: {
    position: 'absolute',
    bottom: 4,
    right: 9,
    zIndex: 1,

    '&:hover': {
      backgroundColor: 'transparent',

      '& path': {
        fill: theme.palette.secondary.main
      }
    },

    '& svg': {
      width: 14
    },

    '& path': {
      fill: 'black'
    }
  },
  voiceOverlay: {
    position: 'absolute',
    top: -1,
    right: -1,
    left: 0,
    zIndex: 1,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 11
  },
  textarea: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.contrastText,

    '&:has(textarea[disabled])': {
      opacity: 0.8
    },

    '& > div': {
      fontSize: 14,
      paddingRight: 44,
      lineHeight: '20px',
      fontFamily: '"Open Sans", sans-serif',

      minHeight: 93,

      borderRadius: 10,
      backgroundColor: theme.palette.primary.contrastText,
      color: '#000',
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText
      },

      padding: '15px 16px 15px 16px',

      // no bottom border
      '&:after, &:before': {
        display: 'none'
      }
    }
  },
  loader: {
    position: 'absolute',
    color: theme.palette.secondary.main,
    zIndex: 1,
    right: 20,
    top: 18
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  button: {
    borderRadius: 10,
    color: theme.palette.primary.contrastText,
    height: 45,
    fontSize: 14,
    textTransform: 'none',

    '&:disabled': {
      color: theme.palette.primary.contrastText,
      opacity: 0.6
    }
  },

  secondaryButton: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    width: 165,

    '&:focus-visible': {
      outline: `1px solid ${theme.palette.secondary.main}`,
      outlineOffset: -1
    }
  },

  primaryButton: {
    width: 200,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },

    '&:focus-visible': {
      outline: `1px solid ${theme.palette.primary.contrastText}`
    }
  },

  loginMsg: {
    position: 'absolute',
    zIndex: 1,

    top: 17,
    left: 15,

    fontSize: 14,
    fontFamily: 'Open Sans',
    color: '#000',

    '& a': {
      color: theme.palette.secondary.main
    }
  }
}));
