/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Link as ExternalLink
} from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useShallow } from 'zustand/react/shallow';

import { tutorialFetchData } from '../../actions/tutorial.action';
import WelcomeTour from '../WelcomeTour';
import { useSearchStore } from '../../stores';

import Navigation from './Navigation';
import Logo from './Logo';
import { menuLinks } from './constants';
import UserDropdown from './UserDropdown';

import { useStyles } from './Header.styles';
import { SearchIcon } from '../../assets/icons';
import widgetsSvg from './assets/widgets.svg';

const HeaderWrapper = (props) => {
  const classes = useStyles();

  const extraProps = useSearchStore(
    useShallow((state) => ({
      isSearchOpen: state.isSearchOpen,
      setIsSearchOpen: state.setIsSearchOpen
    }))
  );

  return <Header {...props} {...extraProps} classes={classes} />;
};

class Header extends Component {
  state = {
    menuEl: null,
    tutorialState: false
  };

  componentDidMount() {
    this.props.fetchTutorial();
  }

  showTutorial = () =>
    this.setState({ tutorialState: !this.state.tutorialState });

  setMenuEl = (val) => this.setState({ menuEl: val });

  render() {
    const { menuEl, tutorialState } = this.state;
    const { classes, tutorial, searchElements, isSearchOpen, setIsSearchOpen } =
      this.props;

    const getIconSharedProps = (...args) => ({
      className: clsx(classes.toolbarButton, ...args),
      disableRipple: true
    });

    return (
      <AppBar className={clsx(classes.header, this.props.className)}>
        <Toolbar className={classes.toolbar}>
          <Logo />

          <div
            className={
              isSearchOpen ? classes.navigationSearchOpen : classes.navigation
            }
          >
            <Navigation />
          </div>

          <div className={classes.controls}>
            <div
              style={{ ...(!isSearchOpen && { display: 'none' }) }}
              className={classes.searchContainer}
            >
              <div>{searchElements}</div>
            </div>
            {!isSearchOpen && (
              <IconButton
                {...getIconSharedProps(classes.searchButton)}
                onClick={() => {
                  // wrap in setTimeout to win useClickOutside hook
                  setTimeout(() => setIsSearchOpen(true));
                }}
                id="search-button"
              >
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            )}
            <IconButton
              {...getIconSharedProps()}
              aria-owns={menuEl ? 'menu' : undefined}
              aria-haspopup="true"
              onClick={(e) => this.setMenuEl(e.currentTarget)}
            >
              <img src={widgetsSvg} alt="widgets" />
            </IconButton>

            <Menu
              id="menu"
              anchorEl={menuEl}
              open={Boolean(menuEl)}
              onClose={() => this.setMenuEl(null)}
              classes={{ paper: classes.menu }}
              disableAutoFocusItem
            >
              {menuLinks.map((link) => (
                <ExternalLink
                  key={link.name}
                  href={link.url}
                  className={classes.menuLink}
                  target={link.target}
                >
                  <MenuItem classes={{ root: classes.menuItem }}>
                    {link.name}
                  </MenuItem>
                </ExternalLink>
              ))}
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={this.showTutorial}
                key="DemoGuide"
              >
                Demo Guide
              </MenuItem>
            </Menu>

            <UserDropdown />
          </div>
        </Toolbar>
        <WelcomeTour tutorialSteps={tutorial} showTutorial={tutorialState} />
      </AppBar>
    );
  }
}

const mapStateToProps = ({ tutorial }) => ({
  tutorial: tutorial.data,
  tutorialIsLoading: tutorial.isLoading,
  tutorialHasError: tutorial.hasError
});

const mapDispatchToProps = (dispatch) => ({
  fetchTutorial: () => dispatch(tutorialFetchData())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWrapper);
