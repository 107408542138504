/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import {
  recordingsFetchData,
  recordingsFetchDataDB
} from '../../actions/recordings.action';
import Recording from '../../components/Recording';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { programDeleteRecording } from '../../actions/videoDetails.action';
import { styles } from './RecordingsPage.styles';

class Recordings extends Component {
  componentDidMount() {
    this.props.recordingsFetchDataDB();
  }

  deleteRecording = (id) => {
    this.props.deleteRecording(id);
    this.props.recordingsFetchDataDB();
  };

  render() {
    const { classes, isLoading, isFailed } = this.props;
    if (isLoading) {
      return <Loader loaderProps={{ size: 40 }} fullHeightCentralized />;
    }

    if (isFailed) {
      return <ErrorMessage fullHeightCentralized />;
    }
    return (
      <div className={classes.root}>
        {this.props.recordings.map((recording) => (
          <Recording
            key={recording.id}
            recording={recording}
            deleteRecording={this.deleteRecording}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.recordings.isLoading,
  isFailed: state.recordings.isFailed,
  recordings: state.recordings.data
});

const mapDispatchToProps = (dispatch) => ({
  recordingsFetchData: () => dispatch(recordingsFetchData()),
  recordingsFetchDataDB: () => dispatch(recordingsFetchDataDB()),
  deleteRecording: (id) => dispatch(programDeleteRecording(id))
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Recordings)
);
