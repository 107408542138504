import { useEffect, useRef } from 'react';

import { useVideoStoreDispatch } from '../../store/videoStore';

export const useAutoPlay = (autoPlay: boolean) => {
  const updateVideoStore = useVideoStoreDispatch();

  const isInit = useRef(false);
  useEffect(() => {
    if (isInit.current) return;
    isInit.current = true;
    if (autoPlay) {
      updateVideoStore({
        isPlaying: true,
        // browser will not autoplay video if it's not muted
        volume: 0
      });
    }
  }, [autoPlay, updateVideoStore]);
};
