import React, { HTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import ControlsBlock from './ControlsBlock';
import TimeControl from './TimeControl';

import { useStyles } from './VideoControls.styles';

type Props = HTMLAttributes<HTMLDivElement> & {
  isVisible?: boolean;
};

const VideoControls = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, isVisible = true, ...props }, ref) => {
    const styles = useStyles();
    return (
      <div
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick?.(e);
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className={clsx(className, styles.bar, !isVisible && styles.hidden)}
        {...props}
      />
    );
  }
);
VideoControls.displayName = 'VideoControls';

const TypedVideoControls = VideoControls as typeof VideoControls & {
  ControlsBlock: typeof ControlsBlock;
  TimeControl: typeof TimeControl;
};

TypedVideoControls.ControlsBlock = ControlsBlock;
TypedVideoControls.TimeControl = TimeControl;

export { TypedVideoControls as VideoControls, ControlsBlock, TimeControl };
export default TypedVideoControls;
