import React, { HTMLAttributes } from 'react';

import { useVoice } from './hooks';

import { useStyles } from './VoiceSearch.styles';
import { MicIdle, MicActive } from './assets/icons';
import { MicIcon, CloseIcon } from '../../assets/icons';

type Props = HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void;
  onVoiceSearch?: (text: string) => void;
  size?: 'small' | 'large';
};

const HeaderVoiceSearch = ({
  size = 'large',
  onClose,
  onVoiceSearch,
  ...props
}: Props) => {
  const styles = useStyles(size)();

  const { isSpeaking, SpeechRecognition, currentText } = useVoice(
    onVoiceSearch,
    onClose
  );

  if (!SpeechRecognition) {
    console.error('SpeechRecognition is not supported in this browser');
    return null;
  }

  return (
    <div {...props}>
      <div className={styles.root}>
        <span className={currentText ? '' : styles.empty}>
          {currentText || 'Listening...'}
        </span>
        <div className={styles.micContainer}>
          <MicIcon className={styles.theMic} width="39px" height="39px" />
          <MicIdle
            className={styles.micIdle}
            style={{ opacity: isSpeaking ? 0 : 1 }}
          />
          <MicActive
            className={styles.micActive}
            style={{ opacity: isSpeaking ? 1 : 0 }}
          />
        </div>
        <button
          type="button"
          onClick={(e) => {
            setTimeout(() => onClose?.());
          }}
          className={styles.close}
        >
          {size === 'large' ? 'Close' : <CloseIcon />}
        </button>
      </div>
    </div>
  );
};

export default HeaderVoiceSearch;
