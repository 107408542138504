import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Storyboard, useStoryboard } from '../../api/video';

export const useVideoSlides = (videoElem: HTMLVideoElement | null) => {
  const params = useParams<{ id: string }>();
  const { data = [] } = useStoryboard(params.id);

  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    if (!videoElem) return;

    const handleTimeUpdate = () => setCurrentTime(videoElem.currentTime);

    videoElem.addEventListener('timeupdate', handleTimeUpdate);
    return () => videoElem.removeEventListener('timeupdate', handleTimeUpdate);
  }, [videoElem]);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const getSlideSharedProps = (
    item: Storyboard,
    nextSlide?: Storyboard,
    /**
     * Might be not obvious and anti-pattern because it is used for side-effect, namely, `if (isCurrent && activeIndex !== idx) setActiveIndex(idx)`.
     * However, very useful in this case - reusing this function in different components.
     * */
    idx = 0
  ) => {
    const startTime = +item.start;
    const endTime =
      +item.end || (nextSlide ? +nextSlide.start : videoElem?.duration ?? 0);
    const isCurrent = currentTime >= startTime && currentTime < endTime;

    if (isCurrent && activeIndex !== idx) setActiveIndex(idx);

    return {
      isCurrent,
      onClick: () => {
        if (!videoElem) return;
        videoElem.currentTime = startTime;
      }
    };
  };

  return { data, activeIndex, getSlideSharedProps };
};
