const root = {
  width: '100%',
  display: 'grid',
  gridTemplate: 'auto 1fr / min-content auto auto',
  color: '#fff',
  backgroundColor: '#272628',
  fontFamily: 'Open Sans',
  borderBottom: '1px solid #000'
};

const recordingContainer = {
  gridRowEnd: 'span 2',
  fontFamily: 'Open Sans',
  fontSize: 14,
  alignSelf: 'center',
  justifySelf: 'end',
  paddingRight: 20
};

const description = {
  fontFamily: 'Open Sans',
  fontSize: 14,
  padding: '5px 19px'
};

export const styles = () => ({
  root,
  rootSmall: {
    ...root,
    gridTemplate: 'auto min-content min-content / min-content auto'
  },
  rootSmallest: {
    ...root,
    gridTemplate: 'repeat(4, min-content)'
  },
  thumbnailContainer: {
    gridRowEnd: 'span 2'
  },
  thumbnailContainerSmall: {
    gridRowEnd: 'span 3'
  },
  recordingContainer,
  recordingContainerSmall: {
    ...recordingContainer,
    gridRowEnd: 'span 1',
    padding: '15px 20px',
    paddingTop: 0,
    justifySelf: 'start',
    order: 4
  },
  recordingWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    '&:hover > button': {
      opacity: 1
    }
  },
  remove: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    background: '#272628',
    border: 0,
    padding: 0,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontFamily: 'Open Sans',
    cursor: 'pointer'
  },
  dismissIcon: {
    padding: 1
  },
  dismissText: {
    marginTop: -1,
    marginLeft: 10
  },
  thumbnail: {
    minWidth: 180,
    width: '100%',
    minHeight: 101,
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  dateTileContainer: {
    padding: '17px 19px 0 19px'
  },
  description,
  descriptionSmall: {
    ...description,
    padding: '15px 20px'
  },
  recordingIcon: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: '1px solid #FF453A',
    display: 'inline-flex',
    boxSizing: 'border-box',
    marginRight: 10,

    '&:after': {
      content: '""',
      width: '19px',
      height: '19px',
      backgroundColor: '#FF453A',
      borderRadius: '50%',
      margin: 'auto'
    }
  }
});
