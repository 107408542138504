import React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { tooltipVideoLooping } from '../../../constants/tooltipsHTML';
import CustomTooltip from '../../CustomTooltip';
import { AccountIcon } from '../../../assets/icons';
import { getUserQueryKey, useGetUser, useLogoutUser } from '../../../api/user';
import { useLoopStore } from '../../../stores/loopStore';

import { useStyles as useHeaderStyles } from '../Header.styles';

const UserDropdown = () => {
  const history = useHistory();

  const { data } = useGetUser();
  const user = data?.user;
  const userName = !user ? null : `${user.firstName} ${user.lastName}`;

  const queryClient = useQueryClient();

  const { mutate: logout } = useLogoutUser({
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: getUserQueryKey });
      history.push('/');
    }
  });

  const [userMenuEl, setUserMenuEl] = React.useState<any>(null);

  const { isLooping, setIsLooping } = useLoopStore();

  const styles = useHeaderStyles();

  return (
    <>
      <IconButton
        className={styles.toolbarButton}
        disableRipple
        aria-owns={userMenuEl ? 'userMenu' : undefined}
        aria-haspopup="true"
        onClick={(e) => setUserMenuEl(e.currentTarget)}
      >
        <AccountIcon />
      </IconButton>

      <Menu
        id="userMenu"
        anchorEl={userMenuEl}
        open={Boolean(userMenuEl)}
        onClose={() => setUserMenuEl(null)}
        classes={{ paper: styles.menu }}
        disableAutoFocusItem
      >
        {userName ? (
          [
            <Link to="/admin" className={styles.loginLink} key="admin">
              <MenuItem
                classes={{ root: styles.menuItem }}
                onClick={() => setUserMenuEl(null)}
              >
                Administration
              </MenuItem>
            </Link>,
            <Link to="/user-profile" className={styles.loginLink} key="profile">
              <MenuItem
                classes={{ root: styles.menuItem }}
                onClick={() => setUserMenuEl(null)}
              >
                {userName}
              </MenuItem>
            </Link>,
            <MenuItem
              classes={{ root: styles.menuItem }}
              onClick={() => {
                setUserMenuEl(null);
                logout();
              }}
              key="logout"
            >
              Logout
            </MenuItem>
          ]
        ) : (
          <Link to="/login" className={styles.loginLink}>
            <MenuItem
              classes={{ root: styles.menuItem }}
              onClick={() => setUserMenuEl(null)}
            >
              Login
            </MenuItem>
          </Link>
        )}

        <MenuItem>
          <CustomTooltip html={tooltipVideoLooping} />
          <FormControlLabel
            control={
              <Switch
                checked={isLooping}
                onChange={() => setIsLooping(!isLooping)}
              />
            }
            label="Video Looping"
            labelPlacement="start"
            className={styles.switcher}
            classes={{ label: styles.menuItem }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;
