import React, { HTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import {
  useVideoStoreValue,
  useVideoStoreDispatch
} from '../../store/videoStore';
import { FORWARD_BACKWARD_SECONDS } from '../../constants';

import {
  BackwardIcon,
  ForwardIcon,
  PauseIcon,
  ReplayIcon
} from './assets/playbackIcons';
import { PlayIcon } from '../../../../assets/icons';
import { useStyles } from './PlaybackButtons.styles';
import { useLoopStore } from '../../../../stores/loopStore';

type Props = HTMLAttributes<HTMLDivElement> & {
  onBackwardClick?: () => void;
  onForwardClick?: () => void;
};

const PlaybackButtons = forwardRef<HTMLDivElement, Props>(
  ({ onBackwardClick, onForwardClick, className, ...props }, ref) => {
    const isPlaying = useVideoStoreValue((store) => store.isPlaying);
    const isStarted = useVideoStoreValue((store) => store.isStarted);
    const currentTime = useVideoStoreValue((store) => store.currentTime);
    const duration = useVideoStoreValue((store) => store.duration);
    const updateVideoStore = useVideoStoreDispatch();
    const onPlayClick = () => updateVideoStore({ isPlaying: true });
    const onPauseClick = () => updateVideoStore({ isPlaying: false });

    const styles = useStyles();

    const isLooping = useLoopStore((store) => store.isLooping);

    const isEnded = currentTime === duration && duration !== 0;
    const isReplay = isEnded && !isLooping;

    const label = isReplay ? 'Replay' : isPlaying ? 'Pause' : 'Play';

    return (
      <div className={clsx(styles.buttons, className)} ref={ref} {...props}>
        <button
          {...(!isStarted && { tabIndex: -1 })}
          title={`Backward ${FORWARD_BACKWARD_SECONDS} seconds`}
          type="button"
          onClick={onBackwardClick}
          className={styles.button}
        >
          <BackwardIcon />
        </button>
        <button
          {...(!isStarted && { tabIndex: -1 })}
          type="button"
          onClick={() => {
            if (isReplay) {
              return updateVideoStore({ currentTime: 0, isPlaying: true });
            }
            (isPlaying ? onPauseClick : onPlayClick)();
          }}
          className={styles.button}
          aria-label={label}
          title={label}
        >
          {isReplay ? (
            <ReplayIcon />
          ) : isPlaying ? (
            <PauseIcon />
          ) : (
            <PlayIcon style={{ translate: '1px 0' }} />
          )}
        </button>
        <button
          {...(!isStarted && { tabIndex: -1 })}
          title={`Forward ${FORWARD_BACKWARD_SECONDS} seconds`}
          type="button"
          onClick={onForwardClick}
          className={styles.button}
        >
          <ForwardIcon />
        </button>
      </div>
    );
  }
);
PlaybackButtons.displayName = 'PlaybackButtons';

export default PlaybackButtons;
