// Video List Page

export const FETCH_GROUPS_REQUEST = '[Groups] request to fetch';
export const FETCH_GROUPS_ERROR = '[Groups] request finished with error';
export const FETCH_GROUPS_SUCCESS = '[Groups] data loaded successfully';
export const CLEAR_GROUPS = '[Groups] clear data';

export const FETCH_GROUP_CONTENT_REQUEST = '[Group Content] request to fetch';
export const FETCH_GROUP_CONTENT_ERROR =
  '[Group Content] request finished with error';
export const FETCH_GROUP_CONTENT_SUCCESS =
  '[Group Content] data loaded successfully';

// Channels Page

export const FETCH_PROGRAM_GUIDE_REQUEST = '[Program Guide] request to fetch';
export const FETCH_PROGRAM_GUIDE_ERROR =
  '[Program Guide] request finished with error';
export const FETCH_PROGRAM_GUIDE_SUCCESS =
  '[Program Guide] data loaded successfully';

// Video Page

export const FETCH_VIDEO_REQUEST = '[Video] request to fetch';
export const FETCH_VIDEO_RECORD_REQUEST = '[Video] request to fetch';
export const FETCH_VIDEO_ERROR = '[Video] request finished with error';
export const FETCH_VIDEO_SUCCESS = '[Video] data loaded successfully';
export const FETCH_RECORDING_VIDEO_SUCCESS =
  '[Video Record] data loaded successfully';
export const CLEAR_VIDEO = '[Video] clear data';

export const FETCH_VIDEO_DETAILS_ERROR =
  '[Video Details] request finished with error';
export const FETCH_VIDEO_DETAILS_SUCCESS =
  '[Video Details] data loaded successfully';
export const CLEAR_VIDEO_DETAILS = '[Video Details] clear data';
export const FETCH_VIDEO_RECORDING_REQUEST =
  '[Video Recording] request to fetch';
export const FETCH_VIDEO_RECORDING_SUCCESS =
  '[Video Recording] data loaded successfully';
export const FETCH_VIDEO_RECORDINGS_SUCCESS =
  '[Video Recordings] data loaded successfully';
export const FETCH_VIDEO_RECORDINGS_POST_SUCCESS =
  '[Video Recordings] data posted successfully';

export const FETCH_VIDEO_NAV_REQUEST = '[Video Nav] request to fetch';
export const FETCH_VIDEO_NAV_ERROR = '[Video Nav] request finished with error';
export const FETCH_VIDEO_NAV_SUCCESS = '[Video Nav] data loaded successfully';
export const CLEAR_VIDEO_NAV = '[Video Nav] clear data';

export const FETCH_VIDEO_PROMO_REQUEST = '[Video Promo] request to fetch';
export const FETCH_VIDEO_PROMO_ERROR =
  '[Video Promo] request finished with error';
export const FETCH_VIDEO_PROMO_SUCCESS =
  '[Video Promo] data loaded successfully';
export const CLEAR_VIDEO_PROMO = '[Video Promo] clear data';

export const FETCH_VIDEO_PROMO_TIME_REQUEST =
  '[Video Promo Time] request to fetch';
export const FETCH_VIDEO_PROMO_TIME_ERROR =
  '[Video Promo Time] request finished with error';
export const FETCH_VIDEO_PROMO_TIME_SUCCESS =
  '[Video Promo Time] data loaded successfully';
export const CLEAR_VIDEO_PROMO_TIME = '[Video Promo Time] clear data';

export const SET_VIDEO_START_TIME = '[Video Start Time] set value';
export const CLEAR_VIDEO_START_TIME = '[Video Start Time] clear value';

export const SET_VIDEO_CURRENT_TIME = '[Video Current Time] set value';
export const CLEAR_VIDEO_CURRENT_TIME = '[Video Current Time] clear value';

export const TOGGLE_VIDEO_LOOPING = '[Video Looping] toggle value';

// Header

export const FETCH_TUTORIAL_REQUEST = '[Tutorial] request to fetch';
export const FETCH_TUTORIAL_ERROR = '[Tutorial] request finished with error';
export const FETCH_TUTORIAL_SUCCESS = '[Tutorial] data loaded successfully';

export const LOGOUT_USER_REQUEST = '[Logout] request to logout';
export const LOGOUT_USER_ERROR = '[Logout] request finished with error';
export const LOGOUT_USER_SUCCESS = '[Logout] request finished with success';

// Dashboard

export const FETCH_DASHBOARD_RECOMMENDATIONS_REQUEST =
  '[Dashboard Recommendations] request to fetch';
export const FETCH_DASHBOARD_RECOMMENDATIONS_ERROR =
  '[Dashboard Recommendations] request finished with error';
export const FETCH_DASHBOARD_RECOMMENDATIONS_SUCCESS =
  '[Dashboard Recommendations] data loaded successfully';

export const FETCH_DASHBOARD_HOT_REQUEST = '[Dashboard Hot] request to fetch';
export const FETCH_DASHBOARD_HOT_ERROR =
  '[Dashboard Hot] request finished with error';
export const FETCH_DASHBOARD_HOT_SUCCESS =
  '[Dashboard Hot] data loaded successfully';

export const FETCH_DASHBOARD_TOP_CHANNELS_REQUEST =
  '[Dashboard Top Channels] request to fetch';
export const FETCH_DASHBOARD_TOP_CHANNELS_ERROR =
  '[Dashboard Top Channels] request finished with error';
export const FETCH_DASHBOARD_TOP_CHANNELS_SUCCESS =
  '[Dashboard Top Channels] data loaded successfully';

export const FETCH_DASHBOARD_FAVORITE_CHANNELS_REQUEST =
  '[Dashboard Favorite Channels] request to fetch';
export const FETCH_DASHBOARD_FAVORITE_CHANNELS_ERROR =
  '[Dashboard Favorite Channels] request finished with error';
export const FETCH_DASHBOARD_FAVORITE_CHANNELS_SUCCESS =
  '[Dashboard Favorite Channels] data loaded successfully';

export const FETCH_DASHBOARD_FAVORITE_VIDEOS_REQUEST =
  '[Dashboard Favorite Videos] request to fetch';
export const FETCH_DASHBOARD_FAVORITE_VIDEOS_ERROR =
  '[Dashboard Favorite Videos] request finished with error';
export const FETCH_DASHBOARD_FAVORITE_VIDEOS_SUCCESS =
  '[Dashboard Favorite Videos] data loaded successfully';

// Recordings
export const FETCH_RECORDINGS_REQUEST = '[Recordings] request to fetch';
export const FETCH_RECORDINGS_ERROR =
  '[Recordings] request finished with error';
export const FETCH_RECORDINGS_SUCCESS = '[Recordings] data loaded successfully';

// Video Content Management
export const FETCH_LIBRARY_REQUEST =
  '[Video Content Management Library] request to fetch';
export const FETCH_LIBRARY_ERROR =
  '[Video Content Management Library] request finished with error';
export const FETCH_LIBRARY_SUCCESS =
  '[Video Content Management Library] data loaded successfully';

export const DELETE_LIBRARY_VIDEO_REQUEST =
  '[Video Content Management Video] request to delete';
export const DELETE_LIBRARY_VIDEO_ERROR =
  '[Video Content Management Video] delete request finished with error';
export const DELETE_LIBRARY_VIDEO_SUCCESS =
  '[Video Content Management Video] video deleted successfully';

export const ADD_LIBRARY_VIDEO_REQUEST =
  '[Video Content Management Video] request to add';
export const ADD_LIBRARY_VIDEO_ERROR =
  '[Video Content Management Video] add request finished with error';
export const ADD_LIBRARY_VIDEO_SUCCESS =
  '[Video Content Management Video] video added successfully';

export const FETCH_VIDEO_LOGS_REQUEST =
  '[Video Content Management Video Logs] request to fetch';
export const FETCH_VIDEO_LOGS_ERROR =
  '[Video Content Management Video Logs] request finished with error';
export const FETCH_VIDEO_LOGS_SUCCESS =
  '[Video Content Management Video Logs] data loaded successfully';
export const FETCH_VIDEO_LOGS_ON_CHUNK =
  '[Video Content Management Video Logs] on chunk';
export const CLEAR_VIDEO_LOGS = '[Video Content Management Video Logs] clear';

export const UPDATE_LIBRARY_REQUEST =
  '[Video Content Management Library] request to update';
export const UPDATE_LIBRARY_ERROR =
  '[Video Content Management Library] update request finished with error';
export const UPDATE_LIBRARY_SUCCESS =
  '[Video Content Management Library] video updated successfully';

export const UPDATE_LIBRARY_VIDEO_REQUEST =
  '[Video Content Management Video] request to update';
export const UPDATE_LIBRARY_VIDEO_ERROR =
  '[Video Content Management Video] update request finished with error';
export const UPDATE_LIBRARY_VIDEO_SUCCESS =
  '[Video Content Management Video] video updated successfully';

export const CLEAR_LIBRARY = '[Video Content Management Library] clear data';
