import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import AdList from '../../components/AdList';
import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import VideoPageBannerSection from '../../components/VideoPageBannerSection';
import { useAds, useBrands, useStoryboard, useVideo } from '../../api/video';
import VideoContainer from '../../components/video/VideoContainer';
import Storyboard from '../../components/Storyboard';
import TimelineCarousel from '../../components/TimelineCarousel';
import VideoLayout from '../../components/video/VideoLayout';

import { useStyles } from './VideoPage.styles';

const VideoPage = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation<{ position: string; isFromSearch: boolean }>();
  const id = params.id;
  const { position, isFromSearch } = location.state || {};

  const { data: video, isLoading, isError, error, isSuccess } = useVideo(id);
  const { isError: isStoryboardError } = useStoryboard(id);
  const { isError: isAdsError } = useAds(id);
  const { isError: isBrandsError } = useBrands(id);
  const isAdvertisementError = isAdsError || isBrandsError;

  const videoRef = useRef<HTMLVideoElement>(null);
  const startSet = useRef(false);
  useEffect(() => {
    if (position !== undefined && videoRef.current) {
      const videoElem = videoRef.current;
      startSet.current = true;
      videoElem.currentTime = +position;
    }
  }, [position, isSuccess]);

  const styles = useStyles();

  if (isLoading)
    return <Loader loaderProps={{ size: 80 }} fullHeightCentralized />;
  if (isError) <ErrorMessage error={String(error)} fullHeightCentralized />;

  return (
    <div>
      {video && (
        <VideoPageBannerSection
          poster={video.poster}
          title={video.title}
          description={video.description}
          tagCloud={video.tagCloud}
        />
      )}
      <VideoLayout>
        {!isAdvertisementError && <AdList videoElem={videoRef.current} />}

        <div>
          {video && (
            <VideoContainer
              style={{ marginBottom: !isStoryboardError ? 12 : 0 }}
              videoObject={video}
              ref={videoRef}
              autoplay={!isFromSearch}
              disablePoster={isFromSearch}
            />
          )}
          {!isStoryboardError && (
            <div className={styles.timelineContainer}>
              <TimelineCarousel videoElem={videoRef.current} />
            </div>
          )}
        </div>

        {!isStoryboardError && <Storyboard videoElem={videoRef.current} />}
      </VideoLayout>
    </div>
  );
};

export default VideoPage;
