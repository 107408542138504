import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    translate: '-2px -2px',
    '& [role=tablist]': {
      gap: 36
    }
  },
  tab: {
    color: '#8A8C8E',
    fontFamily: 'Open Sans',
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '29px',
    textAlign: 'center',
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    minHeight: 'unset',
    minWidth: 'unset',
    opacity: 1,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },

    '&[aria-selected="true"]': {
      fontWeight: 600,
      color: '#fff'
    },

    '&:focus-visible': {
      outline: '1px solid #fff',
      outlineOffset: -1
    }
  }
}));
