import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  card: {
    height: 124,
    minHeight: 124,
    position: 'relative',
    transition: 'opacity .2s ease'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    textDecoration: 'none',
    width: '100%',
    height: '100%',
    minHeight: 124,
    flexDirection: 'column',
    padding: 10,
    color: 'inherit'
  },
  listItemTiming: {
    backgroundColor: '#f37037',
    color: '#fff',
    fontSize: '12px',
    textAlign: 'center',
    padding: '8px 5px',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: '.4',
    transition: 'all .4s ease'
  },
  listItemTimingAfter: {
    display: 'block',
    height: '100%',
    width: 0,
    position: 'absolute',
    top: 0,
    right: '-14px',
    borderTop: '29px solid #f37037',
    borderRight: '14px solid transparent',
    borderBottom: '0px solid transparent',
    borderLeft: '0px solid transparent'
  },
  media: {
    width: 140,
    height: 70,
    objectFit: 'contain',
    borderRadius: 3
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}));
