import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import DashboardPage from '../pages/DashboardPage';
import VideoListPage from '../pages/VideoListPage';
import VideoPage from '../pages/VideoPage';
import RecordingVideoPage from '../pages/RecordingVideoPage';
import ChannelsPage from '../pages/ChannelsPage';
import ChannelPage from '../pages/ChannelPage';
import RecordingsPage from '../pages/RecordingsPage';
import LoginPage from '../pages/LoginPage';
import UserProfilePage from '../pages/UserProfilePage';
import AdministrationPage from '../pages/AdministrationPage';
import NotFoundPage from '../pages/NotFoundPage';
import SearchPage from '../pages/SearchPage';

import PrivateRoute from './PrivateRoute';

const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/videos" />} />
    <Route exact path="/home" component={DashboardPage} />
    <Route exact path="/videos" component={VideoListPage} />
    <Route exact path="/videos/:id" component={VideoPage} />
    <Route exact path="/search" component={SearchPage} />
    <Route exact path="/channels" component={ChannelsPage} />
    <Route exact path="/channels/:id" component={ChannelPage} />
    <Route exact path="/recordings" component={RecordingsPage} />
    <Route exact path="/recordings/:id" component={RecordingVideoPage} />
    <Route exact path="/login" component={LoginPage} />
    <PrivateRoute exact path="/user-profile" component={UserProfilePage} />
    <PrivateRoute path="/admin" component={AdministrationPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
