import React, { forwardRef, HTMLAttributes } from 'react';
import clsx from 'clsx';
import * as SliderRadix from '@radix-ui/react-slider';

import {
  useVideoStoreValue,
  useVideoStoreDispatch
} from '../../store/videoStore';

import { useStyles } from './TimeControl.styles';

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  onTimeChange: (time: number) => void;
};

const formatDuration = (seconds: number) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const pad = (string: string | number) => {
  return ('0' + string).slice(-2);
};

const TimeControl = forwardRef<HTMLDivElement, Props>(
  ({ onTimeChange, className, ...props }: Props, ref) => {
    const currentTime = useVideoStoreValue((store) => store.currentTime);
    const duration = useVideoStoreValue((store) => store.duration);
    const isStarted = useVideoStoreValue((store) => store.isStarted);
    const updateVideoStore = useVideoStoreDispatch();

    const styles = useStyles();
    return (
      <div ref={ref} className={clsx(className, styles.timeRoot)} {...props}>
        <SliderRadix.Root
          defaultValue={[0]}
          max={duration}
          step={1}
          value={[currentTime]}
          onValueChange={(value) => {
            onTimeChange(value[0]);
            updateVideoStore({ currentTime: value[0] });
          }}
          className={styles.sliderRoot}
        >
          <div className={styles.sliderBuffer} />
          <SliderRadix.Track className={styles.sliderTrack}>
            <SliderRadix.Range className={styles.sliderRange} />
          </SliderRadix.Track>
          <SliderRadix.Thumb
            {...(!isStarted && { tabIndex: -1 })}
            className={styles.sliderThumb}
            aria-label="Time"
            data-current-time={formatDuration(currentTime)}
          />
        </SliderRadix.Root>
        <div className={styles.text}>
          {formatDuration(currentTime)} / {formatDuration(duration)}
        </div>
      </div>
    );
  }
);
TimeControl.displayName = 'TimeControl';

export default TimeControl;
