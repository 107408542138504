import React, { ReactNode } from 'react';

import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { useGetUser, User } from '../../api/user';

import { useStyles } from './UserProfilePage.styles';

const UserProfilePage = () => {
  const styles = useStyles();

  const { data, isLoading, isError, error } = useGetUser();
  const user = data?.user ?? ({} as User);

  let content: ReactNode | null = null;

  if (isLoading) {
    content = <Loader loaderProps={{ size: 80 }} fullHeightCentralized />;
  }

  if (isError) {
    content = <ErrorMessage error={error as string} fullHeightCentralized />;
  }

  if (!isLoading && !isError) {
    content = (
      <>
        <p>
          Name: {user.firstName} {user.lastName}
        </p>
        <p>Age: {user.age}</p>
        <p>Gender: {user.sex}</p>
        <p>Zip Code: {user.zipcode}</p>
      </>
    );
  }

  return (
    <div className={styles.userPage}>
      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default UserProfilePage;
