import {
  FETCH_RECORDINGS_REQUEST,
  FETCH_RECORDINGS_ERROR,
  FETCH_RECORDINGS_SUCCESS
} from '../constants/actionTypes';

export const initialState = {
  isLoading: false,
  isFailed: false,
  data: []
};

export function recordings(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECORDINGS_REQUEST:
      return { ...state, isLoading: true, data: [] };
    case FETCH_RECORDINGS_ERROR:
      return { ...state, isLoading: false, isFailed: true };
    case FETCH_RECORDINGS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };

    default:
      return state;
  }
}
