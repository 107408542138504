import { useEffect, useState } from 'react';

import { useGetUser } from '../../../api/user';

const fiveMinutes = 1000 * 60 * 5;

/** Refetch user every 5 minutes (user data required for different parts of the app) */
export const useUserPolling = () => {
  const [refetchEnabled, setRefetchEnabled] = useState(false);
  const { isSuccess } = useGetUser({
    refetchInterval: refetchEnabled ? fiveMinutes : false
  });
  useEffect(() => {
    // polling only when user is successfully fetched
    setRefetchEnabled(isSuccess);
  }, [isSuccess]);

  // TODO: remove this after some time
  // clear legacy auth data
  useEffect(() => {
    localStorage.removeItem('auth.token');
    localStorage.removeItem('auth.user');
  }, []);
};
