import React, { forwardRef, HTMLAttributes } from 'react';
import * as SliderRadix from '@radix-ui/react-slider';
import clsx from 'clsx';

import {
  useVideoStoreValue,
  useVideoStoreDispatch
} from '../../store/videoStore';

import { SoundHigh, SoundMedium, SoundLow, SoundMute } from './assets/sound';
import { useStyles } from './SoundControl.styles';
import { usePrevious } from '../../../../hooks';

const SoundControl = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const isStarted = useVideoStoreValue((store) => store.isStarted);
    const volume = useVideoStoreValue((store) => store.volume);
    const previousVolume = usePrevious(volume);

    const updateVideoStore = useVideoStoreDispatch();

    const styles = useStyles();

    return (
      <div
        className={clsx(className, styles.iconContainer)}
        {...props}
        ref={ref}
      >
        <button
          title="Sound"
          type="button"
          className={styles.button}
          {...(!isStarted && { tabIndex: -1 })}
          onClick={(e) => {
            e.stopPropagation();
            updateVideoStore({
              volume: volume === 0 ? previousVolume || 1 : 0
            });
          }}
        >
          {volume >= 0.9 && <SoundHigh />}
          {volume < 0.9 && volume >= 0.5 && <SoundMedium />}
          {volume < 0.5 && volume > 0 && <SoundLow />}
          {volume === 0 && <SoundMute height="24px" />}
        </button>
        <div className={styles.sliderContainer}>
          <div className={styles.sliderLayer}>
            <SliderRadix.Root
              orientation="vertical"
              defaultValue={[1]}
              max={1}
              step={0.1}
              value={[volume]}
              onValueChange={(value) => {
                updateVideoStore({ volume: value[0] });
              }}
              className={styles.sliderRoot}
            >
              <SliderRadix.Track className={styles.sliderTrack}>
                <SliderRadix.Range className={styles.sliderRange} />
              </SliderRadix.Track>
              <SliderRadix.Thumb
                className={styles.sliderThumb}
                aria-label="Volume"
              />
            </SliderRadix.Root>
          </div>
        </div>
      </div>
    );
  }
);
SoundControl.displayName = 'SoundControl';

export default SoundControl;
