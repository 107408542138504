import React from 'react';
import { useParams } from 'react-router-dom';

import { useChannel, ChannelMulti } from '../../api/channels';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import VideoContainer from '../../components/video/VideoContainer';

import { useStyles } from './ChannelMultiScreenPage.styles';

const ChannelMultiScreenPage = () => {
  const styles = useStyles();
  const params = useParams<{ id: string }>();
  const { data, isLoading, isError, error } = useChannel(params.id);

  if (isLoading) {
    return <Loader loaderProps={{ size: 80 }} fullHeightCentralized />;
  }

  if (isError) {
    return <ErrorMessage error={String(error)} fullHeightCentralized />;
  }

  const data_ = data as ChannelMulti;
  const { title, description, screens } = data_;

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <span className={styles.description}>{description}</span>
      </div>
      <div className={screens.length > 1 ? styles.grid : styles.singleVideo}>
        {screens.length
          ? screens.map((item, index: number) => (
              <div key={index}>
                <h4 className={styles.videoTitle}>{item.title}</h4>
                <span className={styles.videoDescription}>
                  {item.description}
                </span>
                <VideoContainer videoObject={item} parentObject={data_} />
              </div>
            ))
          : // TODO: make sure it looks good
            'No screens'}
      </div>
    </>
  );
};

export default ChannelMultiScreenPage;
