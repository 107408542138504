import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    border: 0,
    cursor: 'pointer',
    padding: 0,

    height: 101,
    width: 38,
    minWidth: 38,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: 'transparent',
    transition: 'background-color 300ms',
    '&:hover': {
      backgroundColor: 'rgba(161, 161, 161, .2)'
    },
    '&:active': {
      backgroundColor: 'rgba(161, 161, 161, .4)'
    },

    '&:focus-visible': {
      outline: `1px solid ${theme.palette.secondary.main}`
    },

    '& svg': {
      fill: '#D8D8D8'
    }
  }
}));
