import React, { HTMLAttributes } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';

import { useStyles } from './CarouselArrow.styles';

type Props = HTMLAttributes<HTMLButtonElement> & {
  onClick?: () => void;
  isNext?: boolean;
};

const CarouselArrow = ({
  isNext = false,
  className,
  style,
  ...props
}: Props) => {
  const styles = useStyles();

  return (
    <button
      type="button"
      className={clsx(styles.button, className)}
      style={{
        transform: `rotate(${isNext ? 0 : 180}deg)`,
        ...style
      }}
      {...props}
    >
      <NavigateNextIcon />
    </button>
  );
};

export default CarouselArrow;
