export const styles = (theme) => ({
  root: {
    padding: '10px 50px 20px 50px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  divider: {
    display: 'table',
    whiteSpace: 'nowrap',
    paddingRight: '20px',
    marginBottom: '15px',
    lineHeight: '24px',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    '&:after': {
      display: 'table-cell',
      position: 'relative',
      width: '100%',
      top: '12px',
      left: '20px',
      borderTop: '2px solid',
      borderTopColor: theme.palette.primary.main,
      opacity: 0.5,
      content: "''"
    }
  },
  flexContainer: {
    justifyContent: 'flex-start'
  },
  scrollButton: {
    color: '#D8D8D8',
    '& svg': {
      fontSize: 40
    }
  },
  cardWrapper: {
    position: 'relative',
    minHeight: '260px',
    width: '300px',
    minWidth: '300px',
    marginRight: '15px',
    marginLeft: '15px'
  },
  card: {
    maxWidth: 320,
    backgroundColor: 'rgba(255,255,255,0.12)',
    borderRadius: 0,
    margin: 'auto'
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit'
  },
  cardFocusHighlight: {
    backgroundColor: 'white'
  },
  image: {
    height: 180,
    position: 'relative',
    backgroundSize: '135%'
  },
  imageOnHover: {
    height: 180,
    position: 'relative',
    display: 'none'
  },
  content: {
    padding: '15px'
  },
  title: {
    whiteSpace: 'normal',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '18px',
    lineHeight: '24px',
    height: '50px',
    overflow: 'hidden',
    display: 'block',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  }
});
