/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import { styles } from './CustomTooltip.styles';

const CustomTooltip = ({ classes, html }) => (
  <Tooltip
    interactive
    title={<div dangerouslySetInnerHTML={{ __html: html }} />}
    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
  >
    <Button disableRipple className={classes.tooltipButton}>
      <HelpIcon className={classes.tooltipIcon} />
    </Button>
  </Tooltip>
);

export default withStyles(styles)(CustomTooltip);
