export const styles = (theme) => ({
  tooltipIcon: {
    opacity: '0.8',
    color: theme.palette.secondary.main,
    fontSize: '18px'
  },
  tooltipButton: {
    minWidth: 'auto',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  popper: {
    opacity: 1
  },
  tooltip: {
    borderRadius: 0,
    transform: 'none!important'
  }
});
