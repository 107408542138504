import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  loader: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  progressDiv: {
    justifyContent: 'center',
    display: 'flex'
  },
  progress: {
    animationDuration: '2000ms'
  }
}));
