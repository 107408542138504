import { useInitAiSearch, useSearchAi } from '../../api/searchAi';
import {
  LOADING_TXT,
  useAddMsg,
  useSearchStore,
  useSetSearchQueryStr
} from '../../stores';

export const useAiChat = () => {
  const addMsg = useAddMsg();
  const setSearchQueryStr = useSetSearchQueryStr();
  const setIsMutating = useSearchStore((state) => state.setIsMutating);

  const { data: aiBaseData, ...restInitAi } = useInitAiSearch();
  const threadId = aiBaseData?.id ?? '';

  const { mutateAsync } = useSearchAi();

  const onAiSearch = async (query: string) => {
    addMsg(query, false);
    setIsMutating(true);

    // if threadId is not available, refetch the data
    if (!threadId && !restInitAi.isLoading && !restInitAi.isFetching) {
      restInitAi.refetch();
      return;
    } else if (!threadId && (restInitAi.isLoading || restInitAi.isFetching)) {
      setTimeout(() => onAiSearch(query), 500);
      return;
    }

    addMsg(LOADING_TXT, true);
    try {
      const { keywords, response } = await mutateAsync({ threadId, query });
      setSearchQueryStr(keywords);
      addMsg(response, true, true);
    } catch (e) {
      console.error(e);
      addMsg('Failed to get response from Assistant...', true, true);
    } finally {
      setIsMutating(false);
    }
  };

  return { onAiSearch, initAiData: restInitAi };
};
