import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: 50,
    marginLeft: 60
  },
  title: {
    color: theme.palette.primary.contrastText,
    textShadow: '2px 2px 0px #000',
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    fontSize: 32,
    fontFamily: 'Open Sans',
    display: 'block',
    width: 'fit-content',
    margin: 0
  },
  description: {
    color: theme.palette.primary.contrastText,
    display: 'block',
    marginTop: 16
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 20,
    rowGap: 10,
    padding: '0 20px',
    marginTop: 25
  },
  singleVideo: {
    width: '100%',
    aspectRatio: '16 / 9',
    padding: '0 20px',
    margin: '20px 0 30px 0',

    '& > div > div': {
      padding: '0 20px'
    }
  },
  videoTitle: {
    color: '#fff',
    marginLeft: 42,
    borderBottom: '2px solid #f37037',
    display: 'inline-block',
    fontFamily: 'Open Sans',
    marginBottom: 15,
    fontSize: 20
  },
  videoDescription: {
    color: '#fff',
    marginLeft: 42,
    display: 'block',
    marginBottom: 25
  }
}));
