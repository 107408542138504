import React, { HTMLAttributes, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { capitalizeFirstChar, getImageUrl } from '../../shared';
import { useVideoSlides } from '../../hooks';

import SlidesTitle from '../VideoSlides/SlidesTitle';
import VideoSlides from '../VideoSlides';
import VideoSlide from '../VideoSlides/VideoSlide';
import { tooltipStoryBoard } from '../../constants/tooltipsHTML';

import { useStyles } from './Storyboard.styles';

type Props = HTMLAttributes<HTMLDivElement> & {
  videoElem: HTMLVideoElement | null;
};

const numToTime = (num: number) => {
  const hours = String(Math.floor(num / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((num % 3600) / 60)).padStart(2, '0');
  const seconds = String(Math.floor(num % 60)).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

const Storyboard = ({ videoElem, ...props }: Props) => {
  const { data, activeIndex, getSlideSharedProps } = useVideoSlides(videoElem);

  const slidesRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!slidesRef.current || activeIndex === null) return;

    const slides = slidesRef.current.querySelectorAll('li');
    const slide = slides[activeIndex] as HTMLElement;

    if (!slide) return console.warn('Slide not found');

    const parentRect = slidesRef.current.getBoundingClientRect();
    const slideRect = slide.getBoundingClientRect();

    slidesRef.current.scrollTo({
      top:
        slide.offsetTop -
        slidesRef.current.offsetTop -
        parentRect.height / 2 +
        slideRect.height / 2,
      behavior: 'smooth'
    });
  }, [activeIndex]);

  const styles = useStyles();

  return data?.length ? (
    <div {...props}>
      <SlidesTitle tooltipHtml={tooltipStoryBoard}>Storyboard</SlidesTitle>
      <VideoSlides ref={slidesRef}>
        {data.map((slide, idx) => {
          const description = capitalizeFirstChar(slide.description);

          const { isCurrent, onClick } = getSlideSharedProps(
            slide,
            data[idx + 1],
            idx
          );

          return (
            <VideoSlide
              key={idx}
              className={clsx(styles.card, isCurrent && styles.activeCard)}
              tabIndex={1}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                  e.preventDefault();
                  onClick();
                }
              }}
              onClick={onClick}
            >
              <div
                className={styles.img}
                style={{
                  backgroundImage: `url(${getImageUrl(slide.thumbnail) ?? 'http://nure.ua/wp-content/uploads/images.png'})`
                }}
              />
              <div className={styles.text}>
                <div className={styles.description} title={description}>
                  {description}
                </div>
                <span className={styles.time}>
                  Start at {numToTime(+slide.start)}
                </span>
              </div>
            </VideoSlide>
          );
        })}
      </VideoSlides>
    </div>
  ) : null;
};

export default Storyboard;
