import React, { useState } from 'react';
import { FormControl, Input, Button, Typography } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';

import Loader from '../../components/Loader';
import { getUserQueryKey, useLoginUser } from '../../api/user/userQuery';

import { useLoggedInRedirect } from './hooks';

import { useStyles } from './LoginPage.styles';

const LoginPage = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const queryClient = useQueryClient();

  const styles = useStyles();

  const { mutateAsync, isLoading, isError, error } = useLoginUser();

  const redirect = useLoggedInRedirect();

  return (
    <div className={styles.loginPage}>
      <div className={styles.content}>
        <form
          name="login"
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            mutateAsync({ uid: userId, passwd: password }).then(() => {
              queryClient.invalidateQueries(getUserQueryKey);
              redirect();
            });
          }}
        >
          <FormControl error={isError}>
            <Input
              id="user-id"
              name="userId"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              classes={{
                input: styles.input,
                underline: styles.cssUnderline
              }}
              placeholder="User Id"
            />
          </FormControl>
          <FormControl error={isError}>
            <Input
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              classes={{
                input: styles.input,
                underline: styles.cssUnderline
              }}
              placeholder="Password"
              autoComplete="on"
            />
          </FormControl>
          {isError && (
            <Typography
              align="center"
              color="error"
              variant="subtitle2"
              className={styles.errorMessage}
            >
              {error === 'Unauthorized'
                ? 'Invalid User Id or Password'
                : String(error)}
            </Typography>
          )}
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            className={styles.button}
            type="submit"
          >
            Login
          </Button>
        </form>
        {isLoading && (
          <div className={styles.loader}>
            <Loader loaderProps={{ size: 80 }} fullHeightCentralized />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
