import React from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from './Logo.styles';
import logoSvg from './assets/logo-grey.svg';

const Logo = () => {
  const styles = useStyles();
  return (
    <Link to="/" className={styles.link}>
      <img src={logoSvg} alt="Cloud Media Platform" className={styles.logo} />
      <h1 className={styles.title}>Cloud Media Platform</h1>
    </Link>
  );
};

export default Logo;
