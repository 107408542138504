import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MatchingsPage from '../pages/MatchingsPage';
import VideoContentManagementPage from '../pages/VideoContentManagementPage';
import ChannelsContentManagementPage from '../pages/ChannelsContentManagementPage';
import PlatformIntelligencePage from '../pages/PlatformIntelligencePage';
import PlatformAnalyticsPage from '../pages/PlatformAnalyticsPage';
import PlatformMonitoringPage from '../pages/PlatformMonitoringPage';
import NotFoundPage from '../pages/NotFoundPage';
import TranscodingPage from '../pages/TranscodingPage';
import GenAiVideoPage from '../pages/GenAiVideoPage';
import GenAiVideoAdPage from '../pages/GenAiVideoPage/GenAiVideoAdPage';

const AdminRoutes = () => (
  <Switch>
    <Redirect exact from="/admin" to="/admin/cms" />
    <Redirect exact from="/admin/cms" to="/admin/cms/videos" />
    <Route
      exact
      path="/admin/cms/videos"
      component={VideoContentManagementPage}
    />
    <Route
      exact
      path="/admin/cms/channels"
      component={ChannelsContentManagementPage}
    />
    <Route
      exact
      path="/admin/cms/videos/matchings/:id"
      component={MatchingsPage}
    />
    <Route exact path="/admin/ml" component={PlatformIntelligencePage} />
    <Route exact path="/admin/ml/video/:id" component={GenAiVideoPage} />
    <Route exact path="/admin/ml/video/ad/:id" component={GenAiVideoAdPage} />
    <Route exact path="/admin/analytics" component={PlatformAnalyticsPage} />
    <Route exact path="/admin/apm" component={PlatformMonitoringPage} />
    <Route exact path="/admin/transcoding" component={TranscodingPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default AdminRoutes;
