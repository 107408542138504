import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@keyframes video-react-spinner-spin': {
    '100%': {
      transform: 'rotate(1turn)'
    }
  },
  '@keyframes video-react-spinner-fade': {
    '0%': {
      borderTopColor: '#73859f'
    },
    '20%': {
      borderTopColor: '#73859f'
    },
    '35%': {
      borderTopColor: '#fff'
    },
    '60%': {
      borderTopColor: '#73859f'
    },
    '100%': {
      borderTopColor: '#73859f'
    }
  },
  loader: {
    border: '6px solid #2b333fb3',
    borderRadius: 25,
    boxSizing: 'border-box',
    height: 50,
    width: 50,
    opacity: '.85',

    '&&:after': {
      animationDelay: '.44s'
    },
    '&:before, &:after': {
      content: '""',
      animation:
        '$video-react-spinner-spin 1.1s cubic-bezier(.6,.2,0,.8) infinite, $video-react-spinner-fade 1.1s linear infinite',
      border: 'inherit',
      borderColor: '#fff #0000 #0000',
      borderTopColor: '#fff',
      borderRadius: 'inherit',
      boxSizing: 'inherit',
      height: 'inherit',
      margin: -6,
      opacity: 1,
      position: 'absolute',
      width: 'inherit'
    }
  }
}));
