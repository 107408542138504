import React, { HTMLAttributes, ReactNode } from 'react';
import { UseSelectSelectedItemChange, useSelect } from 'downshift';
import clsx from 'clsx';

import { useVideoStoreValue } from '../../store/videoStore';

import { useStyles } from './SelectControl.styles';

type Props<T> = HTMLAttributes<HTMLDivElement> & {
  items: T[];
  initialSelectedItem: T;
  renderItem: (item: T) => ReactNode;
  itemToString: (item: T | null) => string;
  titleTooltip?: string;
  onSelectedItemChange?: (changes: UseSelectSelectedItemChange<T>) => void;
  renderSelectedItem?: (item: T | null) => ReactNode;
};

const SelectControl = <T,>({
  items,
  renderItem,
  itemToString,
  initialSelectedItem,
  titleTooltip,
  onSelectedItemChange,
  renderSelectedItem,
  className,
  ...props
}: Props<T>) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items,
    itemToString,
    initialSelectedItem,
    onSelectedItemChange
  });

  const isStarted = useVideoStoreValue((store) => store.isStarted);

  const styles = useStyles();

  return (
    <div className={clsx(styles.selectRoot, className)} {...props}>
      <div
        className={styles.title}
        {...getToggleButtonProps()}
        title={titleTooltip}
        {...(!isStarted && { tabIndex: -1 })}
      >
        {renderSelectedItem
          ? renderSelectedItem(selectedItem)
          : itemToString(selectedItem)}
      </div>
      <ul className={styles.list} {...getMenuProps()}>
        {isOpen &&
          items.map((item, idx) => (
            <li
              className={clsx(
                styles.listItem,
                highlightedIndex === idx && styles.highlightedItem,
                selectedItem === item && styles.selectedItem
              )}
              key={idx}
              {...getItemProps({ item, idx })}
            >
              {renderItem(item)}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SelectControl;
