/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { groupsFetchData, groupsClearData } from '../../actions/groups.action';
import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import Billboard from '../../components/Billboard';

import VideoGroup from './VideoListGroup';

import { styles } from './VideoListPage.styles';

class VideoListPage extends Component {
  componentDidMount() {
    this.props.fetchData();
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  render() {
    const { classes, groups, areLoading, haveError, error } = this.props;

    let content = null;
    let billboard = null;

    if (groups) {
      const item = groups[0]?.items?.[0];

      content = groups.map((group) => (
        <VideoGroup group={group} key={group.id} />
      ));

      billboard = item ? (
        <Billboard
          id={item.id}
          poster={item.poster}
          src={
            item.src_list
              ? item.src_list[0].src
              : 'https://accelerator.media.cloud/media/gl.mp4'
          }
          isHLS={
            item.src_list
              ? item.src_list[0].type === 'application/x-mpegURL'
              : false
          }
          title={item.title}
          description={item.description}
        />
      ) : null;
    }

    if (areLoading) {
      content = <Loader loaderProps={{ size: 80 }} fullHeightCentralized />;
    }

    if (haveError) {
      content = <ErrorMessage error={error} fullHeightCentralized />;
    }

    return (
      <div className={classes.videoListPage}>
        {billboard}
        <div
          className={billboard ? classes.contentWithPlayer : classes.content}
        >
          {content}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ groups }) => ({
  groups: groups.data,
  areLoading: groups.areLoading,
  haveError: groups.haveError,
  error: groups.error
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(groupsFetchData()),
  clearData: () => dispatch(groupsClearData())
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(VideoListPage)
);
