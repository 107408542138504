import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  getEpg,
  getProgramDetails,
  getChannel,
  getEpgChannel
} from './channelsApi';
import { Channel, EpgChannel, Epg, Program } from './channelsTypes';

export const useEpg = (options: UseQueryOptions<Epg[]> = {}) =>
  useQuery<Epg[], unknown>({
    queryKey: ['epg'],
    queryFn: getEpg,
    ...options
  });

export const useEpgChannel = (
  channelId?: string,
  options: UseQueryOptions<EpgChannel> = {}
) =>
  useQuery<EpgChannel, unknown>({
    queryKey: ['epgChannel', channelId],
    queryFn: () => getEpgChannel(channelId ?? ''),
    ...options,
    enabled: options.enabled ?? !!channelId
  });

export const useChannel = (
  channelId?: string,
  options: UseQueryOptions<Channel> = {}
) =>
  useQuery<Channel, unknown>({
    queryKey: ['channel', channelId],
    queryFn: () => getChannel(channelId ?? ''),
    ...options,
    enabled: options.enabled ?? !!channelId
  });

export const useProgramDetails = (
  programId?: string,
  options: UseQueryOptions<Program> = {}
) =>
  useQuery<Program, unknown>({
    queryKey: ['program', programId],
    queryFn: () => getProgramDetails(programId ?? ''),
    ...options,
    enabled: options.enabled ?? !!programId
  });
