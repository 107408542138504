import { baseUrl } from '../apiEndpoints';

export const epgUrl = `${baseUrl}/api/epg`;

export const epgChannelUrl = (channelId: string) =>
  `${baseUrl}/api/epg/${channelId}`;

export const getProgramUrl = (id: string) => `${baseUrl}/api/programs/${id}`;

export const getChannelUrl = (channelId: string) =>
  `${baseUrl}/api/${channelId}/channel.json`;
