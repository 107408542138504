import { useEffect, useRef, useState } from 'react';

export const useIsLoading = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const videoWaiting = () => setIsLoading(true);
    const videoPlaying = () => setIsLoading(false);
    const videoCanPlay = () => setIsLoading(false);
    const videoCanPlayThrough = () => setIsLoading(false);
    const videoSeeked = () => setIsLoading(false);
    const videoPause = () => {
      if (!video.seeking) {
        setIsLoading(false);
      }
    };

    video.addEventListener('waiting', videoWaiting);
    video.addEventListener('playing', videoPlaying);
    video.addEventListener('canplay', videoCanPlay);
    video.addEventListener('canplaythrough', videoCanPlayThrough);
    video.addEventListener('seeked', videoSeeked);
    video.addEventListener('pause', videoPause);

    return () => {
      video.removeEventListener('waiting', videoWaiting);
      video.removeEventListener('playing', videoPlaying);
      video.removeEventListener('canplay', videoCanPlay);
      video.removeEventListener('canplaythrough', videoCanPlayThrough);
      video.removeEventListener('seeked', videoSeeked);
      video.removeEventListener('pause', videoPause);
    };
  }, []);

  return {
    bindVideoLoaderRef: videoRef,
    isLoading
  };
};
