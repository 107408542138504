import {
  FETCH_VIDEO_REQUEST,
  FETCH_VIDEO_ERROR,
  FETCH_VIDEO_SUCCESS,
  CLEAR_VIDEO,
  SET_VIDEO_START_TIME,
  CLEAR_VIDEO_START_TIME,
  SET_VIDEO_CURRENT_TIME,
  CLEAR_VIDEO_CURRENT_TIME,
  TOGGLE_VIDEO_LOOPING
} from '../constants/actionTypes';

export const initialState = {
  startTime: 0,
  currentTime: 0,
  isLooping: true,
  isShockProtectionOn: false,
  isLoading: false,
  hasError: false,
  error: '',
  data: {}
};

export function video(state = initialState, action) {
  switch (action.type) {
    case FETCH_VIDEO_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        data: {},
        error: ''
      };

    case FETCH_VIDEO_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload
      };

    case FETCH_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };

    case CLEAR_VIDEO:
      return {
        ...state,
        data: {}
      };

    case SET_VIDEO_START_TIME:
      return {
        ...state,
        startTime: action.payload
      };

    case CLEAR_VIDEO_START_TIME:
      return {
        ...state,
        startTime: 0
      };

    case SET_VIDEO_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.payload
      };

    case CLEAR_VIDEO_CURRENT_TIME:
      return {
        ...state,
        currentTime: 0
      };

    case TOGGLE_VIDEO_LOOPING:
      return {
        ...state,
        isLooping: !state.isLooping
      };

    default:
      return state;
  }
}
