export const styles = (theme) => ({
  dateLabel: {
    padding: '3px 10px 2px',
    backgroundColor: '#414042',
    color: '#FFF',
    fontFamily: 'Open-Sans',
    fontSize: 14,
    lineHeight: '19px',
    cursor: 'pointer',
    display: 'inline-block'
  },
  listItem: {
    padding: 0,
    paddingBottom: 1,
    marginBottom: 1,
    backgroundColor: '#1F1F1F'
  },
  listItemActive: {
    marginTop: '1px',
    '& $nowPlaying': {
      display: 'initial'
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: -6,
      right: 0,
      height: 2,
      backgroundColor: '#f37037'
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      borderRadius: '50%',
      top: 1,
      left: -12,
      width: 8,
      height: 8,
      transform: 'translateY(-50%)',
      backgroundColor: '#f37037'
    }
  },
  nowPlaying: {
    padding: '3px 5px 2px',
    color: '#f37037',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    display: 'none'
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 80,
    backgroundColor: '#1F1F1F',
    [theme.breakpoints.down('sm')]: {
      height: 115
    },
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#282828'
    },
    '&:hover $programActions': {
      display: 'flex'
    },
    '&:hover $programActions $actionsContainer': {
      width: '100%'
    },
    '&actionsContainer': {
      width: '100%'
    }
  },
  cardContent: {
    padding: '14px 0 0 10px'
  },
  cardRow: {
    display: 'flex'
  },
  programActions: {
    width: '30%',
    display: 'none',
    marginLeft: 'auto',
    padding: '30px',
    actionsContainer: {
      width: '100%'
    }
  },
  actionsContainer: {
    width: '100%'
  },
  cover: {
    width: '140px',
    minWidth: '140px'
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '70px',
    transform: 'translate(-50%, -50%)',
    fontSize: '28px',
    border: '3px solid white'
  },
  listContainer: {
    margin: 0,
    padding: 0,
    backgroundColor: '#000'
  },
  timeCardContainer: {
    cursor: 'pointer'
  }
});
