import { useVideoStoreDispatch } from '../../store/videoStore';

type DocumentType = Document & {
  webkitExitFullscreen?: () => void;
  msExitFullscreen?: () => void;
};

export const useToggleFullScreen = (element: HTMLElement | null) => {
  const updateVideoStore = useVideoStoreDispatch();

  const toggleFullScreen = () => {
    if (!element) return console.error('Element is not found', element);

    const player = element as
      | (HTMLElement & {
          msRequestFullscreen?: () => void;
          webkitRequestFullscreen?: () => void;
        })
      | null;
    if (!player) return;

    if (!document.fullscreenElement) {
      updateVideoStore({ isFullscreen: true });

      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        player.webkitRequestFullscreen(); // Safari support
      } else if (player.msRequestFullscreen) {
        player.msRequestFullscreen(); // IE11 support
      }
    } else {
      updateVideoStore({ isFullscreen: false });

      const doc = document as DocumentType;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen(); // Safari support
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen(); // IE11 support
      }
    }
  };

  return toggleFullScreen;
};
