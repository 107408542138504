import React, { HTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import {
  useVideoStoreValue,
  useVideoStoreDispatch
} from '../../store/videoStore';

import { OpenFullScreenIcon, CloseFullScreenIcon } from './assets/icons';
import { useStyles } from './FullscreenControl.styles';

const FullscreenControl = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement>
>(({ className, onClick, ...props }, ref) => {
  const isFullscreen = useVideoStoreValue((s) => s.isFullscreen);
  const isStarted = useVideoStoreValue((store) => store.isStarted);
  const updateVideoStore = useVideoStoreDispatch();

  const styles = useStyles();

  return (
    <button
      {...(!isStarted && { tabIndex: -1 })}
      className={clsx(className, styles.button)}
      title={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
      type="button"
      onClick={(e) => {
        onClick?.(e);
        updateVideoStore({ isFullscreen: !isFullscreen });
      }}
      ref={ref}
      {...props}
    >
      {isFullscreen ? <CloseFullScreenIcon /> : <OpenFullScreenIcon />}
    </button>
  );
});
FullscreenControl.displayName = 'FullscreenControl';

export default FullscreenControl;
