import { useShallow } from 'zustand/react/shallow';

import { PrevStateFn, SetType } from '../storeTypeUtils';
import { LOADING_TXT } from './constants';
import { useSearchStore } from './searchStore';

export type ChatMessage = {
  message: string;
  isAi: boolean;
};

export type AISearchSlice = {
  searchAiStr: string;
  setSearchAiStr: (input: string | PrevStateFn<string>) => void;
  isChatOpen: boolean;
  setChatOpen: (input: boolean) => void;
  chatMessages: ChatMessage[];
  setChatMessages: (input: ChatMessage[] | PrevStateFn<ChatMessage[]>) => void;
  isMutating: boolean;
  setIsMutating: (isMutating: boolean) => void;
};

export function aiSearchSlice(set: SetType<AISearchSlice>) {
  return {
    isChatOpen: false,
    setChatOpen: (input: boolean) => {
      set({ isChatOpen: input }, false, 'setChatOpen');
    },
    searchAiStr: '',
    setSearchAiStr: (input: string | PrevStateFn<string>) => {
      const setRest = [false, 'setSearchAiStr'] as const;
      if (typeof input === 'string') {
        set({ searchAiStr: input }, ...setRest);
      } else {
        set((state) => ({ searchAiStr: input(state.searchAiStr) }), ...setRest);
      }
    },
    chatMessages: [],
    setChatMessages: (input: ChatMessage[] | PrevStateFn<ChatMessage[]>) => {
      const setRest = [false, 'setChatMessages'] as const;
      if (Array.isArray(input)) {
        set({ chatMessages: input }, ...setRest);
      } else {
        set(
          (state) => ({ chatMessages: input(state.chatMessages) }),
          ...setRest
        );
      }
    },
    isMutating: false,
    setIsMutating: (isMutating: boolean) => {
      set({ isMutating }, false, 'setIsMutating');
    }
  } satisfies AISearchSlice;
}

export const useAddMsg = () => {
  const { setChatMessages, setActiveSearch } = useSearchStore(
    useShallow((state) => ({
      setChatMessages: state.setChatMessages,
      setActiveSearch: state.setActiveSearch
    }))
  );
  return (
    msg: string,
    isAi: boolean,
    isAiReplaceLoader = false,
    omitSetActive = false
  ): void => {
    if (!omitSetActive && isAiReplaceLoader) setActiveSearch('ai');
    setChatMessages((prev: ChatMessage[]) => {
      if (isAiReplaceLoader && prev[prev.length - 1]?.message === LOADING_TXT) {
        return [...prev.slice(0, -1), { message: msg, isAi }];
      }
      return [...prev, { message: msg, isAi }];
    });
  };
};
