import {
  FETCH_VIDEO_NAV_REQUEST,
  FETCH_VIDEO_NAV_ERROR,
  FETCH_VIDEO_NAV_SUCCESS,
  CLEAR_VIDEO_NAV
} from '../constants/actionTypes';

export const initialState = {
  isLoading: false,
  hasError: false,
  error: '',
  data: []
};

export function videoNav(state = initialState, action) {
  switch (action.type) {
    case CLEAR_VIDEO_NAV:
      return {
        ...state,
        data: []
      };

    case FETCH_VIDEO_NAV_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        data: [],
        error: ''
      };

    case FETCH_VIDEO_NAV_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload
      };

    case FETCH_VIDEO_NAV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };

    default:
      return state;
  }
}
