import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  tile: {
    position: 'relative',
    width: 180,
    height: 100,
    border: 0,
    padding: 0,
    margin: '0 10px',
    backgroundColor: 'transparent',
    cursor: 'pointer',

    '@media (max-width: 1366px)': {
      width: 135,
      height: 75
    }
  },
  thumbnail: {
    display: 'block',
    width: 180,
    height: 100,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'gray',

    '@media (max-width: 1366px)': {
      width: 135,
      height: 75
    }
  },
  thumbnailHover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    backgroundPosition: 'center',
    backgroundSize: 'cover',

    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  },
  tooltipTitle: {
    margin: 5,
    marginBottom: 15,
    color: '#f37037',
    fontWeight: 600,
    fontSize: 18
  },
  tooltipText: {
    margin: 5,
    fontWeight: 500,
    fontSize: 15
  },
  flexContainer: {
    justifyContent: 'space-between'
  },
  scrollButton: {
    color: '#D8D8D8',
    '& svg': {
      fontSize: 40,

      '@media (max-width: 1366px)': {
        fontSize: 30
      }
    }
  }
}));
