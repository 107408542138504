import React, {
  HTMLAttributes,
  MediaHTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';

import mergeRefs from '../../../shared/mergeRefs';
import { useDetectIdle } from '../../../hooks';

import {
  VideoStoreProvider,
  useVideoStoreDispatch,
  useVideoStoreValue
} from '../store/videoStore';
import {
  useAutoPlay,
  useHls,
  useIsLoading,
  useToggleCC,
  useToggleFullScreen
} from './hooks';
import { VideoControls, ControlsBlock, TimeControl } from '../VideoControls';
import VideoStartButton from '../VideoStartButton';
import PlaybackButtons from '../VideoControls/PlaybackButtons';
import SoundControl from '../VideoControls/SoundControl';
import CaptionsControl from '../VideoControls/CaptionsControl';
import SelectControl from '../VideoControls/SelectControl';
import { FORWARD_BACKWARD_SECONDS } from '../constants';
import FullscreenControl from '../VideoControls/FullscreenControl';
import VideoLoader from '../VideoLoader';

import { useStyles } from './VideoPlayer.styles';

type Props = HTMLAttributes<HTMLDivElement> & {
  src: string;
  cc?: string;
  trackSelector?: ReactNode;
  type?: string;
  isHls?: boolean;
  poster?: string;
  disablePoster?: boolean;
  videoProps?: MediaHTMLAttributes<HTMLVideoElement>;
  sessionToken?: string;
  hideControls?: boolean;
};

const VideoPlayer = forwardRef<HTMLVideoElement, Props>((props, ref) => (
  <VideoStoreProvider>
    <VideoPlayerImpl {...props} ref={ref} />
  </VideoStoreProvider>
));
VideoPlayer.displayName = 'VideoPlayerContextWrapper';

const VideoPlayerImpl = forwardRef<HTMLVideoElement, Props>(
  (
    {
      src,
      trackSelector,
      isHls,
      type,
      cc,
      className,
      poster,
      disablePoster,
      videoProps: _videoProps,
      sessionToken,
      hideControls,
      ...props
    },
    ref
  ) => {
    const videoPropsClassName = _videoProps?.className;
    const videoProps = { ..._videoProps };
    delete videoProps.className;

    const isStarted = useVideoStoreValue((store) => store.isStarted);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const updateVideoStore = useVideoStoreDispatch();

    const isPlaying = useVideoStoreValue((store) => store.isPlaying);
    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;
      if (isPlaying) {
        video.play().catch(() => {});
      } else {
        video.pause();
      }
    }, [isPlaying]);

    useEffect(() => {
      if (disablePoster) updateVideoStore({ isStarted: true });
    }, [disablePoster, updateVideoStore]);

    const volume = useVideoStoreValue((store) => store.volume);
    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;
      video.volume = volume;
    }, [volume]);

    const { isAdvertisement, isLive, levels, activeLevel, setActiveLevel } =
      useHls(videoRef.current, src, isHls, sessionToken);
    const initialActiveLevel = useRef(
      levels[levels.findIndex((l) => l.id === activeLevel)] ?? null
    );
    useEffect(() => {
      initialActiveLevel.current =
        levels[levels.findIndex((l) => l.id === activeLevel)] ?? null;
    }, [activeLevel, levels]);

    const toggleCC = useToggleCC(videoRef.current);
    const toggleFullScreen = useToggleFullScreen(wrapperRef.current);

    const styles = useStyles();

    const { bindVideoLoaderRef, isLoading } = useIsLoading();

    // idle management (hide controls after some time of inactivity or when mouse out of the playing video)
    // if user navigates using Tab button, show controls when focus is in the toolbar
    const {
      detectIdleRef,
      isMouseMoving,
      onMouseMove,
      setMouseMoving,
      isFocusWithin
    } = useDetectIdle();
    const [isMouseOverControlBar, setIsMouseOverControlBar] = useState(false);

    // Handle outside starting/pausing video using ref (ref.current.play() / ref.current.pause())
    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      const onPlay = () => {
        updateVideoStore({ isPlaying: true });
      };

      const onPause = () => {
        updateVideoStore({ isPlaying: false });
      };

      video.addEventListener('play', onPlay);
      video.addEventListener('pause', onPause);

      return () => {
        video.removeEventListener('play', onPlay);
        video.removeEventListener('pause', onPause);
      };
    }, [src, updateVideoStore]);

    // Handle auto start video
    useAutoPlay(!!videoProps.autoPlay);

    return (
      <div
        ref={wrapperRef}
        className={clsx(styles.wrapper, className)}
        onClick={() => updateVideoStore({ isPlaying: !isPlaying })}
        onDoubleClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleFullScreen();
        }}
        onMouseEnter={() => updateVideoStore({ isHovered: true })}
        onMouseLeave={() => {
          updateVideoStore({ isHovered: false });
          setMouseMoving(false);
        }}
        onMouseMove={onMouseMove}
        tabIndex={-1}
        {...props}
      >
        {/* Do not use poster attribute on <video /> - it doesn't work for LIVE video (at least on Chrome) */}
        {poster && !isStarted && (
          <img
            className={styles.poster}
            src={poster}
            alt="Video poster"
            loading="lazy"
          />
        )}
        <video
          tabIndex={isStarted ? 0 : -1}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              e.preventDefault();
              updateVideoStore({ isPlaying: !isPlaying });
            }
          }}
          ref={mergeRefs(videoRef, ref, bindVideoLoaderRef)}
          className={clsx(styles.video, videoPropsClassName)}
          onPlay={() => {
            updateVideoStore({ isStarted: true });
            // show controls on play
            onMouseMove();
          }}
          onLoadedMetadata={(event) => {
            const video = event.target as HTMLVideoElement;
            const duration = video.duration;
            updateVideoStore({ duration });
          }}
          onTimeUpdate={(event) => {
            const video = event.target as HTMLVideoElement;
            // TODO: Update state only if not seeking
            const currentTime = video.currentTime;
            const duration = video.duration;
            const loadedTime = video.buffered.length
              ? video.buffered.end(0)
              : 0;

            updateVideoStore({
              currentTime,
              duration: Number.isNaN(duration) ? 0 : duration,
              loadedTime
            });
          }}
          onEnded={() => {
            // TODO: play again button
            updateVideoStore({ isPlaying: false });
          }}
          width="100%"
          height="100%"
          {...videoProps}
        >
          {!isHls && <source src={src} type={type} />}
          {cc && (
            <track kind="subtitles" src={cc} srcLang="en" label="English" />
          )}
        </video>
        {!hideControls && (
          <VideoStartButton
            className={clsx(
              styles.startButton,
              (isStarted || hideControls) && styles.startButtonHidden
            )}
            {...(isStarted && { tabIndex: -1 })}
          />
        )}
        {isLoading && <VideoLoader className={styles.loader} />}
        {!hideControls && (
          <VideoControls
            ref={detectIdleRef}
            className={styles.bar}
            isVisible={
              !isAdvertisement &&
              isStarted &&
              (isMouseMoving ||
                !isPlaying ||
                isMouseOverControlBar ||
                isFocusWithin)
            }
            onMouseEnter={() => setIsMouseOverControlBar(true)}
            onMouseLeave={() => setIsMouseOverControlBar(false)}
          >
            <ControlsBlock className={styles.mainControlBlock}>
              <SoundControl className={styles.sound} />
              {isLive ? (
                <span className={styles.live}>Live</span>
              ) : (
                <TimeControl
                  onTimeChange={(time) => {
                    const video = videoRef.current;
                    if (!video) return;
                    video.currentTime = time;
                  }}
                  className={styles.time}
                />
              )}
            </ControlsBlock>
            <ControlsBlock className={styles.playbackButtons}>
              <PlaybackButtons
                onForwardClick={() => {
                  const video = videoRef.current;
                  const currentTime = videoRef.current?.currentTime;
                  const duration = videoRef.current?.duration;

                  if (
                    !video ||
                    typeof currentTime !== 'number' ||
                    typeof duration !== 'number'
                  )
                    return;

                  const newTime = currentTime + FORWARD_BACKWARD_SECONDS;
                  video.currentTime = newTime > duration ? duration : newTime;
                }}
                onBackwardClick={() => {
                  const video = videoRef.current;
                  const currentTime = videoRef.current?.currentTime;
                  if (!video || typeof currentTime !== 'number') return;
                  const newTime = currentTime - FORWARD_BACKWARD_SECONDS;
                  video.currentTime = newTime < 0 ? 0 : newTime;
                }}
              />
            </ControlsBlock>
            <ControlsBlock className={styles.rightControlsBlock}>
              <CaptionsControl onClick={toggleCC} disabled={!cc} />
              {trackSelector}
              {levels.length > 0 && initialActiveLevel.current && (
                <SelectControl
                  items={levels}
                  initialSelectedItem={initialActiveLevel.current}
                  itemToString={(item) => item?.label ?? ''}
                  renderItem={(item) => item.label}
                  titleTooltip="Quality"
                  onSelectedItemChange={(changes) => {
                    setActiveLevel(changes.selectedItem.id);
                  }}
                />
              )}
              <FullscreenControl
                onClick={toggleFullScreen}
                className={styles.fullscreenControl}
              />
            </ControlsBlock>
          </VideoControls>
        )}
      </div>
    );
  }
);
VideoPlayerImpl.displayName = 'VideoPlayer';

export default VideoPlayer;
