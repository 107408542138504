import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    gap: 24,
    alignItems: 'center'
  },
  button: {
    all: 'unset',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,

    '&:focus-visible': {
      outline: '1px solid rgb(255 255 255)',
      outlineOffset: 2
    },

    '& svg': {
      width: 16,
      height: 16
    },

    '&:hover svg': {
      filter: 'drop-shadow(0px 0px 6px rgb(255 255 255))'
    }
  }
}));
